import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';
import moment from 'moment'
import {CHART_BARCHART_OPTIONS, CHART_PIECHART_OPTIONS} from '../../configApp';
import UIStore from "../../store/UIStore";
import projectStore from "../../store/ProjectStore";

const ProductModalContent2 = (props) =>  {
  const { product } = props; // NOSONAR
  const [, setWindowSize] = useState(null); // NOSONAR

  /*
      AppCost: 2.17647058823529
      ConsoAppTot: 15.4705882352941
      ConsoGratuitTot: 0
      ConsoOtherTransmitter: 2200.73529411765
      HPDWHConso: 87.7854671280277
      HPHeatingConso: 619
      NoMonth: 1
      OtherTransmittersCost: 0
      PACChCost: 99
      PACDWHCost: 17.3010380622837
     */

  const barChartOptions = {...CHART_BARCHART_OPTIONS, ...{
      hAxis: {
        title: _T('MONTH'),
        titleTextStyle: { italic: false },
        slantedText: true,
        slantedTextAngle: 90,
      }
    }
  };

  const pieChartOptions = {...CHART_PIECHART_OPTIONS};

  let totalConsoAndCost = {
    conso: {
      heatingTotal: 0,
      DWHTotal: 0,
      AppTotal: 0,
      RafTotal: 0
    },
    cost: {
      heatingTotal: 0,
      DWHTotal: 0,
      AppTotal: 0,
      RafTotal: 0
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Fix a bug on smartphone to adapt first chart to screen size
    setTimeout(() => {
      setWindowSize(window.innerWidth)
    }, 100);
  }, []);

  const handleResize = e => {
    delete barChartOptions.animation;
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [handleResize]);

  const resetTotals = () => {
    totalConsoAndCost = {
      conso: {
        heatingTotal: 0,
        DWHTotal: 0,
        AppTotal: 0,
        RafTotal: 0,
      },
      cost: {
        heatingTotal: 0,
        DWHTotal: 0,
        AppTotal: 0,
        RafTotal: 0,
      }
    }
  };

  const drawChartConso = (conso) => {
    let chartData = [
      projectStore.project.simulationCool
        ? [_T('MONTH'), _T('HEATING'), _T('HOT_WATER'), _T('EXTRA'), _T('RAF'), { role: 'annotation' } ]
        : [_T('MONTH'), _T('HEATING'), _T('HOT_WATER'), _T('EXTRA'), { role: 'annotation' } ]
    ];

    resetTotals();
    conso.forEach(row => {
      totalConsoAndCost.conso.heatingTotal += row.HPHeatingConso;
      totalConsoAndCost.conso.DWHTotal += row.HPDWHConso;
      totalConsoAndCost.conso.AppTotal += row.ConsoAppTot;
      if(projectStore.project.simulationCool) {
        totalConsoAndCost.conso.RafTotal += row.RAFConso;
      }

      totalConsoAndCost.cost.heatingTotal += row.PACChCost;
      totalConsoAndCost.cost.DWHTotal += row.PACDWHCost;
      totalConsoAndCost.cost.AppTotal += row.AppCost;
      if(projectStore.project.simulationCool) {
        totalConsoAndCost.cost.RafTotal += row.RAFCost;
      }

      chartData.push(projectStore.project.simulationCool
        ? [moment().month(row.NoMonth - 1).format("MMM"), row.HPHeatingConso, row.HPDWHConso, row.ConsoAppTot, row.RAFConso, (row.HPHeatingConso + row.HPDWHConso + row.ConsoAppTot + row.RAFConso)]
        : [moment().month(row.NoMonth - 1).format("MMM"), row.HPHeatingConso, row.HPDWHConso, row.ConsoAppTot, (row.HPHeatingConso + row.HPDWHConso + row.ConsoAppTot)]);
    });

    const data = new window.google.visualization.arrayToDataTable(chartData);
    const chart = new window.google.visualization.ColumnChart(document.getElementById('chartConso'));

    // find v-axis max value
    let vAxisMax = null;
    for (let i = 1; i < data.getNumberOfColumns(); i++) {
      let range = data.getColumnRange(i);
      vAxisMax = Math.max(vAxisMax || range.max, range.max);
    }
    // add buffer for annotation
    vAxisMax *=1.2;

    chart.draw(data, {...barChartOptions, ...{
      title: _T('BARCHART_CONSO_TITLE'),
      vAxis: {
        title: _T('CONSUMED_ENERGY'),
        titleTextStyle: { italic: false },
        viewWindow: {
          max: vAxisMax
        }
      }
    }});
  };

  const drawChartRepartitionConso = (conso) => {
    let chartData = [
      [_T('MONTH'), _T('COST', {currency: UIStore.environment_parameters.currency})],
      [_T('HEATING'), totalConsoAndCost.conso.heatingTotal],
      [_T('HOT_WATER'), totalConsoAndCost.conso.DWHTotal],
      [_T('EXTRA'), totalConsoAndCost.conso.AppTotal],
    ];

    if(projectStore.project.simulationCool) chartData.push([_T('RAF'), totalConsoAndCost.conso.RafTotal]);

    const data = new window.google.visualization.arrayToDataTable(chartData);
    const formatter = new window.google.visualization.NumberFormat({
      fractionDigits: 0,
      suffix: 'kWh'
    });
    formatter.format(data, 1);

    const chart = new window.google.visualization.PieChart(document.getElementById('chartRepartitionConso'));
    chart.draw(data, {...pieChartOptions, ...{title: _T('PIECHART_CONSO_TITLE')}});
  };

  const drawChartCout = (conso) => {
    let chartData = [
      projectStore.project.simulationCool
        ? [_T('MONTH'), _T('HEATING'), _T('HOT_WATER'), _T('EXTRA'), _T('RAF'), { role: 'annotation' } ]
        : [_T('MONTH'), _T('HEATING'), _T('HOT_WATER'), _T('EXTRA'), { role: 'annotation' } ],
    ];

    conso.forEach(row => {
      chartData.push(projectStore.project.simulationCool
                        ? [moment().month(row.NoMonth-1).format("MMM"), row.PACChCost, row.PACDWHCost, row.AppCost, row.RAFCost, (row.PACChCost + row.PACDWHCost + row.AppCost + row.RAFCost)]
                        : [moment().month(row.NoMonth-1).format("MMM"), row.PACChCost, row.PACDWHCost, row.AppCost, (row.PACChCost + row.PACDWHCost + row.AppCost)]);
    });

    const data = new window.google.visualization.arrayToDataTable(chartData);
    const chart = new window.google.visualization.ColumnChart(document.getElementById('chartCout'));

    // find v-axis max value
    let vAxisMax = null;
    for (let i = 1; i < data.getNumberOfColumns(); i++) {
      let range = data.getColumnRange(i);
      vAxisMax = Math.max(vAxisMax || range.max, range.max);
    }
    // add buffer for annotation
    vAxisMax *=1.2;

    chart.draw(data, {...barChartOptions, ...{
        title: _T('BARCHART_COST_TITLE', {currency: UIStore.environment_parameters.currency}),
        vAxis: {
          title: _T('COST', {currency: UIStore.environment_parameters.currency}),
          titleTextStyle: { italic: false },
          viewWindow: {
            max: vAxisMax
          }
        }
      }
    });
  };

  const drawChartRepartitionCout = (conso) => {
    let chartData = [
      [_T('MONTH'), 'Consomation' ],
      [_T('HEATING'), totalConsoAndCost.cost.heatingTotal],
      [_T('HOT_WATER'), totalConsoAndCost.cost.DWHTotal],
      [_T('EXTRA'), totalConsoAndCost.cost.AppTotal],
    ];

    if(projectStore.project.simulationCool) chartData.push([_T('RAF'), totalConsoAndCost.cost.RafTotal]);

    const data = new window.google.visualization.arrayToDataTable(chartData);
    const formatter = new window.google.visualization.NumberFormat({
      fractionDigits: 0,
      suffix: UIStore.environment_parameters.currency
    });
    formatter.format(data, 1);

    const chart = new window.google.visualization.PieChart(document.getElementById('chartRepartitionCout'));
    chart.draw(data, {...pieChartOptions, ...{title: _T('PIECHART_COST_TITLE', {currency: UIStore.environment_parameters.currency})}});
  };

  // google.charts.load('current', {packages: ['corechart'], language: UIStore.lang}) //NOSONAR
  // google.charts.setOnLoadCallback(() => drawChartConso(product.E3)) //NOSONAR
  // google.charts.setOnLoadCallback(() => drawChartRepartitionConso(product.E3)) //NOSONAR
  // google.charts.setOnLoadCallback(() => drawChartCout(product.E3)) // NOSONAR
  // google.charts.setOnLoadCallback(() => drawChartRepartitionCout(product.E3)) //NOSONAR

  useEffect(() => {
    drawChartConso(product.E3) // NOSONAR
    drawChartRepartitionConso(product.E3) //NOSONAR
    drawChartCout(product.E3) // NOSONAR
    drawChartRepartitionCout(product.E3) //NOSONAR
  }, []);

  return (
    <>
      <div className="row chartRow">
        <div className="col col-12 col-md-12 col-lg-10">
          <div className="chart" id="chartCout" />
        </div>
        <div className="col col-12 col-md-12 col-lg-2">
          <div className="chart" id="chartRepartitionCout" />
        </div>
      </div>

      <div className="row chartRow">
        <div className="col col-12 col-md-12 col-lg-10">
          <div className="chart" id="chartConso" />
        </div>
        <div className="col col-12 col-md-12 col-lg-2">
          <div className="chart" id="chartRepartitionConso" />
        </div>
      </div>
    </>
  );
}

export default  observer(ProductModalContent2);