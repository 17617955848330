/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { _THtml } from '../_i18n/i18n.helper';
import { Link } from "react-router-dom";
import UIStore from '../store/UIStore';
import * as projectService from '../Project/project.service';
import * as apiService from '../_services/api.service';
import logo_projipac from '../assets/img/logo-projipac.svg';
import logo_simulhome from '../assets/img/logo-simulhome.png';
import logo_pacific from '../assets/img/logo-pacific-project.png';
import picto_simulations from '../assets/img/picto-simulations.svg';
import picto_simulations_thermor from '../assets/img/picto-simulations-thermor.svg';
import LanguageSwitch from "../_i18n/languageSwitch";
import TagManager from 'react-gtm-module'
import ISO6391 from 'iso-639-1'
/**
 * Home Page
 */

const HomePage = (props) => {
  const [info, setInfo] = useState('');

  useEffect(() => {
    async function getSettings() {
      const settings = await apiService.get(`parameters/env=${UIStore.environnement_id}`);
  
      let infos = settings.filter((i) => i.code.startsWith('INFORMATION'));

      for (let info of infos) {
        let parts = info.code.split("/");

        if (parts[1]) {
          let languageCode = ISO6391.getCode(parts[1]);

          if (languageCode === UIStore.lang) {
            setInfo(info.value)
          
            break;
          }
        } else {
            setInfo(info.value)
        }
      };
    }

    getSettings();
  }, []);

  const newProject = () => {
    const { history } = props; // NOSONAR

    projectService.initNewProject();
    history.push(`/${UIStore.env}/project/home/heat`); //NOSONAR
  };

  let brand = UIStore.environment_parameters.brand;
  const tagManagerArgs = {
    dataLayer: {
      'event': 'datalayer-ready',
      'page':{
        'arbo1':'home',
        'arbo2':null,
        'arbo3':null,
        'arbo4':null,
        'taxonomy1':'simulateur',
        'product_taxonomy1':'PAC AIR/EAU',
        'product_taxonomy2':'PAC INDIVIDUELLE',
        'product_taxonomy3':null,
        'product_taxonomy4':null,
        'product_taxonomy5':null,
        'product_taxonomy6':null
        },
      'user':{
        'id': UIStore.user.id,
        'type':null,
        'company': null,
        'siret': UIStore.user.siret
        }
    },
    dataLayerName: 'PageDataLayer'
  }

  TagManager.dataLayer(tagManagerArgs)

  return (
    <div className={brand === 'thermorfr' ?
      'pageChoix pageChoixThermor'
      :
      brand === 'pacific' ? // NOSONAR
        'pageChoix pageChoixPacific'
        :
        'pageChoix'
    }>
      <div className="header">
        {brand !== 'thermorfr' && brand !== 'pacific' &&
        <>
          <LanguageSwitch />
          <img src={UIStore.logo} height="40" alt="Logo" />
        </>}
      </div>

      <div className="main container-fluid" style={{display: "flex", maxWidth: "100%"}}>
        {
          info && <div className={brand === "pacific" ? "info pacific" : "info"} style={brand === "thermorfr" ? {color: "black"} : {}}>
            <div className='infoTitle'>
              <i style={brand === "thermorfr" ? {backgroundColor: "black"} : {}}></i>
              Information
            </div>
            <div className='infoBody' dangerouslySetInnerHTML={{__html: info}}>
            </div>
          </div>
        }
        <div className={brand === "pacific" ? info ? "row choixProjet pacific" : "row choixProjet pacific afterInfo " : info ? "row choixProjet": "row choixProjet afterInfo"}>

          {brand === "thermorfr" ?
            <div className="blocProjipac">
              <img src={logo_simulhome} width="380" height="257" alt="Logo Simul'home" />
            </div>
            :
            brand === "pacific" ? // NOSONAR
              <div className="blocProjipac blocProjipacPacific">
                <img src={logo_pacific} width="100" height="100" alt="Logo Pacific" style={{border: '2px white solid', borderRadius: 22}} />
                <p className='pacificText'>Pompes à chaleur Air/Eau</p>
                </div>
              :
              <div className="blocProjipac">
                <p>Proji-Pac</p>
                <img src={logo_projipac} width="100" height="100" alt="Logo Projipac" />
              </div>}

          <div className="row blocProjet">
            <a className="btn nouveauP" onClick={newProject}> {/* NOSONAR*/}
              <p>{ _THtml('HOME_NEW_PROJECT')}</p>
              <strong>+</strong>
            </a>

            <Link className="btn simulationP" to={`/${UIStore.env}/my_projects`}>
              <p>{ _THtml('HOME_MY_PROJECTS')}</p>
              {brand === "thermorfr" ?
                <img src={picto_simulations_thermor} alt="" width="119" height="82" />
                :
                <img src={picto_simulations} alt="" width="119" height="82" />}
            </Link>
          </div>

        </div>
      </div>

    </div>
  )
}

export default observer(HomePage);
