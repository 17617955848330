import UIStore from '../store/UIStore';
import * as apiService from '../_services/api.service';
import ProjectStore from "../store/ProjectStore";

/**
 * Get departments on current env
 * @returns {Promise<boolean>}
 */
export async function getDepartments () {
  return await apiService.get(`departments/${UIStore.env}`);
}

export async function getStations(department) {
  return await apiService.get(`stations/${UIStore.env}/${department}`);
}

export async function getTbase(department, altitude, seaside) {
  return await apiService.get(`tbase/${UIStore.env}/${department}/${altitude}/${seaside}`);
}

export async function getLoss () {
  UIStore.hideLoadingBar(true);
  return await apiService.post(`loss/${UIStore.env}`, {project: ProjectStore.project});
}

/**
 * Get results from lua engine
 * @returns {Promise<*>}
 */
export async function getResults () {
  return await apiService.post(`results/${UIStore.env}`, {project: ProjectStore.project});
}

export async function getAccessories (productCode) {
  return await apiService.get(`accessories/${UIStore.env}/${productCode}`);
}

export async function getVAT () {
  return await apiService.get(`vatByEnv/${UIStore.env}`);
}

export async function getDocuments (product) {
  return await apiService.get(`documents/${UIStore.env}/${product}`);
}

export async function getEnergies () {
  return await apiService.get(`EnergiesByEnv/${UIStore.env}`);
}