import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import MaintenancePage from '../Maintenance/MaintenancePage';

/**
 * Manage Login
 * If not authenticated show Login else Redirect to Component
 * @param Component
 * @param auth
 * @param redirectToWhenAuth
 * @param rest
 * @returns {*}
 * @constructor
 */
export const LoginRoute = ({ component: Component, auth, redirectToWhenAuth, maintenanceMode, ...rest }) => ( //NOSONAR
  <Route {...rest} render={props => (
    maintenanceMode
      ? <MaintenancePage /> 
      : (
        auth  //NOSONAR
          ? <Redirect to={{pathname: redirectToWhenAuth, state: {from: props.location}}} //NOSONAR
           />
          : <Component {...props} />
      )
  )} />
)
