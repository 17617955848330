import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import PWAPrompt from 'react-ios-pwa-prompt'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import { PrivateRoute } from './_components/PrivateRoute'
import { LoginRoute } from './_components/LoginRoute'
import LoggedInCallbackRoute from './_components/LoggedInCallbackRoute'
import { LogoutRoute } from './_components/LogoutRoute'
import UIStore from './store/UIStore'
import LoginPage from './Login/LoginPage'
import HomePage from './Home/HomePage'
import HelpPage from './Help/HelpPage'
import ProjectPage from './Project/ProjectPage'
import * as i18nService from './_i18n/i18n.service'
import { DEFAULT_ROUTE } from './configApp'
import ProductInfos from './Project/ProductInfos/ProductInfos'
import LoadProjectPage from './Project/LoadProjectPage'
import MyProjects from './Project/MyProjects/MyProjects'
import MyAccount from './MyAccount/MyAccount'
import SatisfactionPage from './Satisfaction/SatisfactionPage'
import { _T } from './_i18n/i18n.helper'

import CookieBot from 'react-cookiebot'
import LoadingBar from './_components/LoadingBarContainer'

const App = () => { // NOSONAR
  let maintenanceMode = null;

  useEffect(() => {
    if (UIStore.isThermor) { //NOSONAR
      document.title = "SIMUL'HOME"
      let link = document.querySelector("link[rel~='icon']")
      link.href = window.location.origin + '/favicon-thermor.png'
      let linkApple = document.querySelector("link[rel~='apple-touch-icon']")
      linkApple.href = window.location.origin + '/favicon192-thermor.png'
    } else if (UIStore.isPacific) { //NOSONAR
      document.title = "PROJECT PAC AIR EAU"
      let link = document.querySelector("link[rel~='icon']")
      link.href = window.location.origin + '/favicon-pacific.png'
      let linkApple = document.querySelector("link[rel~='apple-touch-icon']")
      linkApple.href = window.location.origin + '/favicon192-pacific.png'
    }

    let tag = document.createElement('script');
    tag.async = false;
    tag.src = 'https://www.gstatic.com/charts/loader.js'
    let body = document.getElementsByTagName('body')[0];
    body.appendChild(tag)

    i18nService.translate()
   
  }, [])

  const defaultRoute = sessionStorage.getItem('redirectTo') || DEFAULT_ROUTE + UIStore.env + '/'
  let defaultRoute2 = DEFAULT_ROUTE + UIStore.env + '/home'

  let brand;

  if (UIStore.appReady && UIStore.environment_parameters) { //NOSONAR
    brand = UIStore.environment_parameters.brand
    if (brand) brand = UIStore.environment_parameters.brand.toLowerCase(); //NOSONAR
    else brand = 'atlantic'; //NOSONAR

    if (brand !== 'atlantic') { //NOSONAR
      switch (brand) { //NOSONAR
        case 'thermor':
          document.documentElement.style.setProperty('--saumon', '#E62814')
          document.documentElement.style.setProperty('--saumonAlt', '#E62814')

          document.documentElement.style.setProperty('--ocre', '#000000')
          document.documentElement.style.setProperty('--ocreAlt', '#000000')

          document.documentElement.style.setProperty('--saumon0', '#E62814')
          document.documentElement.style.setProperty('--saumon1', '#E62814')
          document.documentElement.style.setProperty('--saumon2', '#E62814')
          document.documentElement.style.setProperty('--saumon3', '#E62814')
          document.documentElement.style.setProperty('--saumon4', '#E62814')
          document.documentElement.style.setProperty('--saumon5', '#E62814')
          document.documentElement.style.setProperty('--saumon6', '#E62814')

          document.documentElement.style.setProperty('--pdtLib', '#E62814')
          document.documentElement.style.setProperty('--pdtCode', '#EA4837')
          document.documentElement.style.setProperty('--pdtPrix', '#ED685A')

          break

        case 'thermorfr':
          document.documentElement.style.setProperty('--saumon', '#2C2E34') // "Noir"
          document.documentElement.style.setProperty('--saumonAlt', '#E62814') // Rouge
          document.documentElement.style.setProperty('--saumonAlt2', '#2C2E34') // "Noir"
          document.documentElement.style.setProperty('--saumonAlt3', '#E62814') // Rouge
          document.documentElement.style.setProperty('--saumonAlt4', '#2C2E34') // "Noir"
          document.documentElement.style.setProperty('--saumonAlt5', '#2C2E34') // "Noir"
          document.documentElement.style.setProperty('--saumonStart', '#5B7F76') // "Vert"

          document.documentElement.style.setProperty('--ocre', '#E62814') // Rouge
          document.documentElement.style.setProperty('--ocreAlt', '#2C2E34') // "Noir"
          document.documentElement.style.setProperty('--ocreAlt2', '#E62814') // Rouge
          document.documentElement.style.setProperty('--ocreAlt3', '#E62814') // Rouge
          document.documentElement.style.setProperty('--ocreAlt4', '#2C2E34') // Noir
          document.documentElement.style.setProperty('--ocreAlt5', '#E62814') // Rouge

          document.documentElement.style.setProperty('--saumon0', '#EDEDED') // Gris clair
          document.documentElement.style.setProperty('--saumon1', '#EDEDED')
          document.documentElement.style.setProperty('--saumon2', '#EDEDED')
          document.documentElement.style.setProperty('--saumon3', '#EDEDED')
          document.documentElement.style.setProperty('--saumon4', '#EDEDED')
          document.documentElement.style.setProperty('--saumon5', '#EDEDED')
          document.documentElement.style.setProperty('--saumon6', '#EDEDED')

          document.documentElement.style.setProperty('--pdtLib', '#000000') // Noir
          document.documentElement.style.setProperty('--pdtCode', '#2C2E34') // "Noir"
          document.documentElement.style.setProperty('--pdtPrix', '#F2F2F2') // Gris clair autre

          document.documentElement.style.setProperty('--txt', '#000000') // Noir
          document.documentElement.style.setProperty('--txtAlt', '#000000')
          document.documentElement.style.setProperty('--txtRubrique', '#000000')
          document.documentElement.style.setProperty('--txtSpe', '#FFFFFF') // Blanc
          document.documentElement.style.setProperty('--txtSpeAlt', '#FFFFFF') // Blanc

          document.documentElement.style.setProperty('--choixHome', '#FFFFFF')
          document.documentElement.style.setProperty('--choixHomeLine', '#5B7F76')

          document.documentElement.style.setProperty('--topBarre', '#2C2E34')
          document.documentElement.style.setProperty('--topBarrePadding', '0px 0px')

          document.documentElement.style.setProperty('--devisTotalBg', '#FFFFFF')
          document.documentElement.style.setProperty('--devisTxt', '#FFFFFF') // Blanc
          document.documentElement.style.setProperty('--devisBg', '#E62814') // Rouge
          document.documentElement.style.setProperty('--devisTxtAlt', '#FFFFFF') // Blanc
          document.documentElement.style.setProperty('--devisBgAlt', '#2C2E34') // "Noir"

          document.documentElement.style.setProperty('--mobileDrawerBg', '#2C2E34') // Noir

          document.documentElement.style.setProperty('--typo_regular', 'lato_regular')
          document.documentElement.style.setProperty('--typo_light', 'lato_light')
          document.documentElement.style.setProperty('--typo_bold', 'lato_bold')

          document.documentElement.style.setProperty('--froid', '#a7d6f4')

          break

        case 'pacific':
          document.documentElement.style.setProperty('--saumon', '#53565A') // "Cool Gray"
          document.documentElement.style.setProperty('--saumonAlt', '#E52E39') // "Rouge"
          document.documentElement.style.setProperty('--saumonAlt2', '#008aac') // Bleu
          document.documentElement.style.setProperty('--saumonAlt3', '#53565A') // Noir
          document.documentElement.style.setProperty('--saumonAlt4', '#E52E39') // "Rouge"
          document.documentElement.style.setProperty('--saumonAlt5', '#F2F2F2') // "Gris clair"

          document.documentElement.style.setProperty('--ocre', '#E52E39') // Rouge Pacific
          document.documentElement.style.setProperty('--ocreAlt', '#E52E39') // Rouge Pacific
          document.documentElement.style.setProperty('--ocreAlt2', '#008aac') // Bleu
          document.documentElement.style.setProperty('--ocreAlt3', '#53565A') // Cool Gray
          document.documentElement.style.setProperty('--ocreAlt4', '#000000') // Noir
          document.documentElement.style.setProperty('--ocreAlt5', '#FFFFFF') // Blanc

          document.documentElement.style.setProperty('--saumon0', '#F2F2F2') // Gris clair
          document.documentElement.style.setProperty('--saumon1', '#F2F2F2')
          document.documentElement.style.setProperty('--saumon2', '#F2F2F2')
          document.documentElement.style.setProperty('--saumon3', '#F2F2F2')
          document.documentElement.style.setProperty('--saumon4', '#F2F2F2')
          document.documentElement.style.setProperty('--saumon5', '#F2F2F2')
          document.documentElement.style.setProperty('--saumon6', '#F2F2F2')

          document.documentElement.style.setProperty('--pdtLib', '#E52E39') // Noir
          document.documentElement.style.setProperty('--pdtCode', '#E52E39') // Bleu
          document.documentElement.style.setProperty('--pdtPrix', '#008aac') // Bleu

          document.documentElement.style.setProperty('--gris-degrade1', '#FFFFFF')
          document.documentElement.style.setProperty('--gris-degrade2', '#FFFFFF')

          document.documentElement.style.setProperty('--txt', '#000000') // Noir
          document.documentElement.style.setProperty('--txtSpe', '#000000') // Noir
          document.documentElement.style.setProperty('--txtRubrique', '#000000')
          document.documentElement.style.setProperty('--txtSpeAlt', '#FFFFFF') // Blanc
          document.documentElement.style.setProperty('--txtAlt', '#000000') // Noir
          document.documentElement.style.setProperty('--txtBottom', '#000000') // Noir

          document.documentElement.style.setProperty('--devisTotalBg', '#FFFFFF') // Cool gray
          document.documentElement.style.setProperty('--devisTxt', '#FFFFFF') // Blanc
          document.documentElement.style.setProperty('--devisBg', '#E52E39') // Rouge
          document.documentElement.style.setProperty('--devisTxtAlt', '#FFFFFF') // Blanc
          document.documentElement.style.setProperty('--devisBgAlt', '#53565A') // Cool Gray

          document.documentElement.style.setProperty('--mobileDrawerBg', '#F2F2F2') // Cool Gray

          document.documentElement.style.setProperty('--typo_regular', 'inter_regular')
          document.documentElement.style.setProperty('--typo_light', 'inter_light')
          document.documentElement.style.setProperty('--typo_bold', 'inter_bold')

          document.documentElement.style.setProperty('--froid', '#008aac')
          break
        default:
          break;
      }
    }
  }

  if (UIStore.maintenanceMode !== null) {
    maintenanceMode = UIStore.maintenanceMode && !UIStore.disableMaintenanceMode
  }
  
  return (
    UIStore.appReady && //NOSONAR
    UIStore.maintenanceMode !== null && (
      <>
        <PWAPrompt
          copyTitle={_T('PWA_ADD_TO_SCREEN')}
          copyBody={_T('PWA_BODY')}
          copyShareButtonLabel={_T('PWA_SHARE_BTN')}
          copyAddHomeButtonLabel={_T('PWA_HOME_BTN')}
          copyClosePrompt={_T('PWA_CLOSE')}
        />

        {UIStore.environment_parameters.cookiebot && <CookieBot domainGroupId={UIStore.environment_parameters.cookiebot} />}

        <div className="loadingBarWrapper" >
          <LoadingBar action={UIStore.loadingaction} height={brand === 'thermorfr' ? 8 : 3}  color="#1e6abc" //NOSONAR
           />
          
          {/* <LoadingBar height={brand == 'thermorfr' ? 8 : 3}  color="#1e6abc" ref={loadingBarRef} /> */}
        </div>
        
        <ToastContainer />
        <Router>
          <Switch>
            <LoginRoute
              exact
              path="/:env/login"
              component={LoginPage}
              auth={UIStore.isAuthenticated}
              redirectToWhenAuth={defaultRoute}
              maintenanceMode={maintenanceMode}
            />
            <PrivateRoute
              exact
              path="/:env/home"
              component={HomePage}
              auth={UIStore.isAuthenticated}
              maintenanceMode={maintenanceMode}
            />
            <PrivateRoute
              exact
              path="/:env/help/:section?"
              component={HelpPage}
              auth={UIStore.isAuthenticated}
              maintenanceMode={maintenanceMode}
            />
            <PrivateRoute
              exact
              path="/:env/load_project/:id/:uid?"
              component={LoadProjectPage}
              auth={UIStore.isAuthenticated}
              maintenanceMode={maintenanceMode}
            />
            <PrivateRoute
              exact
              path="/:env/project/:step/:mode"
              component={ProjectPage}
              auth={UIStore.isAuthenticated}
              maintenanceMode={maintenanceMode}
            />
            <PrivateRoute
              exact
              path="/:env/project/:step"
              component={ProjectPage}
              auth={UIStore.isAuthenticated}
              maintenanceMode={maintenanceMode}
            />
            <PrivateRoute
              exact
              path="/:env/product/:section?"
              component={ProductInfos}
              auth={UIStore.isAuthenticated}
              maintenanceMode={maintenanceMode}
            />
            <PrivateRoute
              exact
              path="/:env/my_projects"
              component={MyProjects}
              auth={UIStore.isAuthenticated}
              maintenanceMode={maintenanceMode}
            />
            <PrivateRoute
              exact
              path="/:env/my_account"
              component={MyAccount}
              auth={UIStore.isAuthenticated}
              maintenanceMode={maintenanceMode}
            />
            <PrivateRoute
              exact
              path="/:env/satisfaction"
              component={SatisfactionPage}
              auth={UIStore.isAuthenticated}
              maintenanceMode={maintenanceMode}
            />
            <LoggedInCallbackRoute exact path="/loggedin" redirectToWhenAuth={defaultRoute} maintenanceMode={maintenanceMode} />
            <LogoutRoute exact path="/logout" maintenanceMode={maintenanceMode} />
            <Redirect path="*" to={defaultRoute2} />
          </Switch>
        </Router>
      </>
    )
  )
}

export default observer(App);
