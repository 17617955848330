import React from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';
import ProjectStore from "../../store/ProjectStore";
import {API_URL} from "../../config";

function ProductModalContent5(props) {
  const { product } = props; // NOSONAR

  const schema = product.product.code_schema + ProjectStore.project.schemaCode; // NOSONAR

  return (
    <div className="row chartRow">
      <div className="col col-12">
        {_T('INSTALL_RECOMMENDATION')}
        <img src={`${API_URL}/assets/data/schemas/${product.product.code_schema}/${schema}/${schema}.JPG`} width={"100%"} alt=""/> {/*NOSONAR*/}
      </div>
    </div>
  );
}

export default  observer(ProductModalContent5);