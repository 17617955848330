import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import UIStore from '../store/UIStore';
import * as userService from '../User/auth.service';
import queryString from 'query-string';

/**
 * Manage login callback from backend
 * Receive the jwt token by query params
 */
const LoggedInCallbackRoute = (props) => {
  const [loading, setLoading] = useState(true);
  const [authFailed, setAuthFailed] = useState(false);

  /**
   * Get jwt token by query params
   * then ask backend for user informations
   */
  useEffect( () => {
    async function fetchUserInfos() {
      const parsed = queryString.parse(props.location.search); //NOSONAR

      if(parsed.token === null) {
        setLoading(false);
        setAuthFailed(true);
      } else {
        userService.setToken(parsed.token);
  
        const results = await userService.getUserInfos();
  
        setLoading(false);
        setAuthFailed(!results);
      }
    }

    fetchUserInfos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Redirect to the page we want (this.props.redirectToWhenAuth)
   * or to the login page if auth failed: ie: no jwt token or unable to get user informations
   */
  function redirect (props) {
    let { redirectToWhenAuth } = props; //NOSONAR

    
    // Uncomment to pop a toast
    // if(!this.state.authFailed) {
    //   toast.success(_T("SUCCESS_AUTHENTICATION", {name: UIStore.user.username}))
    // } else {
    //   toast.error(_T("ERROR_DURING_AUTHENTICATION"))
    // }

    sessionStorage.removeItem('redirectTo');

    return (
      authFailed
        ? <Redirect to={{ pathname: '/', state: { from: props.location } }} //NOSONAR
         />
        : <Redirect to={{ pathname: redirectToWhenAuth, state: { from: props.location } }} //NOSONAR
         />
    );
  }


  let { ...rest } = props;

  return (
    loading
      ? <div>Loading...</div>
      : <Route {...rest} render={props => (UIStore.isAuthenticated && redirect(props) )} />
  );
  }

export default observer(LoggedInCallbackRoute);
