import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { _T, _THtml } from '../../_i18n/i18n.helper'
import NavigationButtons from '../../_components/Navigation/NavigationButtons'
import { toast } from 'react-toastify'
import * as apiService from '../../_services/api.service';
import { HelpPophover, HelpSideModal } from '../../_components/aide'

import UIStore from '../../store/UIStore'
import ProjectStore from '../../store/ProjectStore'
import formStore from './heatMode.store'
import HeatModeZone from './HeatModeZone'
import manageForm from '../../_components/forms/manageForm'
import Field from '../../_components/forms/Field'
import * as ddLoss from './dropDownLossMethods.data'
import * as luaService from '../lua.service'

import fleche_titre_atlantic from '../../assets/img/fleche-titre.svg'
import fleche_titre_thermorfr from '../../assets/img/fleche-titre-thermorfr.svg'
import fleche_titre_pacific from '../../assets/img/fleche-titre-pacific.svg'

import { API_URL } from '../../config'
import _ from 'lodash'

const imgChevrons = {
  atlantic: fleche_titre_atlantic,
  thermorfr: fleche_titre_thermorfr,
  pacific: fleche_titre_pacific
}

/**
 * Manage the HeatMode Form
 */

const HeatMode = (props) => { //NOSONAR
  const { errors, touched, onChange, onBlur } = props; // NOSONAR

  const [data, setData] = useState({
      departments: [],
      stations: [],
      deperditionMethods: ddLoss.getLossMethods(UIStore.environment_parameters.lossMethods),
      DeperdComponent: null,
      showSeaside: null
    });
  
  let deperdsLoading = null
  let debounced = null
  let fleche_titre = imgChevrons[UIStore.environment_parameters.brand] || fleche_titre_atlantic

  useEffect(() => {
    async function fetchDepartments() {
      // registrer the submit function so it can be called by the BreadcrumbMobile component
      props.registerChildSubmit(submit); // NOSONAR

      if (ProjectStore.project.forms.heatModeStep.form) {
        formStore.form = { ...formStore.form, ...ProjectStore.project.forms.heatModeStep.form }
      }
      
      const settings = await apiService.get(`parameters/env=${UIStore.environnement_id}`);

      let bordDeMer = settings.filter((i) => i.code == 'bordDeMer')[0];

      if(bordDeMer) {
        bordDeMer = settings.filter((i) => i.code == 'bordDeMer')[0].value;
      }

      const departments = formStore.form.departmentsList || (await luaService.getDepartments())
      formStore.form.departmentsList = departments;

      setData({
        ...data,
        departments: departments || [],
        stations: formStore.form.stationsList || [],
        showSeaside: bordDeMer
      })
    }

    fetchDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.departments && !formStore.form.stationsList) onDepartmentChange()
    if (data.departments.length !== 0) {
      onDeperditionMethodChange(null, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.departments])

  useEffect(() => {
    if (window.scroll) window.scrollTo(0, document.body.scrollHeight)
  }, [data.DeperdComponent])

  const getTbase = async (e) => {
    // debugger
    formStore.form.tbase = await luaService.getTbase(formStore.form.departement, formStore.form.altitude, formStore.form.seaside)
    formStore.form.tbaseModified = false;
    calculateDeperd() // to do // NOSONAR
  }

  const onDepartmentChange = async (e) => {
    if (e) props.onChange(e); // NOSONAR
    if (formStore.form.departement && formStore.form.departement !== '') {
      const stations = await luaService.getStations(formStore.form.departement)
      formStore.form.stationsList = stations

      setData({
        ...data,
        stations: stations,
      })
    }
  }

  const onStationChange = async (e) => {
    props.onChange(e); // NOSONAR
    const selectedStation = data.stations.find((item) => item.code === formStore.form.stationMeteo)
    formStore.form.altitude = selectedStation ? selectedStation.altitude : 0

    calculateDeperd()
    getTbase()
  }

  const onSeasideChange = async (e) => {
    props.onChange(e); // NOSONAR
    getTbase()
  }

  const onAltitudeChange = async (e) => {
    onChangeCalculateDeperds(e)
    getTbase()
  }

  const onTbaseChange = async (e) => {
    props.onChange(e); // NOSONAR
    formStore.form.tbaseModified = true;
    calculateDeperd();
  }

  const onChangeCalculateDeperds = async (e) => {
    props.onChange(e); // NOSONAR
    calculateDeperd();
  }

  const onMitoyenneteChange = (code, label) => {
    formStore.updateMitoyennete(code, label)
    calculateDeperd()
  }

  const onLevelChange = (code, label) => {
    formStore.updateLevel(code, label)
    calculateDeperd()
  }

  const onDeperditionMethodChange = (e, scroll) => {
    if (scroll == null) scroll = true; // NOSONAR
    if (e) props.onChange(e); // NOSONAR
    calculateDeperd()
  
    const selectedDeperditionMethod = data.deperditionMethods?.find(
      (item) => item.code === formStore.form.deperditions.deperditionMethod,
    )
    if (selectedDeperditionMethod) {
      setData({
        ...data,
        DeperdComponent: selectedDeperditionMethod.component,
      })
      
    }
  }

  const addZone = () => {
    formStore.addZone()
  }

  const removeZone = (e, index) => {
    formStore.removeZone(index)
  }

  const calculateDeperd = () => {
    if (!debounced) {
      debounced = _.debounce(calculateDeperdAsync, 500)
    }

    debounced()
  }

  const calculateDeperdAsync = async () => { //NOSONAR
    if (!_.isEmpty(formStore.form.deperditions.deperditionMethod)) {
      props
        .validate('touch_no_field') // NOSONAR
        .then(async () => {
          ProjectStore.updateForm('heatModeStep', { form: toJS(formStore.form) })
          deperdsLoading = luaService.getLoss()

          ProjectStore.project.deperds = await deperdsLoading

          console.log(ProjectStore.project.deperds.Study.StudyMaximumTempTransmitter)

          if (ProjectStore.project.deperds?.Study?.StudyMaximumTempTransmitter) {
            let maxTemp = formStore.form.zones[0].temperature
            if (formStore.form.zones.length > 1) {
              formStore.form.zones.forEach((z) => {
                if (z.temperature > maxTemp) maxTemp = z.temperature
              })
            }

            if (ProjectStore.project.deperds.Study.StudyMaximumTempTransmitter < parseInt(maxTemp)) {
              ProjectStore.setNewTransmitterTemp(ProjectStore.project.deperds.Study.StudyMaximumTempTransmitter)
            } else {
              ProjectStore.setNewTransmitterTemp(null)
            }
          }

          deperdsLoading = null
        })
        .catch(() => {
          ProjectStore.project.deperds = null
        })
    }
  }

  const submit = async () => { //NOSONAR
    let promise
    let showProgressBar = false
    //let coolModeOk = false

    if (deperdsLoading != null) {
      showProgressBar = true
     // UIStore.loadingBar.continuousStart()
      UIStore.loadingContinuousStart()
      promise = deperdsLoading
    } else {
      promise = Promise.resolve()
    }

    return promise
      .then(() => {

        /**
         * if (showProgressBar) UIStore.loadingBar.complete()
         */
        
        if (showProgressBar) UIStore.loadingComplete()
        return props.validate(); // NOSONAR
      })
      .then(async () => {
        ProjectStore.resetResults()
        ProjectStore.updateForm('heatModeStep', { form: toJS(formStore.form) })

        // If coolMode on, we have to check emitter type
        if (ProjectStore.project.simulationCool) {
          let coolModeOk = false;
          formStore.form.zones.forEach((zone) => {
            if (zone.emetteur != 4 && zone.emetteur != 6 && zone.emetteur != 7) coolModeOk = true;
          })

          if (!coolModeOk) return Promise.reject({ coolModeEmitterError: true, msg: _T('COOL_MODE_EMITTER_ERROR') }); // NOSONAR
        }

        return Promise.resolve()
      })
      .catch((err) => {
        // toast.error(_T(err.errors.join(', ')))
        if (err?.coolModeEmitterError) toast.error(err.msg)
        else toast.error(_T('SOME_FIELDS_NEED_YOUR_ATTENTION'))
        return Promise.reject(err)
      })
  }

  const brand = UIStore.environment_parameters.brand

  return (
    <>
      {data.showSeaside !== null && <form id="form_stepHome" className="heatMode" onSubmit={(e) => e.preventDefault()}>
        <div className="row donneesGEO">
          <div className="col col-12 col-lg-2 titreRubrique">
            <p className={brand === "pacific" ? "pacific" : undefined}>{_THtml('GEOGRAPHICAL_DATA')}</p>
            {brand !== "pacific" && <img className="chevron" src={fleche_titre} alt="" />}
          </div>

          <div className="col col-12 col-lg-10 contentRubrique">
            <div className="form">
              <div className="row">
                <div className="col col-12 col-lg-4">
                  <Field
                    className="custom-select"
                    type="select"
                    name="departement"
                    id={brand !== 'thermorfr' && brand !== 'pacific' ? 'data_departement' : 'data_departement_thermor'}
                    placeholder={_T('DEPARTMENT')}
                    onChange={onDepartmentChange}
                    onBlur={onBlur}
                    value={formStore.form.departement}
                    errors={errors}
                    touched={touched}
                    defaultValue={true}
                    translate={false}
                    items={data.departments}
                  />
                </div>

                <div className="col col-12 col-lg-4">
                  <Field
                    className="custom-select"
                    type="select"
                    name="stationMeteo"
                    id="data_meteo"
                    placeholder={_T('CITY')}
                    onChange={onStationChange}
                    onBlur={onBlur}
                    value={formStore.form.stationMeteo}
                    errors={errors}
                    touched={touched}
                    defaultValue={true}
                    translate={false}
                    items={data.stations}
                  />
                </div>

                <div className="col col-12 col-lg-4">
                  <Field
                    className="form-control"
                    type="number"
                    name="altitude"
                    id="data_altitude"
                    placeholder={_T('ALTITUDE')}
                    unit="m"
                    min={0}
                    onChange={onAltitudeChange}
                    onBlur={onBlur}
                    value={formStore.form.altitude}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col col-12 col-lg-4">
                  <Field
                    className="form-control"
                    type="text"
                    name="placesaid"
                    id={'data_placesaid'}
                    placeholder={_T('PLACE_SAID')}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={formStore.form.placesaid}
                    errors={errors}
                    touched={touched}
                    defaultValue={true}
                    translate={false}
                  />
                </div>

                {data.showSeaside === true && <div className="col col-12 col-lg-4">
                  <Field
                    type="checkbox"
                    name="seaside"
                    id="data_seaside"
                    placeholder={_T('SEASIDE')}
                    value={formStore.form.seaside}
                    onChange={onSeasideChange}
                    errors={errors}
                    touched={touched}
                    defaultValue={false}
                  />
                  <button className="help" style={{ right: 'auto' }} id='helpSeaSide'></button>
                  <HelpPophover targetId='helpSeaSide'>
                    <pre dangerouslySetInnerHTML={{ __html: _T('HELP_SEASIDE') }}></pre>
                    <div></div>
                  </HelpPophover>
                </div>}

                <div className="col col-12 col-lg-4">
                  <Field
                    className="form-control"
                    type="number"
                    name="tbase"
                    id="data_tbase"
                    placeholder={_T('TEMP_BASE')}
                    unit="°C"
                    min={-50}
                    max={50}
                    step={1}
                    onBlur={onBlur}
                    onChange={onTbaseChange}
                    value={formStore.form.tbase}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row donneesHAB" //NOSONAR
        >
          <div className="col col-12 col-lg-2 titreRubrique" //NOSONAR
          >
            <p className={brand === "pacific" ? "pacific" : undefined} //NOSONAR
            >{_THtml('HABITAT_DATA')}</p>
            {brand !== "pacific" && <img 
              className="chevron" //NOSONAR
              src={fleche_titre} //NOSONAR
              alt="" //NOSONAR
            />}
          </div>

          <div className="col col-12 col-lg-10 contentRubrique" //NOSONAR
          >
            <div className="row" //NOSONAR
            >
              <div className="maison_mitoyennete" //NOSONAR
              >
                <p>{_T('JOINT_OWNERSHIP')}</p>
                <button className="preload maison_seule" //NOSONAR
                >
                  <span></span>
                </button>
                <button className="preload maison_1mur active" //NOSONAR
                >
                  <span></span>
                </button>
                <button className="preload maison_2murs active" //NOSONAR
                >
                  <span></span>
                </button>
                <button
                  className={formStore.form.mitoyennete === 0 ? //NOSONAR
                    brand === "pacific" ? // NOSONAR
                      'maison_seule active pacific'
                      :
                      'maison_seule active'
                    :
                    brand === "pacific" ? // NOSONAR
                      'maison_seule pacific'
                      :
                      'maison_seule'}
                  onClick={() => onMitoyenneteChange(0, '0_WALL')} //NOSONAR
                >
                  <span></span>
                  {_T('0_WALL')}
                </button>
                <button
                  className={formStore.form.mitoyennete === 1 ? //NOSONAR
                    brand === "pacific" ? // NOSONAR
                      'maison_1mur active pacific'
                      :
                      'maison_1mur active'
                    :
                    brand === "pacific" ? // NOSONAR
                      'maison_1mur pacific'
                      :
                      'maison_1mur'}
                  onClick={() => onMitoyenneteChange(1, '1_WALL')} //NOSONAR
                >
                  <span></span>
                  {_T('1_WALL')}
                </button>
                <button
                  className={formStore.form.mitoyennete === 2 ? //NOSONAR
                    brand === "pacific" ? // NOSONAR
                      'maison_2murs active pacific'
                      :
                      'maison_2murs active'
                    :
                    brand === "pacific" ? // NOSONAR
                      'maison_2murs pacific'
                      :
                      'maison_2murs'}
                  onClick={() => onMitoyenneteChange(2, '2_WALLS')} //NOSONAR
                >
                  <span></span>
                  {_T('2_WALLS')}
                </button>
              </div>

              <div className="maison_type" //NOSONAR
              >
                <p>{_T('HABITAT_TYPE')}</p>
                <button className="preload maison_rdc" //NOSONAR
                >
                  <span></span>
                </button>
                <button className="preload maison_r1 active" //NOSONAR
                >
                  <span></span>
                </button>
                <button
                  className={formStore.form.level === 0 ? //NOSONAR
                    brand === "pacific" ? // NOSONAR
                      'maison_rdc active pacific'
                      :
                      'maison_rdc active'
                    :
                    brand === "pacific" ? // NOSONAR
                      'maison_rdc pacific'
                      :
                      'maison_rdc'}
                  onClick={() => onLevelChange(0, '0_LEVEL')} //NOSONAR
                >
                  <span></span>
                  {_T('0_LEVEL')}
                </button>
                <button
                  className={formStore.form.level === 1 ? //NOSONAR
                    brand === "pacific" ? // NOSONAR
                      'maison_r1 active pacific'
                      :
                      'maison_r1 active'
                    :
                    brand === "pacific" ? // NOSONAR
                      'maison_r1 pacific'
                      :
                      'maison_r1'}
                  onClick={() => onLevelChange(1, '1_LEVEL')} //NOSONAR
                >
                  <span></span>
                  {_T('1_LEVEL')}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row donneesDESC" //NOSONAR
        >
          <div className="col col-12 col-lg-2 titreRubrique" //NOSONAR
          >
            <p className={brand === "pacific" ? "pacific" : undefined} //NOSONAR
          >{_THtml('HABITAT_DESCRIPTION')}</p>
            {brand !== "pacific" && <img 
              className="chevron" //NOSONAR
              src={fleche_titre} //NOSONAR
              alt="" //NOSONAR
            />}
          </div>

          <div className="col col-12 col-lg-10 contentRubrique" //NOSONAR
          >
            <div className="form" //NOSONAR
            >
              {formStore.form.zones?.map((zone, index) => (
                  <HeatModeZone
                    key={index} //NOSONAR
                    index={index}
                    zone={zone}
                    errors={errors}
                    touched={touched}
                    onChange={onChangeCalculateDeperds}
                    onBlur={onBlur}
                    addZone={addZone}
                    removeZone={removeZone}
                  />
                ))}
            </div>
          </div>
        </div>

        <div className="row donneesDEP" //NOSONAR
        >
          <div className="col col-12 col-lg-2 titreRubrique" //NOSONAR
          >
            <p className={brand === "pacific" ? "pacific" : undefined} //NOSONAR
            >
              {_THtml('LOSS')}
              <button id="helpLoss" //NOSONAR
              ></button>
            </p>
            {/*<HelpPophover targetId='helpLoss'>
                    <pre dangerouslySetInnerHTML={{__html: _T('HELP_LOSS', {api_url: API_URL})}}></pre>
                    <div></div>
                  </HelpPophover>*/}
            <HelpSideModal targetId="helpLoss">
              <div dangerouslySetInnerHTML={{ __html: _T('HELP_LOSS', { api_url: API_URL }) }} //NOSONAR
              ></div>
              <div></div>
            </HelpSideModal>
            {brand !== "pacific" && <img 
              className="chevron" //NOSONAR
              src={fleche_titre} //NOSONAR
              alt="" //NOSONAR
            />}
          </div>

          <div className="col col-12 col-lg-10 contentRubrique" //NOSONAR
          >
            <div className="row deperdition" //NOSONAR
            >
              <div className="form" //NOSONAR
              >
                <div className="row" //NOSONAR
                >
                  <div className="col col-12 col-lg-4" //NOSONAR
                  >
                    <Field
                      id="data_methode"
                      className="custom-select"
                      type="select"
                      name="deperditions.deperditionMethod"
                      placeholder={_T('LOSS_METHOD')}
                      onChange={onDeperditionMethodChange}
                      onBlur={onBlur}
                      value={formStore.form.deperditions.deperditionMethod}
                      errors={errors}
                      touched={touched}
                      defaultValue={true}
                      items={data.deperditionMethods}
                    />
                  </div>
                </div>
              </div>
            </div>

            {data.DeperdComponent && (
              <data.DeperdComponent
                deperditions={formStore.form.deperditions}
                errors={errors}
                touched={touched}
                onChange={onChange}
                onBlur={onBlur}
                calculateDeperd={calculateDeperd}
              />
            )}
          </div>
        </div>
      </form>
      }    
      <NavigationButtons handleSubmit={submit} hidePrev={true} />
    </>
  )
}


export default manageForm(observer(HeatMode), formStore, 'form')
