import React from 'react';
import { _T } from '../_i18n/i18n.helper';
import { Link } from "react-router-dom";
import UIStore from '../store/UIStore';

import logo_projipac_noir from '../assets/img/logo-projipac-noir.svg';
import logo_thermor_rouge from '../assets/img/logo-thermor-rouge.png';
import logo_pacific from '../assets/img/logo-pacific-project.png';
import MainMenu from "../_components/Navigation/MainMenu";
import Breadcrumb from "../_components/Navigation/Breadcrumb";
import FaqSection from "./FaqSection";
import TutoSection from "./TutoSection";
import ChangelogSection from "./ChangelogSection";
import CGUSection from "./CGUSection";

// list of section components
const sectionMap = {
  tutorial: TutoSection,
  faq: FaqSection,
  changelog: ChangelogSection,
  cgu: CGUSection,
};

export default function HelpPage(props) {
  let { section } = props.match.params; // NOSONAR

  let brand = UIStore.environment_parameters.brand;

  if (!section) section = 'tutorial';
  const Section = sectionMap[section];

  const imgSideBar = () => {
    if (brand === "thermorfr") {
      return <img src={logo_thermor_rouge} alt="Logo Projipac" width="182" height="130" />
    } else if (brand === "pacific") {
      return <img src={logo_pacific} alt="Logo Projipac" width="130" height="130" />
    } else {
      return <img src={logo_projipac_noir} alt="Logo Projipac" width="122" height="119" />
    }
  } 

  return (
    <div className="pageAide">
      <div className="header container-fluid withoutBreadcrumb">
        <MainMenu />
        <Breadcrumb title={_T('HELP_TITLE')} />
      </div>

      <div className={brand === "pacific" ? "main container-fluid pacific" : "main container-fluid"}>
        <div className="row blocAide">
          <div className="col col-12 col-md-3 sidebar">
            {imgSideBar()}
            <nav className="insideMenu">
              <ul>
                <li className={section === 'tutorial' ? 'active' : ''}><Link to={`/${UIStore.env}/help`}><span></span>{_T('TUTORIAL')}</Link></li>
                <li className={section === 'faq' ? 'active' : ''}><Link to={`/${UIStore.env}/help/faq`}><span></span>{_T('FAQ')}</Link></li>
                <li className={section === 'changelog' ? 'active' : ''}><Link to={`/${UIStore.env}/help/changelog`}>{_T('CHANGELOG')}</Link></li>
                <li className={section === 'cgu' ? 'active' : ''}><Link to={`/${UIStore.env}/help/cgu`}>{_T('CGU')}</Link></li>
                <li><Link to={`/${UIStore.env}/satisfaction`}>{_T('SATISFACTION')}</Link></li>
              </ul>
            </nav>
          </div>

          <Section />
        </div>
      </div>

    </div>
  )
}
