import intl from 'react-intl-universal';

/**
 * helper for translation, return the translatedString or default the translation code
 * @param string
 * @returns {*}
 * @private
 */
export function _THtml (code, variables) {
  return code ? intl.getHTML(code, variables).d(code) : '';
}

export function _T (code, variables) {
  return code ? intl.get(code, variables).d(code) : '';
}