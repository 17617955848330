import React, { useState } from 'react';
import { Button } from 'reactstrap';
import Files from 'react-files';
import axios from 'axios';
import UIStore from '../../../../store/UIStore'
import {API_URL} from '../../../../config'
import {_T} from '../../../../_i18n/i18n.helper'
import styles from './FileUploader.module.scss';

/**
 * @prop {[string]} types content-type like ['image/*']
 * @prop {int} fileSize (optional) default 50000000
 * @prop {string} filename (optional) force upload filename, DANGER if this file already exists in back, it will be overwrited
 * @prop {callBack(filename)} onUpload (optional) triggered when a file starts to uploading, first parameter is the filename being upladed
 * @prop {callBack(model)} onUploaded(optional) model is set to model provided by api if success, null otherwise
 * @prop {callBack} onError (optional) triggered when error occured
 * @prop {string} value (required) for edit an exist model, can be null for no
 * @prop {string} forceText (optional) force display text, usefull when edit with delete old file
 * @prop {bool} disableDelete (optional) for disable the delete button
 * to do traduction
 */


function FileUploader(props) {
  /* eslint-disable react/prop-types */
  const { onUpload, onUploaded, filename, value, type, fileSize } = props; //NOSONAR

  const [, setUploading] = useState(false); //NOSONAR
  const [err, setErr] = useState(false);
  const [, setFile] = useState(""); //NOSONAR
    
  const fileChange = (e) => {
    let data = new FormData();
    let model;
    setErr(false);

    if (!e.length) {
      setErr(_T('Le fichier envoyé ne correspond pas au type demandé'));
      return;
    }
    setUploading(true);

    if (onUpload)
      onUpload(e[0].name);

    data.append('userId', UIStore.user.id);
    data.append('name', filename || e[0].name);
    data.append('file', e[0]);

    let promise;
    if (value) {
      promise = axios.put(API_URL + '/file/' + value.id, data) //NOSONAR
    } else {
      promise = axios.post(API_URL + '/file', data)
    }
    promise.then((res) => {
      model = res.data;
      setFile(res.data.name);
    })
    .catch((err) => {
      model = null;
      fileErr(err.response.statusText, e[0].name);
    })
    .finally(() => {
      setUploading(false);
      e.shift();
      if (onUploaded)
        onUploaded(model);
    })
  }

  const fileErr = (err, file) => {
    setErr(err);
  }

  return (
    <div className={styles.fileUploader}>
            <Files 
              // ref='files' // to do
              onChange={fileChange}
              onError={fileErr}
              accepts={type}
              maxFiles={1}
              maxFileSize={fileSize || 1000000000}
              clickable
            >
              <Button color='primary'>{_T('UPDATE_LOGO')}</Button>
            </Files>
        {err && <p className={styles.err}>{_T('Une erreur est survenue.')}</p>}
    </div>
  );
  
}

export default FileUploader;
