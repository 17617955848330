import intl from 'react-intl-universal'
import numeral from 'numeral'
import { API_URL } from '../config'
import ax from 'axios'
import UIStore from '../store/UIStore'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/en-gb'
import 'moment/locale/de'
import 'moment/locale/nl'
import 'moment/locale/pl'
import 'moment/locale/es'
import 'moment/locale/pt'
import 'moment/locale/it'
import 'moment/locale/bg'
import 'moment/locale/el'
import 'moment/locale/sr'
import 'moment/locale/sv'
import 'moment/locale/fi'
import 'moment/locale/da'
import 'moment/locale/et'
import 'moment/locale/hu'
import 'moment/locale/lt'
import 'moment/locale/hr'
import 'moment/locale/sl'

/**
 * Load the lang locale form the backend
 * @param lang
 * @returns {Promise<*>}
 */
export async function loadLocale(lang) {
  try {
    const response = await ax.get(`${API_URL}/formattedLocale/${lang}`)
    return response.data
  } catch (error) {
    console.error(error)
    return false
  }
}

/**
 * Load the "lang" locale and refresh App
 * @param lang
 * @returns {Promise<void>}
 */
export async function translate(lang) { //NOSONAR
  if (!lang) {
    if(UIStore.env === 'pac-air-eau') lang = 'zu'
    else if(UIStore.env === 'pacific') lang = 'za'
    else {
      if(lang === 'zu' || lang === 'za') lang = null
      if(UIStore.lang !== 'zu' && UIStore.lang !== 'za') lang = UIStore.lang
      if(!lang) lang = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage || 'fr-FR' //NOSONAR
    }
  }

  const locales = {}

  try {
    await import(`numeral/locales/${lang}`)
    numeral.locale(lang)
  } catch (ex) {
    await import('numeral/locales/fr')
    numeral.locale('fr')
  }

  try {
    switch (lang) {
      case 'vls':
        moment.locale('nl')
        break
      case 'en':
        moment.locale('en-gb')
        break
      default:
        moment.locale(lang)
        break
    }
  } catch (ex) {
    moment.locale('en-gb')
  }

  console.log('locale:', moment.locale())

  locales[lang] = await loadLocale(lang)

  intl
    .init({
      currentLocale: lang,
      locales,
    })
    .then(() => {
      UIStore.setLanguage(lang)

      // reload app if its a language switch
      UIStore.appReady ? window.location.reload() : UIStore.appIsReady()
    })
}
