export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function resetObject(obj, value) {
  Object.keys(obj).forEach(k => obj[k] = value || null);
}

/**
 * Find an item in a combo items list a,d return it's label, or code if not found
 * @param arr the combo items list
 * @param value the code of the item to find
 * @returns {*}
 */
export function getLabelFromCode(arr, value) {
  const found = arr.find(item => item.code === value);
  return found ? found.label : value;
}

export function nl2br(string) {
  return string.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

// eslint-disable-next-line no-extend-native
Number.prototype.round = function(places) { // NOSONAR
  return +(Math.round(this + "e+" + places)  + "e-" + places); // NOSONAR
};

String.isNullOrEmpty = function(value) {
  return !(typeof value === "string" && value.length > 0);
}