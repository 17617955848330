/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { API_URL } from '../config'
import { _T, _THtml } from '../_i18n/i18n.helper'

import logo_projipac from '../assets/img/logo-projipac.svg'
import logo_simulhome from '../assets/img/logo-simulhome.png';
import logo_pacific from '../assets/img/logo-pacific-project.png';
import LanguageSwitch from '../_i18n/languageSwitch'
import UIStore from '../store/UIStore'
import FogotPwdModal from './ForgotPwdModal'

/**
 * Home Page
 */

const LoginPage = (props) => {
  const { history } = props; // NOSONAR
  const [data, setData] = useState({
    dropdownOpen: false,
    openForgotPwdModal: false
  })

  useEffect(() => {
    try {
      if (history.location.state.from.pathname) { // NOSONAR
        sessionStorage.setItem('redirectTo', history.location.state.from.pathname) // NOSONAR
      }
    } catch (ex) {
      //
    }
  });

  // const toggle = () => setData({...data, dropdownOpen: !data.dropdownOpen}) // NOSONAR

  const openForgotPwdModal_ = () => setData({...data, openForgotPwdModal: true});

  const closeForgotPwdModal_ = () => setData({...data, openForgotPwdModal: false});

  let brand = UIStore.environment_parameters.brand

  let className = 'pageHome'
  if (brand === 'thermorfr') className += ' pageHomeThermor'
  else if (brand === 'pacific') className += ' pageHomePacific'

  let url = `${API_URL}/login/?env=${UIStore.env}&lang=${UIStore.lang}&callbackurl=${window.location.origin}`
  let urlInterne = `${url}&interne=true`

  const atlantic_sso_user = document.cookie.split('; ').filter(row => row.startsWith('atlantic_sso_user=')).map(c => c.split('=')[1])[0]
  if (atlantic_sso_user) {
    window.location = url
  }

  return (
    <div className={className}>
      <div className="header">
        {brand !== 'thermorfr' &&
          <>
            {brand !== 'pacific' && <LanguageSwitch />}
            <img src={UIStore.logo} height="40" alt="Logo" />
          </>}
      </div>

      <div className="main container-fluid">
        <div className="row blocHome">
          {brand === 'thermorfr' ?
            <div className="blocProjipac blocSimulhome">
              <img src={logo_simulhome} width="380" height="257" alt="Logo Simul'home" />
            </div>
            :
            brand === "pacific" ? // NOSONAR
              <div className="blocProjipac blocProjipacPacific">
                <img src={logo_pacific} width="100" height="100" alt="Logo Pacific" />
                <p className='pacificText'>Pompes à chaleur Air/Eau</p>
              </div>
              :
              <div className="blocProjipac">
                <p>Proji-Pac</p>
                <img src={logo_projipac} width="120" height="120" alt="Logo Projipac" />
              </div>}

          <div className={brand === 'thermorfr' ?
            "blocHomeTitre blocHomeTitrePJP"
            :
            brand === 'pacific' ? // NOSONAR
              "blocHomeTitre blocHomeTitrePacific"
              :
              "blocHomeTitre"}>
            <a className="btn zoom" href={UIStore.isNeuf ? urlInterne : url}>
              {UIStore.isThermor ? _T('THERMOR_SSO_CONNEXION_BUTTON') : _T('ATL_SSO_CONNEXION_BUTTON')}
            </a>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {!UIStore.isEnvFr && (
                <a className="createAccount" href="https://accounts.groupe-atlantic.com/">
                  {_T('CREATE_YOUR_ACCOUNT')}
                </a>
              )}
              {UIStore.environnement_sso == 'false' && (
                <a // NOSONAR
                  className="createAccount" // NOSONAR
                  href="#" // NOSONAR
                  onClick={openForgotPwdModal_} // NOSONAR
                >
                  {_T('FORGOT_PASSWORD')}
                </a>
              )}
            </div>
          </div>

          <p className={brand === "pacific" ? "BlocHomeTexte pacific" : "BlocHomeTexte"}>{_THtml('HOME_WELCOME_TEXT')}</p>
        </div>
      </div>

      {!UIStore.isNeuf && <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
        <a className="loginInterne" href={urlInterne}>
          {_T('AZUREAD_CONNEXION_BUTTON')}
        </a>
      </div>}

      <FogotPwdModal modalIsOpen={data.openForgotPwdModal} close={closeForgotPwdModal_} />
    </div>
  )
}

export default observer(LoginPage);
