import React, { useEffect, useState } from 'react';
import { API_URL } from '../config';
import * as tutorialService from './tutorial.service';
import { _T } from '../_i18n/i18n.helper';
import { toast } from 'react-toastify';

/**
 * Home Page
 */
export default function TutoSection(props) {
  const [data, setData] = useState({
    tutorials: null,
    categories: null
  });

  useEffect(() => {
    tutorialService.getAll()
      .then((e) => {
        let tutorials = e;
        let categories = [];
        tutorials.map(i => {
          if (!categories.includes(i.category)) {
            categories.push(i.category);
          }

          return categories;
        })
        setData({
          tutorials: tutorials,
          categories: categories
        })
      })
      .catch((e) => {
        toast.error(_T('ERROR_OCCURED'));
      })
  }, []);


  return (
    <div className="contentAide col col-12 col-md-9 tuto">
      <div>
        {data.tutorials && data.categories.map(i =>
          <div style={{ marginTop: '40px' }} key={i}>
            <p>{i}</p>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {data.tutorials.filter(j => j.category === i).map(j => (
                <div key={j.id} style={{ margin: '10px' }}>
                  <button className="btn_play"></button>
                  <video controls src={`${API_URL}/${j.video.filepath}`} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}