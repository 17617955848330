import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as userService from '../User/auth.service';
import UIStore from '../store/UIStore';
import { LOGOUT_REDIRECT_URL, API_URL, SSO_URL } from '../config';
import MaintenancePage from '../Maintenance/MaintenancePage';
/**
 * Manage Logout
 * Logout and redirect to Home
 * @param rest
 * @returns {*}
 * @constructor
 */

export function LogoutRoute(props) {
  const [data, setData] = useState({
    isLogout: false,
    loading: true,
    accessToken: UIStore?.user?.openid_data.accessToken.token
  });

  // Do asynchronous action here
  useEffect(() => {
    async function fetchUserInfos() {
      if (UIStore.isAuthenticated) {
        try {
          const isLogout = await userService.logout();
          setData({
            ...data,
            isLogout: isLogout,
            loading: false
          })
        } catch (err) {
          // error handling
        }
      } else {
        setData({
          ...data,
          loading: false
        })
      }
    }

    fetchUserInfos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { maintenanceMode } = props; //NOSONAR

  if (maintenanceMode) return <MaintenancePage />

  const logoutUrl = UIStore.environnement_sso == 'true' ?
    SSO_URL + '/oidc/logout?id_token_hint=' + data.accessToken + '&post_logout_redirect_uri=' + window.location.origin + '/'
    :
    'https://identity.groupe-atlantic.com:9443/commonauth?commonAuthLogout=true&type=type&sessionDataKey=1234567&commonAuthCallerPath=' + API_URL + '/auth/openid/callback&relyingParty=' + LOGOUT_REDIRECT_URL;

  if (data.loading) {
    return <>Loading...</>;
  } else {
    if (data.isLogout) {
      return <Route {...props} component={() => {
        window.location.href = logoutUrl
        return null;
      }} />
    }
    return <Route {...props}
      render={props => (<Redirect to={{ pathname: '/', state: { from: props.location } }} //NOSONAR
       />)} />;
  }
}
