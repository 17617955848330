import React from 'react'
import {observer} from 'mobx-react';

import formStore from "../form.store";
import Field from "../../_components/forms/Field";
import { _T } from '../../_i18n/i18n.helper';


import styles from './rates.module.scss';

 const RatesSatisfaction = (props) => {
    const { onChange } = props; //NOSONAR

    return(
      <div className={styles.rateSatisfaction}>
        <RateLine name='ACCESS_FACILITY' onChange={onChange} value={formStore.form.ACCESS_FACILITY}  description={_T('ACCESS_FACILITY_PLUS')}/>
        <RateLine name='ERGONOMY' onChange={onChange} value={formStore.form.ERGONOMY} />
        <RateLine name='SIMPLICITY' onChange={onChange} value={formStore.form.SIMPLICITY} />
        <RateLine name='INTUITIVENESS' onChange={onChange} value={formStore.form.INTUITIVENESS} />
        <RateLine name='FIABILITY' onChange={onChange} value={formStore.form.FIABILITY} />
        <RateLine name='RAPIDITY' onChange={onChange} value={formStore.form.RAPIDITY} />
        <RateLine name='LISIBILITY' onChange={onChange} value={formStore.form.LISIBILITY} />
        <RateLine name='INFORMATIONS' onChange={onChange} value={formStore.form.INFORMATIONS} />
        <RateLine name='DESIGN' onChange={onChange} value={formStore.form.DESIGN} />
      </div>
    )
  }

const RateLine = ({value, onChange, name, description}) => ( //NOSONAR
  <div className={styles.rateLine}>
    <div className={styles.left}>
      <span className={styles.leftContent}>
        <p className={styles.title}>{_T(name)}</p>
        <p className={styles.description}>{description}</p>
      </span>
    </div>
    <div className={styles.right}>
      <div className={styles.rightContent}>
        <Field 
          type='rater'
          onChange={onChange}
          value={value}
          name={name}
          />
      </div>
    </div>
  </div>
)

export default observer(RatesSatisfaction);