import {makeObservable, observable, action } from 'mobx';
import * as Yup from 'yup';
import {_T} from "../../_i18n/i18n.helper";

class CoolModeStore {

  formValidationScheme = Yup.object().shape(
    {
      nbPeoples: Yup.number().required(),
      inertia: Yup.string().required(),
      location: Yup.string().required(),
      temperature: Yup.number().required(),
      econome: Yup.number().required(),
      config: Yup.array()
        .of(Yup.object().shape({
          orientation: Yup.string(),
          mitoyen: Yup.string().required(),
          length: Yup.number().required(),
          surface: Yup.string().required(),
          occultant: Yup.string().required(),
        })),
    }
  );

  defaultForm = {
    nbPeoples: undefined,
    inertia: undefined, inertia_i18nCode: '',
    location: undefined, location_i18nCode: '',
    temperature: '24',
    econome: '1', econome_i18nCode: _T('ECONOME'),
    config: [
      {
        orientation: '1', orientation_i18n: _T('NORTH'),
        mitoyen: undefined, mitoyen_i18n: '',
        length: '',
        surface: '',
        occultant: undefined, occultant_i18n: '',
      },
      {
        orientation_i18n: _T('EST'),
        mitoyen: undefined, mitoyen_i18n: '',
        length: '',
        surface: '',
        occultant: undefined, occultant_i18n: '',
      },
      {
        orientation_i18n: _T('SOUTH'),
        mitoyen: undefined, mitoyen_i18n: '',
        length: '',
        surface: '',
        occultant: undefined, occultant_i18n: '',
      },
      {
        orientation_i18n: _T('WEST'),
        mitoyen: undefined, mitoyen_i18n: '',
        length: '',
        surface: '',
        occultant: undefined, occultant_i18n: '',
      },
      {
        orientation_i18n: _T('ROOF'),
        mitoyen: '0', mitoyen_i18n: _T('NO'),
        length: '0',
        surface: '0',
        occultant: '0', occultant_i18n: _T('NO'),
      }
    ]
  };

  form = {...this.defaultForm};

  constructor() {
    makeObservable(this, {
      form: observable,
      initNewProject: action,

    })
  }
  
  initNewProject() {
    this.form = {...this.defaultForm};
  }

}

export default new CoolModeStore();