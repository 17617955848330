import React from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';
import ProjectStore from '../../store/ProjectStore';
import numeral from 'numeral';

const EstimatedLoss = () => {
  return (
    <div className="col col-12 col-lg-4 deperdit">
      <p className="titrage">{_T('ESTIMATED_LOSS')}</p>
      <p>{_T('BASE_TEMPERATURE')} :<strong>{ProjectStore.value('deperds.TBase').round(0)}&nbsp;°C</strong></p>
      <p>{_T('LOSS')} :<strong>{numeral(ProjectStore.value('deperds.Deperd').round(0)).format()}&nbsp;W</strong></p>
      <p>{_T('REQUESTED_HEATING_CAPACITY')} :<strong>{numeral(ProjectStore.value('results.E1[0].PowerNeeded').round(0)).format()}&nbsp;W</strong></p>
    </div>
  );
}

export default  observer(EstimatedLoss);