export const typologies = [
  { code: '0', label: 'SELECT_TYPO_IND' },
  { code: '1', label: 'SELECT_TYPO_SPLIT' },
]

export const typologiesWithMonobloc = [
  { code: '0', label: 'SELECT_TYPO_IND' },
  { code: '1', label: 'SELECT_TYPO_SPLIT' },
  { code: '2', label: 'SELECT_TYPO_MONOBLOC' },
]

export const captages = [
  { code: '1', label: 'SELECT_CAPT_NAPPE' },
  { code: '2', label: 'SELECT_CAPT_VERT' },
  { code: '3', label: 'SELECT_CAPT_HORZ' },
]

export const utilisations = [
  { code: '1', label: 'SELECT_UTI_BATI2012' },
  { code: '2', label: 'SELECT_UTI_RENO' },
]

export const energies_rt2012 = [
  { code: 'gaz', label: 'SELECT_NRG_GAZ' },
  { code: 'propane', label: 'SELECT_NRG_PROPANE' },
]

export const energies = [
  { code: 'fioul', label: 'SELECT_NRG_FIOUL' },
  { code: 'gaz', label: 'SELECT_NRG_GAZ' },
  { code: 'propane', label: 'SELECT_NRG_PROPANE' },
]

export const alimentations = [
  { code: '1', label: 'SELECT_ALIM_MONO' },
  { code: '2', label: 'SELECT_ALIM_TRI' },
]
export const alimentations_ind = [
  { code: '0', label: 'SELECT_ALIM_IND' },
]

export const abonnementsMono = [{ code: '1', label: 'SELECT_ABO_PLEINT' }]

export const abonnementsMulti = [
  { code: '1', label: 'SELECT_ABO_PLEINT' },
  { code: '2', label: 'SELECT_ABO_1P' },
  { code: '3', label: 'SELECT_ABO_2P' },
]

export const releveChaudiere = [
  { code: 'chaudiere', label: 'YES' },
  { code: 'sans', label: 'NO' },
]

export const anneesChaudiere = [
  { code: 'plus de 10 ans', label: 'SUP_10_YEARS' },
  { code: 'moins de 10 ans', label: 'INF_10_YEARS' },
]

export const typesChaudiere = [
  { code: 'standard', label: 'SELECT_TYPEC_STD' },
  { code: 'basse temperature', label: 'SELECT_TYPEC_BASSET' },
  { code: 'condensation', label: 'SELECT_TYPEC_COND' },
]

export const energiesChaudiere = [
  { code: 'propane', label: 'SELECT_NRGC_PROPANE' },
  { code: 'gaz', label: 'SELECT_NRGC_GAZ' },
  { code: 'fioul', label: 'SELECT_NRGC_FIOUL' },
  { code: 'electrique', label: 'SELECT_NRGC_ELEC' },
]

export const typesECSDefault = [
  { code: 'sans', label: 'SELECT_ECS_SANS' },
  { code: 'deportee', label: 'SELECT_ECS_DEP' },
  { code: 'integree', label: 'SELECT_ECS_INT' },
]
export const typesECSInst = [{ code: 'instantanee', label: 'SELECT_ECS_INST' }]
export const typesECSIntegree = [{ code: 'integree', label: 'SELECT_ECS_INT' }]

export const nbOccupants = [
  { code: '1', label: '1' },
  { code: '2', label: '2' },
  { code: '3', label: '3' },
  { code: '4', label: '4' },
  { code: '5', label: '5' },
  { code: '6', label: '6' },
]
