import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from "react-router-dom";

import { _T } from '../../_i18n/i18n.helper';

import ProjectStore from '../../store/ProjectStore'
import {getBreadcrumbItems} from "../../configApp";
import UIStore from '../../store/UIStore';

// @observer
const BreadcrumbMobile = (props) => {
  let { handleSubmit, history, currentStep } = props; //NOSONAR

  const next = () => {
    if(handleSubmit) {
      handleSubmit().then(() => {
        ProjectStore.nextStep(history);
      });
    }
  };

  const previous = () => {
    ProjectStore.previousStep(history);
  };

  return (
    <div className="row bandeEtapes">
      <button className="btn etapePREV" onClick={previous}></button>
      <p>étape<strong>{currentStep.index+1}/{getBreadcrumbItems(UIStore.environment_parameters.disabledSteps).length} {_T(currentStep.label)} {/*NOSONAR*/}
      </strong></p>
      <button className="btn etapeNEXT" onClick={next}></button>
    </div>
  );
}

export default  withRouter(observer(BreadcrumbMobile));