import { makeObservable, observable, action } from 'mobx';
import * as Yup from 'yup';
import UIStore from '../../store/UIStore';

class SaveProjectStore {

  formValidationScheme = Yup.object().shape(
    {
      projectName: Yup.string().required(),
      projectRef: Yup.string().required(),
      technician: Yup.string().required(),
      client: Yup.object().shape({
        data: Yup.object().shape({
          companyName: Yup.string().required(),
          address: Yup.string().required(),
          cp: Yup.string().required(),
          city: Yup.string().required(),
          name: Yup.string().required(),
          phone: Yup.string().required(),
        }),
      }),
    }
  );

  defaultForm = {
    projectName: '',
    projectRef: '',
    technician: '',
    registerClient: false,
    synthesisTitle: false,
    client : {
      data: {
        companyName: '',
        address: '',
        cp: '',
        city: '',
        name: '',
        phone: '',
      }
    }
  };

  form = {...this.defaultForm};

  constructor() {
    makeObservable(this, {
      form: observable,
      initNewProject: action,
      setClient: action
    })
  }

  initNewProject() {
    this.form = {...this.defaultForm};
  }

  setClient = (client) => {
    this.form.client = {...client, ...{user_id: UIStore.user.id}};
  }

}

export default new SaveProjectStore();