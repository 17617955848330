import * as apiService from "../_services/api.service";

export async function get () {
  return await apiService.get('userinfos');
}

export async function update (userInfos) {
  return await apiService.patch(`userinfos`, userInfos);
}

export async function deleteLogo () {
  return await apiService.del(`userinfos/logo`);
}