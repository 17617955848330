import React from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';
import ProjectStore from '../../store/ProjectStore';

const InputData = () => {

  return (
    <div className="col col-12 col-lg-4 adresse">
      <p className="titrage">{_T('INPUT_DATA')}</p>
      <p>{_T('DEPARTMENT')} :<strong>{ProjectStore.value('forms.heatModeStep.form.departement')}</strong></p>
      <p>{_T('CITY')} :<strong>{ProjectStore.value('forms.heatModeStep.form.stationMeteo')}</strong></p>
      <p>{_T('ALTITUDE')} :<strong>{ProjectStore.value('forms.heatModeStep.form.altitude')}&nbsp;m</strong></p>
      <p>{_T('WINTER_ROOM_TEMP')} :<strong>{ProjectStore.value('forms.heatModeStep.form.deperditions.temperature')}&nbsp;°C</strong></p>
      { ProjectStore.project.simulationCool && <p>{_T('SUMMER_ROOM_TEMP')} :<strong>{ProjectStore.value('forms.coolModeStep.form.temperature')}&nbsp;°C</strong></p>}
    </div>
  );
}

export default  observer(InputData);