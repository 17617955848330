import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import ProjectStore from '../store/ProjectStore'
import * as luaService from './lua.service'
import { useTable, useSortBy } from 'react-table'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import numeral from 'numeral'

import InputData from './StepResults/InputData'
import EstimatedLoss from './StepResults/EstimatedLoss'
import ColdResults from './StepResults/ColdResults'

import chaudiere from '../assets/img/produits/chaudiere.jpg'
import NavigationButtons from '../_components/Navigation/NavigationButtons'
import UIStore from '../store/UIStore'
import MainMenu from '../_components/Navigation/MainMenu'
import Breadcrumb from '../_components/Navigation/Breadcrumb'
import { _T } from '../_i18n/i18n.helper'
import * as projectService from './project.service'
import * as projectTraceService from './projectTrace.service'

import { FaHome, FaWrench, FaRegChartBar } from 'react-icons/fa'

let bodyColsclassNames = [
  'modele',
  'code',
  'typo',
  'taux',
  'taux',
  'taux',
  'conso1',
  'cop',
  'couv',
  'eer',
  'conso',
  'prix',
  'details',
  'select',
]

const bodyColsUnits = [
  '',
  '',
  '',
  '',
  '',
  '',
  UIStore.environment_parameters.currency,
  '',
  '',
  '',
  '',
  UIStore.environment_parameters.currency,
  '',
  '',
]

const colorizeTxcouv = () => {
  return (
    UIStore.isEnvFr &&
    ProjectStore.project.forms.hpChoiceStep.form.heatPumpType !== '3' &&
    ProjectStore.project.forms.hpChoiceStep.form.releveChaudiere === 'sans'
  )
}

const colorizeTxPhybride = () => {
  return UIStore.isEnvFr && ProjectStore.project.forms.hpChoiceStep.form.heatPumpType === '3'
}

const isAeroAndTypoIndiff = () => {
  return ProjectStore.project.forms.hpChoiceStep.form.heatPumpType === '1' && ProjectStore.project.forms.hpChoiceStep.form.typologie === '0'
}

function Table({ columns, data, showProduct, selectProduct, selectedProduct, recommendedBy }) { // NOSONAR
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  )

  const getCellStyle = (row, j) => { //NOSONAR
    const style = {}
    if (!UIStore.isEnvFr) return {}
    switch (j) {
      case 3:
        if (colorizeTxcouv()) {
          if (row.original.Txcouv >= 60 && row.original.Txcouv < 70) style.backgroundColor = '#f8a14b'
          if (row.original.Txcouv >= 70 && row.original.Txcouv < 100) style.backgroundColor = '#85b98e'
          if (row.original.Txcouv >= 100 && row.original.Txcouv <= 140) style.backgroundColor = '#f8a14b'
        }
        return style
      case 4:
        if (colorizeTxPhybride()) {
          if (row.original.TxCouvThermo >= 70 && row.original.TxPhybride >= 40 && row.original.TxPhybride < 60)
            style.backgroundColor = '#339043'
          if (row.original.TxCouvThermo >= 70 && row.original.TxPhybride >= 60 && row.original.TxPhybride < 80)
            style.backgroundColor = '#85b98e'
          if (row.original.TxCouvThermo < 70 && row.original.TxPhybride >= 40 && row.original.TxPhybride < 60)
            style.backgroundColor = '#f8a14b'
        }
        return style
      default:
        return {}
    }
  }

  const getCell = (row, cell, j) => {
    let brand = UIStore.environment_parameters.brand
    if (brand === 'thermorfr')
      bodyColsclassNames = [
        'modele',
        'code',
        'typo',
        'taux',
        'taux',
        'taux',
        'conso1',
        'cop',
        'couv',
        'eer',
        'conso',
        'prix',
        'details_thermor',
        'select',
      ]

    switch (j) {
      case 12:
        return (
          <>
            <button className={brand !== 'thermorfr' ? 'btn_add' : 'btn_add btn_add_thermor'} onClick={(e) => showProduct(e, row.original)}>
              +
            </button>
            <img src={row.original.product.photo || chaudiere} alt="" />
          </>
        )
      case 13:
        const className = 'btn_select' + (selectedProduct && selectedProduct.product.id === row.original.product.id ? ' active' : '') // NOSONAR
        return (
          <button className={className} onClick={(e) => selectProduct(e, row.original)}>
            <i></i>
            <span>
              Sélection {/* NOSONAR */}
            </span>
          </button>
        )
      case 0:
        return <>{cell.render('Cell')}</>
      case 2:
        return <>{_T(`SELECT_TYPO_${row.original.Split_mono.toUpperCase()}`)}</>
      case 3:
        return <>{row.original.Txcouv === 0 ? _T('EXTRA_ONLY') : row.original.Txcouv}</>
      case 6:
        return <>{numeral(row.original.AllConso).format()}</>
      case 7:
        return <>{numeral(row.original.COPGlobalHeating).format('0,0.00')}</>
      case 11:
        return <>{UIStore.environment_parameters.hidePrice ? '-' : numeral(row.original.product.price).format()}</>
      default:
        return (
          <>
            {cell.render('Cell')}
            <span>{bodyColsUnits[j]}</span>
          </>
        )
    }
  }

  // Render the UI for your table
  return (
    <table 
      className="syntTAB" // NOSONAR
      {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) => {
              // Add the sorting props to control sorting. For this example
              // we can add them into the header props
              if (!ProjectStore.project.simulationCool && j >= 8 && j <= 10) return null
              else if (!colorizeTxPhybride() && j === 4) return null
              else if (!isAeroAndTypoIndiff() && j === 2) return null
              else
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} 
                    className={column.className} // NOSONAR
                  >
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span> {/*NOSONAR*/}
                  </th>
                )
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                if (!ProjectStore.project.simulationCool && j >= 8 && j <= 10) return null
                else if (!colorizeTxPhybride() && j === 4) return null
                else if (!isAeroAndTypoIndiff() && j === 2) return null
                else
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={bodyColsclassNames[j]} // NOSONAR
                      data-title={columns[j].Header} // NOSONAR
                      style={getCellStyle(row, j)} // NOSONAR
                    >
                      {
                        getCell(row, cell, j, i) // NOSONAR
                      }
                    </td>
                  )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function ProjectStepResults(props) {
  const { history } = props; // NOSONAR

  const [data, setData] = useState({
    isProductInfosOpen: false,
    columns: null,
    data: null,
    error: false,
    recommendedBy: null,
  });

  useEffect(() => { //NOSONAR
    async function fetchData() {
      // Calculate the schema code
      projectService.computeSchemaCode();

      if (!ProjectStore.project.results || !ProjectStore.project.results.E1 || ProjectStore.project.results.E1.length <= 0) { // NOSONAR
        try {
          ProjectStore.project.deperds = await luaService.getLoss();
          const response = await luaService.getResults();

          ProjectStore.project.results = response ? response.results : null;

          if (ProjectStore.project.results.E1 && ProjectStore.project.results.E1[0] // NOSONAR
            ) {
            selectProduct(null, ProjectStore.project.results.E1[0]);
            setData({
              ...data,
              recommendedBy: ProjectStore.project.results.E1[0].product.code,
            });
          } else {
            selectProduct(null, null);
          }
          //create project trace
          await projectTraceService.create();
        } catch (e) {
          setData({
            ...data,
            error: true,
          });
        }
      }

      const _columns = [
        {
          Header: _T('MODEL'),
          id: 'reference',
          accessor: 'product.reference',
        },
        {
          Header: _T('CODE'),
          id: 'code_env',
          accessor: 'product.code_env',
        },
        {
          Header: _T('TYPO'),
          id: 'Split_mono',
          accessor: 'Split_mono',
        },
        {
          Header: _T('COVERAGE_RATE_AT_TBASE'),
          id: 'Txcouv',
          accessor: 'Txcouv',
          className: 'colorSaumon',
        },
        {
          Header: _T('COVERAGE_RATE_AT_T_0_50'),
          id: 'TxPhybride',
          accessor: 'TxPhybride',
          className: 'colorSaumon',
        },
        {
          Header: _T('ANNUAL_THERMO_COV_RATE'),
          id: 'TxCouvThermo',
          accessor: 'TxCouvThermo',
          className: 'colorSaumon',
        },
        {
          Header: _T(ProjectStore.project.forms.hpChoiceStep.form.typeECS === 'sans' ? 'ESTIMATED_CONS_WITHOUT_ECS' : 'ESTIMATED_CONS', {
            currency: UIStore.environment_parameters.currency,
          }),
          id: 'AllConso',
          accessor: 'AllConso',
          className: 'colorSaumon',
        },
        {
          Header: _T('SYSTEM_GLOBAL_COP'),
          id: 'COPGlobalHeating',
          accessor: 'COPGlobalHeating',
          className: 'colorSaumon',
        },
        {
          Header: _T('ANNUAL_TX_COUV'),
          id: 'ColdTxCouv',
          accessor: 'ColdTxCouv',
          className: 'colorBleu',
        },
        {
          Header: _T('ANNUAL_ESTIMATED_CONS', { currency: UIStore.environment_parameters.currency }),
          id: 'AllColdConso',
          accessor: 'AllColdConso',
          className: 'colorBleu',
        },
        {
          Header: _T('GLOBAL_EER'),
          id: 'EERGlobal',
          accessor: 'EERGlobal',
          className: 'colorBleu',
        },
        {
          Header: _T('SUGGESTED_PRICE', { currency: UIStore.environment_parameters.currency }),
          id: 'price',
          accessor: 'product.price',
        },
        {
          Header: _T('PRODUCT_DETAILS'),
          id: 'details',
        },
        {
          Header: _T('SELECTED_PRODUCT'),
          id: 'selection',
        },
      ];

      const _data = ProjectStore.project.results ? ProjectStore.project.results.E1 : [];

      setData({
        ...data,
        columns: _columns,
        data: _data
      });
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showProduct = (e, item) => {
    UIStore.setLastShowProduct(item);
    history.push(`/${UIStore.env}/product`); // NOSONAR
  }

  const selectProduct = (e, item) => {
    if (e) e.stopPropagation();
    projectService.selectProduct(item);
  }

  const submit = () => {
    if (ProjectStore.project.selectedProduct) {
      ProjectStore.saveStore();
      return Promise.resolve();
    } else {
      toast.error(_T('NO_SELECTED_PRODUCT_ERROR'))
      return Promise.reject(false); // NOSONAR
    }
  }

  return (
    <>
      <div 
        className="header container-fluid" // NOSONAR
      >
        <MainMenu />
        <Breadcrumb handleSubmit={submit} />
      </div>

      <div className="main container-fluid" // NOSONAR
      >
        <div className="row syntheseDatas" // NOSONAR
        >
          <div className="row syntheseINFOS" // NOSONAR
          >
            <InputData />
            <EstimatedLoss />
            {ProjectStore.project.simulationCool && <ColdResults />}
          </div>

          {data?.columns && data.data && data.data.length > 0 && (
            <>
              <div className="row tabTAB syntheseTAB" // NOSONAR
              >
                <Table
                  columns={data.columns}
                  data={data.data}
                  showProduct={showProduct}
                  selectProduct={selectProduct}
                  selectedProduct={ProjectStore.project.selectedProduct}
                  recommendedBy={data.recommendedBy}
                />
              </div>

              {colorizeTxcouv() && (
                <div 
                  style={{ margin: '0px 20px', display: 'flex', flexDirection: 'column' }} // NOSONAR
                >
                  <div 
                    style={{ display: 'flex' }} // NOSONAR
                  >
                    <div 
                      style={{ width: 20, height: 20, marginRight: 5, backgroundColor: '#85b98e' }} // NOSONAR
                    ></div>
                    <div>{_T('RECOMMENDED_BY_ATLANTIC')}</div>
                  </div>
                  <div style={{ display: 'flex' }} // NOSONAR
                  >
                    <div 
                      style={{ width: 20, height: 20, marginRight: 5, backgroundColor: '#f8a14b' }} // NOSONAR
                    ></div>
                    <div>{_T('UNOPTIMIZED_SOLUTION')}</div>
                  </div>
                  <div 
                    style={{ marginTop: '10px', fontSize: '0.825em' }} // NOSONAR
                    dangerouslySetInnerHTML={{ __html: _T('LEGEND_NOTE') }} // NOSONAR
                  ></div>
                  <div 
                    style={{ marginTop: '10px', fontSize: '0.825em' }} // NOSONAR
                    dangerouslySetInnerHTML={{ __html: _T('COMMENT_AERO') }} // NOSONAR
                  ></div>
                </div>
              )}

              {colorizeTxPhybride() && (
                <div style={{ margin: '0px 20px', display: 'flex', flexDirection: 'column' }} // NOSONAR
                >
                  <div style={{ display: 'flex' }} // NOSONAR
                  >
                    <div style={{ width: 20, height: 20, marginRight: 5, backgroundColor: '#339043' }} // NOSONAR
                    ></div>
                    <div>{_T('OTHER_SOLUTION_HYBRID')}</div>
                  </div>
                  <div style={{ display: 'flex' }} // NOSONAR
                  >
                    <div style={{ width: 20, height: 20, marginRight: 5, backgroundColor: '#85b98e' }} // NOSONAR
                    ></div>
                    <div>{_T('RECOMMENDED_BY_ATLANTIC_HYBRID')}</div>
                  </div>
                  <div style={{ display: 'flex' }} // NOSONAR
                  >
                    <div 
                      style={{ width: 20, height: 20, marginRight: 5, backgroundColor: '#f8a14b' }} // NOSONAR
                    ></div>
                    <div>{_T('UNOPTIMIZED_SOLUTION_HYBRID')}</div>
                  </div>
                </div>
              )}
            </>
          )}

          {data?.columns && data.data && data.data.length === 0 && (
            <span
              className="noProduct" // NOSONAR
              dangerouslySetInnerHTML={{ // NOSONAR
                __html: _T('NO_PRODUCT').replace(/([a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})/gi, '<a href="mailto:$1">$1</a>'),
              }}
            ></span>
          )}

          {data && !data.data && !data.error && ( // NOSONAR
            <div 
              className="no-freeze-spinner" // NOSONAR
            >
              <div 
                id="no-freeze-spinner" // NOSONAR
              >
                <div>
                  <i 
                    className="material-icons" // NOSONAR
                  >
                    <FaHome />
                  </i>
                  <i className="material-icons" // NOSONAR
                  >
                    <FaWrench />
                  </i>
                  <i className="material-icons" // NOSONAR
                  >
                    <FaRegChartBar />
                  </i>
                  <div></div>
                </div>
              </div>
            </div>
          )}
        </div>

        <NavigationButtons handleSubmit={submit} />
      </div>
    </>
  )
}

export default withRouter(observer(ProjectStepResults));
