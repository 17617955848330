/* eslint-disable react/prop-types */

import React, {useState, useEffect} from 'react'
import { observer } from 'mobx-react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { toJS } from 'mobx'
import { _T } from '../_i18n/i18n.helper'

import formStore from './form.store'
import Field from '../_components/forms/Field'
import manageForm from '../_components/forms/manageForm'
import * as userService from '../MyAccount/user.service'
import UIStore from '../store/UIStore';
import { toast } from 'react-toastify'

const EnergyModal = (props) => {
  /* sonarlint-disable react/prop-types */
  const { errors, touched, onBlur, modalIsOpen, toggle, validate } = props; // NOSONAR

  const [data, setData] = useState({
    loading: true,
    userInfos: null,
    saveDisabled: true,
    resetDisabled: true
  }); 

  const refresh = async () => {
    const userInfos = (await userService.get()) || {}
    const energy = userInfos.energy && userInfos.energy.fuelOil ? userInfos.energy :  UIStore.environment_energy
    formStore.form = { ...formStore.form, ...energy };

    setData({
      loading: false,
      userInfos: userInfos,
      saveDisabled: true,
      resetDisabled: userInfos.energy == null
    });
  }

  useEffect(() => {refresh()}, []);

  const save = async () => {
    return validate()
      .then(() => {
        userService
          .update({data: {...data.userInfos, energy: toJS(formStore.form) }})
          .then(() => {
            toast.success(_T('ENERGY_SAVED'))
            refresh()
            toggle()
          })
          .catch(() => toast.error(_T('ERROR_OCCURED')))
      })
      .catch((err) => {
        toast.error(_T('ERROR_OCCURED'))
      })
  }

  const reset = async () => {
    userService
      .update({data: {...data.userInfos, energy: null }})
      .then(() => {
        toast.success(_T('ENERGY_RESET'))
        refresh()
      })
      .catch(() => toast.error(_T('ERROR_OCCURED')))
  }

  const cancel = () => {
    refresh()
    toggle()
  }

  const onChange = (e) => {
    setData({
      ...data,
      saveDisabled:false
    })
    props.onChange(e) //NOSONAR
  }

    return (
      <Modal isOpen={modalIsOpen} toggle={toggle} size="lg" className="modalSaveProject">
        <ModalBody>
          <button className="btn_close" onClick={toggle}>
            X
          </button>
          <div className="energyTab">
            <div className="row header" style={{marginTop: 20, height: 46}}>
              <div className="col-12 col-lg-6">
                {_T('ENERGY')}
              </div>
              <div className="col-12 col-lg-6">
                {_T('PRICE')} { UIStore.environment_parameters.currency }
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                {_T('FUEL_OIL')}
              </div>
              <div className="col-12 col-lg-6">
                <Field
                    className="form-control"
                    type="number"
                    name="fuelOil"
                    placeholder={_T('FUEL_OIL')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={formStore.form.fuelOil}
                    errors={errors}
                    touched={touched}
                    min={0}
                    step={0.1}
                  />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                {_T('PROPANE')}
              </div>
              <div className="col-12 col-lg-6">
                <Field
                    className="form-control"
                    type="number"
                    name="propane"
                    placeholder={_T('PROPANE')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={formStore.form.propane}
                    errors={errors}
                    touched={touched}
                    min={0}
                    step={0.1}
                  />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                {_T('ELECTRICITY_FULL_PRICE')}
              </div>
              <div className="col-12 col-lg-6">
                <Field
                    className="form-control"
                    type="number"
                    name="electricityFullPrice"
                    placeholder={_T('ELECTRICITY_FULL_PRICE')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={formStore.form.electricityFullPrice}
                    errors={errors}
                    touched={touched}
                    min={0}
                    step={0.1}
                  />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                {_T('ELECTRICITY_FULL_HOUR')}
              </div>
              <div className="col-12 col-lg-6">
                <Field
                    className="form-control"
                    type="number"
                    name="electricityFullHour"
                    placeholder={_T('ELECTRICITY_FULL_HOUR')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={formStore.form.electricityFullHour}
                    errors={errors}
                    touched={touched}
                    min={0}
                    step={0.1}
                  />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                {_T('ELECTRICITY_FULL_OFF_PEACK')}
              </div>
              <div className="col-12 col-lg-6">
                <Field
                    className="form-control"
                    type="number"
                    name="electricityFullOffPeack"
                    placeholder={_T('ELECTRICITY_FULL_OFF_PEACK')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={formStore.form.electricityFullOffPeack}
                    errors={errors}
                    touched={touched}
                    min={0}
                    step={0.1}
                  />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                {_T('GAS')}
              </div>
              <div className="col-12 col-lg-6">
                <Field
                    className="form-control"
                    type="number"
                    name="gas"
                    placeholder={_T('GAS')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={formStore.form.gas}
                    errors={errors}
                    touched={touched}
                    min={0}
                    step={0.1}  
                  />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {!data.saveDisabled && <button className="btn enregistrer" onClick={save}>
            {_T('NAV_SAVE')}
          </button>}
          {!data.saveDisabled &&<button className="btn cancel" onClick={cancel}>
            {_T('CANCEL')}
          </button>}
          {!data.resetDisabled &&<button className="btn enregistrer" onClick={reset}>
            {_T('RESET')}
          </button>}
          {data.resetDisabled && data.saveDisabled &&<button className="btn enregistrer" onClick={cancel}>
            {_T('FERMER')}
          </button>}
        </ModalFooter>
      </Modal>
    )
  }
/* sonarlint-enable react/prop-types */
export default manageForm(observer(EnergyModal), formStore, 'form')
