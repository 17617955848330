import ax from 'axios';
import { API_URL } from '../config';
import { toast } from 'react-toastify';
import {_T} from "../_i18n/i18n.helper";
import * as userService from "../User/auth.service";
import UIStore from "../store/UIStore";

/**
 * Send a call to API
 * @param method
 * @param endpoint
 * @param data
 * @param disableRoast
 * @returns {Promise<*>}
 */
export async function request (method, endpoint, data, options, disableToast) {
  try {

    let args = [`${API_URL}/${endpoint}`];
    if(data) args.push(data);
    if(options) args.push(options);

    const func = ax[method];

    if(!UIStore.disableLoadingBar) UIStore.loadingContinuousStart();
    const response = await func(...args);
    if(!UIStore.disableLoadingBar) UIStore.loadingComplete();
    if(UIStore.disableLoadingBar) UIStore.hideLoadingBar(false);

    if(options && options.responseType === 'blob') return response;
    else return response.data;

  } catch (error) {

    UIStore.loadingComplete();

    switch (error.response.status) {
      case 403:
        break;
      case 404:
        console.log('ERROR_NOT_FOUND');
        break;
      case 401:
      case 498:
        userService.logout();
        break;
      default:
        if(!disableToast) toast.error(_T('ERROR_OCCURED'));
        break;
    }

    console.error(error);
    return Promise.reject(error);

  }

}

export async function get (endpoint, options, disableToast) {
  return await request('get', endpoint, options, disableToast);
}
export async function post (endpoint, data, options, disableToast) {
  return await request('post', endpoint, data, options, disableToast);
}
export async function put (endpoint, data, options, disableToast) {
  return await request('put', endpoint, data, options, disableToast);
}
export async function patch (endpoint, data, options, disableToast) {
  return await request('patch', endpoint, data, options, disableToast);
}
export async function del (endpoint, options, disableToast) {
  return await request('delete', endpoint, options, disableToast);
}
