import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';
import ProjectStore from "../../store/ProjectStore";
import { DARKEN_CHART_COLOR, CHART_LINECHART_OPTIONS } from '../../configApp';
import UIStore from "../../store/UIStore";
import numeral from 'numeral';


function ProductModalContent4(props) {
  const { product } = props; // NOSONAR

  const [, setWindowSize] = useState(null); // NOSONAR

  /*
    MaxPower: 4.08555555555556
    MaxPowerAndAppoint: 7.08555555555556
    NbOfHours: 0
    Needs: 5.025
    Temp: -8
   */

  let ApointFound = false;

  const barChartOptions = {...CHART_LINECHART_OPTIONS, ...{
      title: _T('BARCHART_OPERATION'),
      series: {
        0: {targetAxisIndex: 1, type: 'area'},
        1: {targetAxisIndex: 0},
        2: {targetAxisIndex: 0},
        3: {targetAxisIndex: 0},
      },
      hAxis: {
        title: _T('EXT_TEMP'),
        titleTextStyle: { italic: false },
      },
      vAxes: {
        0: {
          title: 'Deperditions (kW)',
          titleTextStyle: { color: DARKEN_CHART_COLOR[1], italic: false },
          textStyle: { color: DARKEN_CHART_COLOR[1] },
        },
        1: {
          title: 'Heures/an',
          titleTextStyle: { color: DARKEN_CHART_COLOR[0],italic: false },
          textStyle: { color: DARKEN_CHART_COLOR[0] },
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Fix a bug on smartphone to adapt first chart to screen size
    setTimeout(() => {
      setWindowSize(window.innerWidth);
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = useCallback(() => {
    delete barChartOptions.animation;
    setWindowSize(window.innerWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [handleResize]);

  const getAnnotation = (row, serie) => {
    if(parseInt(ProjectStore.project.deperds.TBase) === row.Temp) return serie === 'Power'  ? 'A' : 'B';
    else if(!ApointFound && row.Power && row.Needs && row.Power.round(1) === row.Needs.round(1)) {
      ApointFound = true;
      return 'C';
    }
    return null;
  };
  
  const drawChartFonctionnement = (conso) => { // NOSONAR
    let chartData;
    if(ProjectStore.project.simulationCool) {
      barChartOptions.series[4] = {targetAxisIndex: 0};
      barChartOptions.series[5] = {targetAxisIndex: 0};

      chartData = [
        [_T('TEMPERATURE'), _T('HOURS'), _T('POWER'), {role:'annotation'}, _T('NEEDS'), {role:'annotation'}, _T('COOL_NEEDS'), _T('COOL_POWER')],
        //['-7', 100],
      ];
    } else { // NOSONAR
      chartData = [
        [_T('TEMPERATURE'), _T('HOURS'), _T('POWER'), {role:'annotation'}, _T('NEEDS'), {role:'annotation'}],
        //['-7', 100],
      ];
    }

    ApointFound = false;

    let rowdata = null;
    conso.forEach((row, index) => {
      const nextIndex = Math.min(index+1, conso.length-1);
      const prevIndex = Math.max(index-1, 0);

      row.Power = row.Power || (conso[nextIndex].Power > 0 || conso[prevIndex].Power > 0) ? row.Power : null; // NOSONAR
      row.Needs = row.Needs || (conso[nextIndex].Needs > 0 || conso[prevIndex].Needs > 0) ? row.Needs : null; // NOSONAR
      row.FRNeed = row.FRNeed || (conso[nextIndex].FRNeed > 0 || conso[prevIndex].FRNeed > 0) ? row.FRNeed : null; // NOSONAR
      row.ColdMaxPower = row.ColdMaxPower || (conso[nextIndex].ColdMaxPower > 0 || conso[prevIndex].ColdMaxPower > 0) ? row.ColdMaxPower : null;// NOSONAR
      if(row.Power) row.Power = row.Power.round(2); // NOSONAR
      if(row.Needs) row.Needs = row.Needs.round(2); // NOSONAR
      if(row.FRNeed) row.FRNeed = row.FRNeed.round(2); // NOSONAR
      if(row.ColdMaxPower) row.ColdMaxPower = row.ColdMaxPower.round(2); // NOSONAR

      if((!ProjectStore.project.simulationCool && row.Temp <= (parseInt(ProjectStore.project.forms.heatModeStep.form.deperditions.temperature))) // NOSONAR
        || ProjectStore.project.simulationCool) {
        let annotationPower = getAnnotation(row, 'Power');
        let annotationNeeds = getAnnotation(row, 'Needs');
        let annotation = null;

        console.log(annotationPower);

        if(annotationPower && annotationNeeds && row.Power && row.Needs && row.Power.round(1) === row.Needs.round(1)) { // NOSONAR
          annotation = annotationPower + ' / ' + annotationNeeds;
        }

        rowdata = [row.Temp, row.NbOfHours, row.Power, annotation || annotationPower, row.Needs, annotation || annotationNeeds]; // NOSONAR
        if(ProjectStore.project.simulationCool) { // NOSONAR
          rowdata.push(row.FRNeed);
          rowdata.push(row.ColdMaxPower);
        }

        chartData.push(rowdata);
      }
    });

    const data = new window.google.visualization.arrayToDataTable(chartData);
    const chart = new window.google.visualization.LineChart(document.getElementById('chartFonc'));
    chart.draw(data, barChartOptions);
  };
  /**
  * window.google.charts.load('current', {packages: ['corechart'], language: UIStore.lang})
  * window.google.charts.setOnLoadCallback(() => {(product.E2) })
  */

  useEffect(() => {
    drawChartFonctionnement(product.E2); // NOSONAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row chartRow">
        <div className="col col-12">
          <div className="chart" id="chartFonc" />
        </div>
      </div>

      <div className="row chartRow">
        <div className="col col-12">

          <div className="infosPlus">
            <div className="row">
              <div className="col col-12 col-md-9">{_T('TBASE_HEATING_POWER')} <span>(A)</span></div>
              <div className="col col-12 col-md-3">{numeral(product.Pcalotb * 1000).format('0,0')}</div> {/*NOSONAR*/}
            </div>
            <div className="row">
              <div className="col col-12 col-md-9">{_T('ESTIMATED_LOSS')} (W) <span>(B)</span></div>
              <div className="col col-12 col-md-3">{numeral(ProjectStore.project.deperds.Deperd).format('0,0')}</div> {/*NOSONAR*/}
            </div>
            <div className="row">
              <div className="col col-12 col-md-9">{_T('BASE_TEMPERATURE')}</div>
              <div className="col col-12 col-md-3">{numeral(ProjectStore.project.deperds.TBase).format('0,0')}</div> {/*NOSONAR*/}
            </div>
            <div className="row">
              <div className="col col-12 col-md-9">{_T('BIVALENCE_POINT')} <span>(C)</span></div>
                <div className="col col-12 col-md-3">{product.Tbiv}</div> {/*NOSONAR*/}
            </div>
            <div className="row">
                <div className="col col-12 col-md-9">{_T('EXTRA_ELEC')}</div>
                <div className="col col-12 col-md-3">{numeral(product.AppointAvailable).format()}</div> {/*NOSONAR*/}
            </div>
            <div className="row">
                <div className="col col-12 col-md-9">{_T('NEEDED_EXTRA_ELEC')}</div>
                <div className="col col-12 col-md-3">{numeral(product.AppointNeeded).format()}</div> {/*NOSONAR*/}
            </div>
            <div className="row">
                <div className="col col-12 col-md-9">{_T('ANNUAL_THERMO_COV_RATE')}</div>
                <div className="col col-12 col-md-3">{product.TxCouvThermo}</div> {/*NOSONAR*/}
            </div>
            <div className="row">
              <div className="col col-12 col-md-9">{_T(ProjectStore.project.forms.hpChoiceStep.form.typeECS === 'sans' ? 'ESTM_TOTAL_CONS_WITHOUT_ECS' : 'ESTM_TOTAL_CONS', {currency: UIStore.environment_parameters.currency})}</div> {/*NOSONAR*/}
              <div className="col col-12 col-md-3">{numeral(product.AllConso).format()}</div> {/*NOSONAR*/}
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default  observer(ProductModalContent4);