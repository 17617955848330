import {makeObservable, observable } from 'mobx';
import * as Yup from 'yup';

class EnergyStore {

  formValidationScheme = Yup.object().shape(
    {
      fuelOil: Yup.number(),
      propane: Yup.number(),
      electricityFullPrice: Yup.number(),
      electricityFullHour: Yup.number(),
      electricityFullOffPeack: Yup.number(),
      gas: Yup.number(),
    }
  );

  defaultForm = {
    fuelOil: '',
    propane: '',
    electricityFullPrice: '',
    electricityFullHour: '',
    electricityFullOffPeack: '',
    gas: '',
  };

  form = {...this.defaultForm};
  
  constructor() {
    makeObservable(this, {
      form: observable
    })
  }
}

export default new EnergyStore();