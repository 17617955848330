import ProjectStore from '../store/ProjectStore'
import {toJS} from 'mobx'
import UIStore from '../store/UIStore'
import * as clientService from "../Client/client.service";
import * as apiService from '../_services/api.service';
import formStore from './Save/form.store'
import * as _ from 'lodash';

async function prepare() { //NOSONAR
  let data = formStore.form;
  try {
    if(data.registerClient) {
      await clientService.insertOrUpdate(data.client);
    }

    let project = {
      project_id: ProjectStore.project.id,
      user_id: UIStore.user.id,
      environnement_id: UIStore.environnement_id,
      data: { ..._.cloneDeep(toJS(ProjectStore.project)), ...{ header: data }},
      version_index: true,
    };

    // Cleanup trace
    delete project.data.forms.heatModeStep.form.departmentsList;
    delete project.data.forms.heatModeStep.form.stationsList;

    if(project.data.results?.E1?.length > 0) {
      project.data.results.E1.forEach(p => {
        if(p.E2) delete p.E2;
        if(p.E3) delete p.E3;
        if(p.E4) delete p.E4;
        if(p.E5) delete p.E5;
      })
    }

    if(project.data.selectedProduct) {
      if(project.data.selectedProduct.E2) delete project.data.selectedProduct.E2;
      if(project.data.selectedProduct.E3) delete project.data.selectedProduct.E3;
    }

    if(project.data.accessories) {
      if(project.data.accessories.accessoriesGroups) delete project.data.accessories.accessoriesGroups;
      if(project.data.accessories.otherAccessories && project.data.accessories.otherAccessories.length > 0) {
        project.data.accessories.otherAccessories = project.data.accessories.otherAccessories.filter(a => a._selected);
      }
      if(project.data.accessories.recommendedAccessories && project.data.accessories.recommendedAccessories.length > 0) {
        project.data.accessories.recommendedAccessories = project.data.accessories.recommendedAccessories.filter(a => a._selected);
      }
    }

    return (project);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

export async function create() {
  let project = await prepare();
  let data = await apiService.post(`projectTrace/${UIStore.environnement_id}`, project);
  ProjectStore.traceId = data.id;
  localStorage.setItem('traceId', data.id);
}

export async function update() {
  let project = await prepare();
  await apiService.patch(`projectTrace/${ProjectStore.traceId}`, project);
}