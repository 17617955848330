import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";

import { _T } from '../../_i18n/i18n.helper';

import ProjectStore from '../../store/ProjectStore'
import SaveProjectModal from "../../Project/Save/SaveProjectModal";
import UIStore from '../../store/UIStore';


const NavigationButtons = (props) => {
  const { handleSubmit, toogleSaveModal, history, hideNext, hidePrev, hideSave, handleSave, saveText, overrideNext, handleSaveModalResponse} = props; //NOSONAR

  const [data, setData] = useState({
    modalIsOpen: false,
    showGenerateButton: false
  });

  let brand = UIStore.environment_parameters.brand;

  useEffect(() => {
    if (toogleSaveModal) {
      setData({
        modalIsOpen:true,
        showGenerateButton:true
      })
    }
  }, [toogleSaveModal])

  const next = () => {
    if(handleSubmit) {
      handleSubmit().then(() => {
        ProjectStore.nextStep(history);
      });
    }
  };

  const previous = () => {
    ProjectStore.previousStep(history);
  };

  const save = () => {
    setData({
      modalIsOpen: true,
      showGenerateButton: false
    });
  };

  const toggle = () => {
    setData({
      modalIsOpen: !data.modalIsOpen,
      showGenerateButton: false
    });
  };
  
  return (
    <>
      <div className="footer container-fluid">
        <div className="row">
          <div className="col bottomBarre">
            <button className="btn navPrev" onClick={previous}>{ !hidePrev && <><i></i>{ _T('NAV_PREVIOUS') }</> }</button>
            { !hideSave && <button className={`btn enregistrer ${brand}`} onClick={handleSave != null ? handleSave : save}>{saveText || _T('NAV_SAVE') }</button> }
            <button className={`btn navNext ${brand}`} onClick={overrideNext || next}>{ !hideNext && <>{ _T('NAV_NEXT') }<i></i></> }</button>
          </div>
        </div>
      </div>

      <SaveProjectModal 
        modalIsOpen={data.modalIsOpen} 
        showGenerateButton={data.showGenerateButton} 
        handleSaveModalResponse={handleSaveModalResponse} 
        toggle={toggle} 
      />
    </>
  );
}

export default  withRouter(NavigationButtons);