import React, { useEffect, useState } from 'react';

import styles from './helpSideModal.module.scss'

/**
 * @param {Element} targetId element who displays popover when clicked
 * @param {Elements} children
 */
function HelpsideModal(props) {
  const [data, setData] = useState({
    isOpen: false,
    neverOpen: true
  }) 

  useEffect(() => {
    let target = window.document.getElementById(props.targetId); //NOSONAR
    target.onclick = onTargetClick;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const onTargetClick = () => {
    setData({
      isOpen: !data.isOpen,
      neverOpen: false
    });
  };

  return (
    <div className={`${styles.HelpSideModal} && ${data.isOpen && styles.HelpSideModalOpen} && ${data.neverOpen && styles.HelpSideModalNeverOpen}`}>
      <div onClick={onTargetClick} className={styles.wrapper} //NOSONAR
      >
      </div>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <span className={styles.closeButton} onClick={onTargetClick} //NOSONAR
          >X</span>
        </div>
        <div className={styles.contentBody}>
          {
            props.children //NOSONAR
          }
        </div>
      </div>
    </div>
  )
}

export default HelpsideModal
