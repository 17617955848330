import React from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';

import produit_vide from "../../assets/img/produits/produit-vide.jpg";
import UIStore from '../../store/UIStore';

import HelpPophover from "../../_components/aide/HelpPophover";

const Acc = (props) => {
  const { acc, select } = props; // NOSONAR

  const helpClick = (e) => {
    e.stopPropagation()
  }

  let className = "col col-12 col-xd-6 col-sm-4 col-lg-2 blocProduit";
  if(acc._selected) className += " active"; // NOSONAR

  const brand = UIStore.environment_parameters.brand;

  return (
    <div className={className} onClick={select}> {/*NOSONAR*/}
      <button className="btn_select"><em>{_T('SELECT')}</em><i></i></button>
      <div className="imageProduit"><img src={acc._accessory.photo || produit_vide} alt=''/></div> {/*NOSONAR*/}
      <div className={brand === "pacific" ? "texteProduit pacific" : "texteProduit"}>
        <p className={brand === "pacific" && "pacific"}>{acc.code_env} {/*NOSONAR*/}
          <br/>
          {
            acc.reference // NOSONAR
          }
        </p>
        <button className={brand === "thermorfr" ? "help acc thermor" : "help acc"} id={`help${acc.id}`} onClick={helpClick}></button> {/*NOSONAR*/}
        <HelpPophover targetId={`help${acc.id}`}> {/*NOSONAR*/}
          <div>
            {!UIStore.environment_parameters.hidePrice && <div>{_T('ACC_PRICE')}: {acc.price}{UIStore.environment_parameters.currency}</div>} {/*NOSONAR*/}
            <div>{_T('ACC_DESCRIPTION')}:</div>
            <pre dangerouslySetInnerHTML={{__html: acc.description}}></pre> {/*NOSONAR*/}
          </div>
        </HelpPophover>
      </div>
    </div>
  );
}

export default  observer(Acc);