import React from 'react';
import { observer } from 'mobx-react';
import * as tools from '../../_helpers/tools'
import { _T } from '../../_i18n/i18n.helper';

const ProductModalContent1 = (props) => {
  const { product } = props; // NOSONAR

  return (
    <>
      <p className="titre_produit">{_T('MORE')}</p>
      <div className='contenu' dangerouslySetInnerHTML={{__html: product.product.description ? tools.nl2br(product.product.description) : ''}} /> {/*NOSONAR*/}
    </>
  );
}

export default  observer(ProductModalContent1);