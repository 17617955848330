import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { _T, _THtml } from '../_i18n/i18n.helper';
import UIStore from '../store/UIStore';

import logo_projipac from '../assets/img/logo-projipac.svg';

/**
 * Home Page
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
const MaintenancePage = () => {
const [count, setCount] = useState(0);

  const handleClick = () => {
    setCount(count + 1);
    if (count >= 6) {
      UIStore.setDisableMaintenanceMode();
    }
  }

  return (
    <div className="pageHome">
      <div className="header">
        <img src={UIStore.logo} height="40" alt="Logo" />
      </div>

      <div className="main container-fluid">
        <div className="row blocHome">
          <div className="blocProjipac">
            <p>Proji-Pac</p>
            <img src={logo_projipac} width="100" height="100" alt="Logo Projipac" />
          </div>
          <div className="blocHomeTitre" onClick={() => handleClick()}> {/* NOSONAR */}
            <a className="btn zoom" style={{ cursor: 'default' }}>{_T('MAINTENANCE_MODE_TITLE')}</a>
          </div>

          <p className="BlocHomeTexte">{_THtml('MAINTENANCE_MODE_TEXT')}</p>
        </div>
      </div>
    </div>
  )
}

export default observer(MaintenancePage)