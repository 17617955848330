import React from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';

const ProductNavigationButtons = (props) => {
  const { hidePrev, hideNext, previous, next, select} = props; //NOSONAR
  return (
    <div className="footer container-fluid">
      <div className="row">
        <div className="col bottomBarre">
          <button className="btn navPrev" onClick={previous}>{ !hidePrev && <><i></i>{ _T('NAV_PREVIOUS') }</>}</button>
          <button className="btn enregistrer" onClick={select}>{ _T('NAV_SELECT') }</button>
          <button className="btn navNext" onClick={next}>{ !hideNext && <>{ _T('NAV_NEXT') }<i></i></> }</button>
        </div>
      </div>
    </div>
  );
}

export default observer(ProductNavigationButtons);