import React from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';
import Field from '../../_components/forms/Field';
import HeatModeCalculatedNeeds from "./HeatModeCalculatedNeeds";
import * as dd from "./dropDown.data";

/**
 * Manage the zone part of HeatMode Form
 */
const HeatModeDeperditionsDPE = (props) => {
  const { errors, touched, onBlur, deperditions } = props; //NOSONAR

  //const [temperatures, setTemperatures] = useState(dd.temperature)
  const temperatures = dd.temperature;


  const onChange = (e) => {
    props.onChange(e); //NOSONAR
    props.calculateDeperd(); //NOSONAR
  };

    return (
      <div className="row estimation">
        <div className="col col-12 col-lg-4">
          <div className="form">

            <Field id="data_murs"
                   className='custom-select'
                   type="select"
                   name='deperditions.temperature'
                   placeholder={_T('DESIRED_TEMP')}
                   onChange={onChange}
                   onBlur={onBlur}
                   value={deperditions.temperature} //NOSONAR
                   errors={errors}
                   touched={touched}
                   defaultValue={true}
                   unit="°C"
                   translate={false}
                   items={temperatures} />

            <Field className='form-control'
                   type="number"
                   name={'deperditions.dpe'}
                   min={1}
                   placeholder={_T('DPE')}
                   onChange={onChange}
                   onBlur={onBlur}
                   value={deperditions.dpe} //NOSONAR
                   errors={errors}
                   touched={touched}
                   unit={'kWh(ef)'} />

          </div>
        </div>

        <HeatModeCalculatedNeeds />
      </div>
    );
  }


export default observer(HeatModeDeperditionsDPE);