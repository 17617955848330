import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from "react-router-dom";
import LanguageSwitch from "../../_i18n/languageSwitch";
import { _T } from '../../_i18n/i18n.helper';
import UIStore from "../../store/UIStore";
import MobileMenu from "./MobileMenu";
import { VERSION } from '../../config'
import thermor_logo from '../../assets/img/thermor_logo.png';
import picto_sav from '../../assets/img/picto-sav-2.svg';
import picto_contact from '../../assets/img/picto-contact-2.svg';
import picto_forum from '../../assets/img/picto-forum-2.svg';
import picto_questions from '../../assets/img/picto-questions-2.svg';

function MainMenuDefault(props) {
  const { brand } = props; //NOSONAR

  return (
    <ul >
      <li className="menuAide">
        <Link to={`/${UIStore.env}/help`}>
          {
            brand !== 'pacific' && <i></i> //NOSONAR
          }
          {_T('MMENU_HELP')}
        </Link>
      </li>
      <li className="menuCompte">
        <Link to={`/${UIStore.env}/my_account`}>
          {
            brand !== 'pacific' && <i></i> //NOSONAR
          }
          {_T('MMENU_MY_ACCOUNT')}
        </Link>
      </li>
      <li className="menuSimulations">
        <Link to={`/${UIStore.env}/my_projects`}>
          { 
            brand !== 'pacific' && <i></i> //NOSONAR
          }
          {_T('MMENU_MY_SIMULATION')}
        </Link>
      </li>
      <li className="menuDeconnexion">
        <Link to={`/logout`}>
          {
            brand !== 'pacific' && <i></i> //NOSONAR
          }
          {_T('MMENU_LOGOUT')}
        </Link>
      </li>
    </ul>
  );
}

function MainMenuThermor(props) {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showThermorSubMenu, setShowThermorSubMenu] = useState(false);

  const userName = UIStore.user ? UIStore.user.username : null;
  let welcome = userName != null && userName !== "" ? "Bonjour, " + userName : "Bonjour";

  return (
    <div>
      <ul style={{ justifyContent: 'right' }}>
        <li className="menuSimulationsThermor"><Link to={`/${UIStore.env}/my_projects`}><i></i>{"Historique des simulations"}</Link></li>
        <li className="menuCompteThermor" onClick={() => { //NOSONAR
          setShowUserMenu(!showUserMenu);
          setShowThermorSubMenu(false);
        }}>
          <Link><i></i>{welcome}</Link>
        </li>

        <li className="menuSubThermor" onClick={() => { //NOSONAR
          setShowThermorSubMenu(!showThermorSubMenu);
          setShowUserMenu(false);
        }}>
          <Link><i></i></Link>
        </li>
      </ul>

      {showUserMenu &&
        <div style={{
          position: "absolute", top: 48, right: 25, width: 282, boxShadow: "0px 1px 1px 1px #DDDDDD", backgroundColor: 'white', zIndex: 20,
          display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <div className="menuUserThermorCell">
            <a href={'https://www.thermor-pro.fr/account/info'} target="_blank" rel="noopener noreferrer"><p>Mon profil</p></a>
          </div>

          <div className="menuUserThermorCell">
            <Link to={`/${UIStore.env}/my_account`}><p>Options simul'Home</p></Link>
          </div>

          <div className="menuUserThermorCell">
            <Link to={`/logout`}><p style={{ fontWeight: 'bold' }}>Déconnexion</p></Link>
          </div>
          
        </div>}

      {showThermorSubMenu &&
        <div style={{
          position: "absolute", right: 25, top: 48, width: 282, backgroundColor: "#2C2E34", zIndex: 20,
          display: 'grid', gridTemplateColumns: "1fr 1fr"
        }}>
          <div className="menuSubThermorCell" style={{ gridColumn: "span 2", gridRow: 1 }}>
            <p>L'UNIVERS DES PROS</p>
          </div>
          <div className="menuSubThermorCell menuSubThermorCellActive" style={{ gridColumn: 1, gridRow: 2 }}>
            <a target="_blank" href={'https://espace-sav.thermor-pro.fr/'} rel="noopener noreferrer">
              <div>
                <img src={picto_sav} width="48" height="48" alt=''/>
                <p>Espace SAV</p>
              </div>
            </a>
          </div>
          <div className="menuSubThermorCell menuSubThermorCellActive" style={{ gridColumn: 2, gridRow: 2 }}>
            <a target="_blank" href={'https://assistance-pro.thermor-pro.fr/hc/fr/categories/7494255392796'} rel="noopener noreferrer">
              <div>
                <img src={picto_questions} width="48" height="48" alt=''/>
                <p>Questions <br />Réponses</p>
              </div>
          </a>
          </div>
          <div className="menuSubThermorCell menuSubThermorCellActive" style={{ gridColumn: 1, gridRow: 3 }}>
            <a target="_blank" href={'https://assistance-pro.thermor-pro.fr/hc/fr/community/topics/7494329810076'} rel="noopener noreferrer">
              <div>
                <img src={picto_forum} width="48" height="48" alt=''/>
                <p>Forum pro</p>
              </div>
            </a>
          </div>
          <div className="menuSubThermorCell menuSubThermorCellActive" style={{ gridColumn: 2, gridRow: 3 }}>
            <a target="_blank" href={'https://www.thermor-pro.fr/la-marque-thermor/les-femmes-et-les-hommes-thermor'} rel="noopener noreferrer">
              <div>
                <img src={picto_contact} width="48" height="48" alt=''/>
                <p>Contacter <br />Thermor</p>
              </div>
            </a>
          </div>
        </div>}
    </div>
  );
}

const MainMenu = (props) => {
  const className = UIStore.mobileMenuOpen ? 'mobileMenuOpen' : '';
  let brand = UIStore.environment_parameters.brand;

  const toggleMobileMenu = () => {
    UIStore.toggleMobileMenu();
  };

  return (
    <div>
      <div className="row">
        <div className={className} //NOSONAR
         onClick={toggleMobileMenu} //NOSONAR
        >
          <div className="overlay"></div>
          <MobileMenu className="mobileMenuOpen" />
        </div>
        <div className="col topBarre">
          <button className="btn-menuMobile" onClick={toggleMobileMenu}></button>
          {brand !== 'thermorfr' && VERSION && <div className="versionApp">v{VERSION}</div>}
          <nav className="topMenu">
            {brand === 'thermorfr' ?
              <MainMenuThermor />
              :
              <MainMenuDefault brand={brand}/>
            }
          </nav>
          {brand !== 'thermorfr' && brand !== 'pacific' && <LanguageSwitch />}
          {brand === 'thermor' && <span className="thermorReduceMode">THERMOR</span>}
          {brand === 'pacific' && <span className="thermorReduceMode">PAC AIR EAU</span>}
        </div>
      </div>

      {brand === 'thermorfr' &&
        <div className="bandeauThermorContainer">
          <div className="row bandeauThermor">
            <a href="https://www.thermor-pro.fr/" target="_blank" rel="noopener noreferrer">
              <img src={thermor_logo} height="65" width="244" alt='' />
            </a>
            <a href="https://simulhome-pac-air-eau.thermor-pro.fr/" target="_blank" rel="noopener noreferrer">
              {/* <img className="simulHomeLibelle" src={simul_home} height="24" width="105" /> */}
              <span className="simulHomeLibelle">Simul'home PAC air-eau</span>
            </a>
            <div className="helpThermorContainer">
              <Link to={`/${UIStore.env}/help`} className="helpThermor">{_T('MMENU_HELP')}</Link>
            </div>
          </div>
        </div>}
    </div>
  );
}



export default observer(MainMenu);
