import { makeObservable, observable, action } from 'mobx'
import * as Yup from 'yup'

class HPChoiceStore {
  formValidationScheme = Yup.object().shape({
    heatPumpType: Yup.string().required(),
    typologie: Yup.mixed().when('heatPumpType', {
      is: '1',
      then: () => Yup.string().required(),
      otherwise: () => Yup.mixed(),
    }),
    captage: Yup.mixed().when('heatPumpType', {
      is: '2',
      then: () => Yup.string().required(),
      otherwise: () => Yup.mixed(),
    }),
    utilisation: Yup.mixed().when('heatPumpType', {
      is: '3',
      then: () => Yup.string().required(),
      otherwise: () => Yup.mixed(),
    }),
    energie: Yup.mixed().when('heatPumpType', {
      is: '3',
      then:() =>  Yup.string().required(),
      otherwise:() =>  Yup.mixed(),
    }),
    alimentation: Yup.string().required(),
    abonnement: Yup.string().required(),
    releveChaudiere: Yup.string().required(),
    anneeChaudiere: Yup.mixed().when('releveChaudiere', {
      is: 'chaudiere',
      then:() =>  Yup.string().required(),
      otherwise:() =>  Yup.mixed(),
    }),
    typeChaudiere: Yup.mixed().when('releveChaudiere', {
      is: 'chaudiere',
      then:() =>  Yup.string().required(),
      otherwise: () => Yup.mixed(),
    }),
    energieChaudiere: Yup.mixed().when('releveChaudiere', {
      is: 'chaudiere',
      then:() =>  Yup.string().required(),
      otherwise:() =>  Yup.mixed(),
    }),
    puissanceChaudiere: Yup.mixed().when('releveChaudiere', {
      is: 'chaudiere',
      then:() =>  Yup.string().required(),
      otherwise:() =>  Yup.mixed(),
    }),
    typeECS: Yup.string().required(),
    nbOccupants: Yup.mixed().when('typeECS', {
      is: (value) => value === '' || value === 'sans',
      then:() =>  Yup.mixed(),
      otherwise:() =>  Yup.string().required(),
    }),
  })

  defaultForm = {
    heatPumpType: '1',
    heatPumpType_i18nCode: 'AEROTHERMAL',
    typologie: '0',
    typologie_i18nCode: 'SELECT_TYPO_IND',
    captage: undefined,
    captage_i18nCode: '',
    utilisation: undefined,
    utilisation_i18nCode: undefined,
    energie: undefined,
    energie_i18nCode: '',
    alimentation: undefined,
    alimentation_i18nCode: '',
    abonnement: undefined,
    abonnement_i18nCode: '',
    releveChaudiere: 'sans',
    releveChaudiere_i18nCode: 'NON',
    anneeChaudiere: undefined,
    anneeChaudiere_i18nCode: '',
    typeChaudiere: undefined,
    typeChaudiere_i18nCode: '',
    energieChaudiere: undefined,
    energieChaudiere_i18nCode: '',
    puissanceChaudiere: undefined,
    puissanceChaudiere_value: '',
    typeECS: undefined,
    typeECS_i18nCode: '',
    nbOccupants: undefined,
  }

  form = { ...this.defaultForm }

  constructor() {
    makeObservable(this, {
      form: observable,
      initNewProject: action,
      updateHeatPumpType: action
    })
  }
  
  initNewProject() {
    this.form = { ...this.defaultForm }
  }

  updateHeatPumpType(code, label) {
    this.form.heatPumpType = code
    this.form.heatPumpType_i18nCode = label

    switch (code) {
      case '1':
        this.form.captage = ''
        this.form.utilisation = ''
        this.form.energie = ''
        break
      case '2':
        this.form.typologie = ''
        this.form.utilisation = ''
        this.form.energie = ''
        break
      case '3':
        this.form.captage = ''
        this.form.typologie = ''
        break
    }
  }
}

export default new HPChoiceStore()
