import React from 'react';
import numeral from 'numeral';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { _T } from '../../_i18n/i18n.helper';

import Field from "../../_components/forms/Field";
import UIStore from "../../store/UIStore";

const QuoteSection = (props) => {
  const { name, className, items, deleteProduct, addProduct, getTotal, showEco, onChange, filter } = props; //NOSONAR
  let validItems = [];
  return (
    <>
      <em>{name}</em>

      {items?.filter(i => i._type === 'generator' || i._selected || i._recommended).length > 0 && //NOSONAR
        <table className={className}>
          <thead>
          <tr>
            <th>{_T('ARTICLE_CODE')}</th>
            <th>{_T('DESC')}</th>
            <th>{_T('QTY')}</th>
            {!UIStore.environment_parameters.hidePrice &&
            <>
              <th>{_T('RECOM_PUBLIC_PRICE', {currency: UIStore.environment_parameters.currency})}</th>
              <th>{_T('DISCOUNT')}</th>
              {showEco && <th>{_T('ECO_PRICE', {currency: UIStore.environment_parameters.currency})}</th>}
              <th>{_T('TOTAL_HT', {currency: UIStore.environment_parameters.currency})}</th>
            </>}
            <th></th>
          </tr>
          </thead>
          <tbody>
          {items.map((item, index) => { //NOSONAR

            if (item._type !== filter) return;
            if (deleteProduct && (!item._selected && !item._recommended)) return;

            validItems.push(item);

            return (
              <tr key={'recommended' + index} //NOSONAR
              >
                <td className="code" data-title="Code article">
                  {item.id ? item.code_env : (<Field className='form-control'
                                                      type="text"
                                                      name='code_env'
                                                      onChange={(e) => onChange(e, index)}
                                                      value={item.code_env}/>)}
                </td>
                <td className="libelle" data-title="Désignation">
                  {item.id ? item.reference : (<Field className='form-control'
                                                      type="text"
                                                      name='reference'
                                                      onChange={(e) => onChange(e, index)}
                                                      value={item.reference}/>)}
                </td>
                <td className="quantite" data-title="Quantité">
                  <Field className='form-control'
                          type="number"
                          name='_qty'
                          min={0}
                          onChange={(e) => onChange(e, index, 0)}
                          value={item._qty}/>
                </td>
                {!UIStore.environment_parameters.hidePrice && <td className="prix" data-title="Prix public conseillé HT">
                  {item.id ? numeral(item.price).format('0,0.00') + ' ' + UIStore.environment_parameters.currency : (
                  <Field className='form-control'
                          type="number"
                          name='price'
                          min={item._type === 'generator' ? 1 : 0}
                          onChange={(e) => onChange(e, index, 0)}
                          value={item.price}/>)}</td>}
                {!UIStore.environment_parameters.hidePrice && <td className="remise" data-title="Taux de remise">
                  <Field className='form-control'
                          type="number"
                          name='_discount'
                          min={0}
                          max={100}
                          onChange={(e) => onChange(e, index, 0, 100)}
                          value={item._discount}/>
                </td>}
                {!UIStore.environment_parameters.hidePrice && showEco && <td className="ecopart" data-title="Eco-part HT">
                  {item.id ? numeral(item.eco).format('0,0.00') + ' ' + UIStore.environment_parameters.currency : (<Field className='form-control'
                                                                                                type="number"
                                                                                                name='eco'
                                                                                                min={0}
                                                                                                onChange={(e) => onChange(e, index, 0)}
                                                                                                value={item.eco}/>)}</td>}
                {!UIStore.environment_parameters.hidePrice && <td className="total"
                    data-title="Total HT">{numeral(getTotal(toJS(item))).format('0,0.00')} {UIStore.environment_parameters.currency}</td>}
                <td className="delete" data-title="">
                  {deleteProduct && !item._recommended &&
                  <button className="btn_delete" onClick={(e) => deleteProduct(e, index)}>
                    <i></i><span>{_T('DELETE')}</span></button>}
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      }

      <div className="tab_sousTotal">
        { addProduct && 
        <div style={{ width: "100%" }}>
          <button className="ajout_access" onClick={(e) => addProduct(e)}>
            <em>{_T('ADD_PRODUCT')}&nbsp;&nbsp;<span>+</span></em>
          </button>
        </div>
        }
        {!UIStore.environment_parameters.hidePrice &&
        <>
          <p className="total">{_T('TOTAL_HT', {currency: UIStore.environment_parameters.currency})}</p>
          <span className="total">{numeral(getTotal(toJS(validItems))).format('0,0.00')} {UIStore.environment_parameters.currency}</span>
        </>}  
      </div>
    </>
  );
}

export default  observer(QuoteSection);