import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { _T } from '../../_i18n/i18n.helper'
import * as dd from './dropDown.data'
import Field from '../../_components/forms/Field'
import formStore from './heatMode.store'
import UIStore from '../../store/UIStore'

/**
 * Manage the zone part of HeatMode Form
 */

const HeatModeZone = (props) => {
  const { errors, touched, zone, index, removeZone, onChange, onBlur, addZone } = props; //NOSONAR

  const [data, setData] = useState({
    emetteurs: [],
    temperatures: [],
  })

  useEffect(() => {
    setData(
      {
        ...data,
        emetteurs: dd.getEmetteursByZone(index)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTemperatureCombo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.emetteurs])

  const setTemperatureCombo = () => {
    const { zone } = props; //NOSONAR

    const selectedEmetteur = data.emetteurs.find((item) => item.code === zone.emetteur) //NOSONAR
    if (selectedEmetteur) {
      zone.emetteur_schemaCode = selectedEmetteur.schemaCode; //NOSONAR
      zone.temperatureNeeded = selectedEmetteur.temperatures.length > 0; //NOSONAR

      // Set les temperature par rapport au type d'emetteur
      // supprime les temperature > 60 pour le type "radiateur" uniquement chez thermor
      setData({
        ...data,
        temperatures: selectedEmetteur.temperatures.filter((t) => (UIStore.environment_parameters.brand === 'thermorfr' ? t <= 60 : true)),
      })
    }
  }

  const onTypeEmetteurChange = (e) => {
    const { zone } = props; //NOSONAR

    if (e) props.onChange(e); //NOSONAR

    zone.temperature = zone.emetteur === '4' ? '55' : ''; //NOSONAR
    setTemperatureCombo();
  }

  return (
    <div className="row">
      <div className="col col-12 col-lg-1 zone">
        <p>{_T('ZONE') + ' ' + (index + 1)}</p>
      </div>

      <div className="col col-12 col-lg-2">
        <Field
          className="data_surfaceZ1 form-control"
          type="number"
          name={`zones[${index}].surface`}
          placeholder={_T('ZONE_SURFACE')}
          onChange={onChange}
          onBlur={onBlur}
          value={zone.surface} //NOSONAR
          min={0}
          max={2000}
          step={0.1}
          unit="m²"
          errors={errors}
          touched={touched}
        />
      </div>

      <div className="col col-12 col-lg-2">
        <Field
          className="data_hauteurZ1 form-control"
          type="number"
          name={`zones[${index}].hauteur`}
          placeholder={_T('ZONE_HEIGHT')}
          onChange={onChange}
          onBlur={onBlur}
          value={zone.hauteur} //NOSONAR
          min={0}
          max={10}
          step={0.1}
          unit="m"
          errors={errors}
          touched={touched}
        />
      </div>

      <div className="col col-12 col-lg-4">
        <Field
          className="data_emet custom-select"
          type="select"
          name={`zones[${index}].emetteur`}
          placeholder={_T('ZONE_EMITTER')}
          onChange={onTypeEmetteurChange}
          onBlur={onBlur}
          value={zone.emetteur} //NOSONAR
          errors={errors}
          touched={touched}
          defaultValue={true}
          items={data.emetteurs}
        />
      </div>

      <div className="col col-12 col-lg-2">
        {(!zone.emetteur || zone.emetteur < 5) && ( //NOSONAR
          <Field
            className="data_temp custom-select"
            type="select"
            name={`zones[${index}].temperature`}
            placeholder={_T('ZONE_TEMPERATURE')}
            onChange={onChange}
            onBlur={onBlur}
            value={zone.temperature} //NOSONAR
            errors={errors}
            touched={touched}
            defaultValue={true}
            unit="°C"
            translate={false}
            items={data.temperatures}
          />
        )}
      </div>

      <div className="col col-12 col-lg-1">
        {index === 0 && formStore.form.zones.length < 2 && (
          <button className="ajout_zone" onClick={addZone}>
            <i>+</i>
            <em>{_T('ADD_ZONE')}</em>
          </button>
        )}
        {index > 0 && (
          <button className="ajout_zone" onClick={(e) => removeZone(e, index)}>
            <i>-</i>
            <em>{_T('REMOVE_ZONE')}</em>
          </button>
        )}
      </div>
    </div>
  )
}


export default observer(HeatModeZone)
