import React, { useEffect } from 'react';
import { toJS } from 'mobx'
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import manageForm from "../../_components/forms/manageForm";
import formStore from "./coolMode.store";
import { _T, _THtml } from "../../_i18n/i18n.helper";
import Field from "../../_components/forms/Field";
import NavigationButtons from "../../_components/Navigation/NavigationButtons";
import * as dd from "./dropDown.data";
import ProjectStore from "../../store/ProjectStore";
import UIStore from "../../store/UIStore";
import orientation from "../../assets/img/orientation-b.png";
import HelpPophover from "../../_components/aide/HelpPophover";

import fleche_titre_atlantic from "../../assets/img/fleche-titre-cold.svg";
import fleche_titre_thermor from "../../assets/img/fleche-titre-cold-thermor.svg";
import fleche_titre_pacific from '../../assets/img/fleche-titre-cold-pacific.svg'

const imgChevrons = {
  atlantic: fleche_titre_atlantic,
  thermorfr: fleche_titre_thermor,
  pacific: fleche_titre_pacific
}

const CoolMode = (props) => {
  const data = {
    nbPeoples: dd.cool_nbPeoples,
    inertia: dd.cool_inertia,
    location: dd.cool_location,
    mitoyen: dd.cool_mitoyen,
    orientation: dd.cool_orientation,
    occultant: dd.cool_occultant,
    temperatures: dd.cool_temperature
  };

  const fleche_titre = imgChevrons[UIStore.environment_parameters.brand] || fleche_titre_atlantic

  const submit = async () => {

    return props.validate() // NOSONAR
      .then(async () => {
        ProjectStore.resetResults();
        ProjectStore.updateForm('coolModeStep', { form: toJS(formStore.form) });
        return Promise.resolve();
      })
      .catch(err => {
        //toast.error(_T(err.errors.join(', '))) // NOSONAR
        toast.error(_T('SOME_FIELDS_NEED_YOUR_ATTENTION'));
        return Promise.reject(err);
      });

  };

  useEffect(() => {
    if (ProjectStore.project.forms.coolModeStep?.form) {
      formStore.form = { ...formStore.form, ...ProjectStore.project.forms.coolModeStep.form };
    }

    if (ProjectStore.project.forms.heatModeStep.form.mitoyennete === 1) {
      formStore.form.config.forEach((item, index) => {
        if (index < 4) {
          item.mitoyen = 0;
          item.mitoyen_i18n = _T('NO');
        }
      })
    }

    onOrientationChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onOrientationChange = async (e) => {
    if (e) props.onChange(e); // NOSONAR

    switch (formStore.form.config[0].orientation) {
      case '1':
        formStore.form.config[1].orientation_i18n = _T('EST');
        formStore.form.config[2].orientation_i18n = _T('SOUTH');
        formStore.form.config[3].orientation_i18n = _T('WEST');
        break;
      case '0':
        formStore.form.config[1].orientation_i18n = _T('NORTH_EST');
        formStore.form.config[2].orientation_i18n = _T('SOUTH_EST');
        formStore.form.config[3].orientation_i18n = _T('SOUTH_WEST');
        break;
      default: 
        // Do nothing
    }

    formStore.form.config[4].orientation_i18n = _T('ROOF');

  };

  const onnbPeoplesChange = (e) => {
    props.onChange(e); // NOSONAR

    try {
      if (ProjectStore.project.forms.hpChoiceStep.form.nbOccupants !== '') {
        ProjectStore.project.forms.hpChoiceStep.form.nbOccupants = formStore.form.nbPeoples;
      }
    } catch (e) {
      //
    }
  };

  const onmitoyenChange = (e, index) => {
    props.onChange(e); // NOSONAR

    if (formStore.form.config[index].mitoyen === '1') {
      formStore.form.config[index].surface = '0';
      formStore.form.config[index].occultant = '0';
      formStore.form.config[index].occultant_i18n = _T('NO');
    } else if (formStore.form.config[index].mitoyen === '0') {
      formStore.form.config[index].occultant = '2';
      formStore.form.config[index].occultant_i18n = _T('EXTERIOR');
    } else {
      formStore.form.config[index].surface = '';
      formStore.form.config[index].occultant = '';
      formStore.form.config[index].occultant_i18n = '';
    }
  };

  const onsurfaceChange = (e, index) => {
    props.onChange(e); // NOSONAR

    if (formStore.form.config[index].surface === '0') {
      formStore.form.config[index].occultant = '0';
      formStore.form.config[index].occultant_i18n = _T('NO');
    } else if (index >= 3 && formStore.form.config[index].occultant === '0') {
      formStore.form.config[index].occultant = '2';
      formStore.form.config[index].occultant_i18n = _T('EXTERIOR');
    }
  };


  const { errors, touched, onChange, onBlur } = props; // NOSONAR

  const brand = UIStore.environment_parameters.brand;

  return (
    <>
      <form id='form_stepHome' className="coldMode" onSubmit={(e) => e.preventDefault()}>
        <div className="row donneesGEO">
          <div className="col col-12 col-lg-2 titreRubrique">
            <p className={brand === "pacific" ? 'froid pacific' : 'froid'}>{_THtml('HABITAT_DESC')}</p>
            {brand !== "pacific" && <img className="chevron" src={fleche_titre} alt="" />}
          </div>

          <div className="col col-12 col-lg-10 contentRubrique">
            <div className="form">
              <div className="row row_cool_config">
                <div className="col col-12 col-lg-4">
                  <Field className='custom-select'
                    type="select"
                    name='inertia'
                    placeholder={_T('INERTIA')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={formStore.form.inertia}
                    errors={errors}
                    touched={touched}
                    defaultValue={true}
                    translate={true}
                    items={data.inertia}>
                    <button className="help cold" id='helpInertia'></button>
                    <HelpPophover targetId='helpInertia'>
                      <pre dangerouslySetInnerHTML={{ __html: _T('HELP_INERTIA') }}></pre>
                      <div></div>
                    </HelpPophover>
                  </Field>
                </div>

                <div className="col col-12 col-lg-4">
                  <Field className='custom-select'
                    type="select"
                    name='nbPeoples'
                    placeholder={_T('NB_PEOPLE')}
                    onChange={onnbPeoplesChange}
                    onBlur={onBlur}
                    value={formStore.form.nbPeoples}
                    errors={errors}
                    touched={touched}
                    defaultValue={true}
                    translate={false}
                    items={data.nbPeoples} />

                </div>

                <div className="col col-12 col-lg-4">
                  <Field className='custom-select'
                    type="select"
                    name='location'
                    placeholder={_T('LOCATION')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={formStore.form.location}
                    errors={errors}
                    touched={touched}
                    defaultValue={true}
                    translate={true}
                    items={data.location}>
                    <button className="help cold" id='helpLocation'></button>
                    <HelpPophover targetId='helpLocation'>
                      <pre dangerouslySetInnerHTML={{ __html: _T('HELP_LOCATION') }}></pre>
                      <div></div>
                    </HelpPophover>
                  </Field>
                </div>
              </div>

              <div className="row row_cool_config">
                <div className="col col-12 col-lg-4">
                  <Field className='custom-select'
                    type="select"
                    name='temperature'
                    placeholder={_T('DESIRED_TEMP_COOL')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={formStore.form.temperature}
                    errors={errors}
                    touched={touched}
                    unit="°C"
                    translate={false}
                    items={data.temperatures} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row donneesGEO" //NOSONAR
        >
          <div className="col col-12 col-lg-2 titreRubrique" //NOSONAR\
          >
            <p className={brand === "pacific" ? 'froid pacific' : 'froid'} //NOSONAR
            >{_THtml('HABITAT_CONFIG')}
              <button 
                id='helpConfig' //NOSONAR
                className='thermorfr-cold' //NOSONAR
              ></button>
              <HelpPophover targetId='helpConfig'>
                <pre dangerouslySetInnerHTML={{ __html: _T('HELP_CONFIG') }} //NOSONAR
                ></pre>
                <div></div>
              </HelpPophover>
            </p>
            {brand !== "pacific" && <img className="chevron" //NOSONAR
              src={fleche_titre} //NOSONAR
              alt="" //NOSONAR
            />}
          </div>

          <div className="col col-12 col-lg-10 contentRubrique" //NOSONAR
          >
            <div className="form" //NOSONAR
            >
              <img className="orientation" //NOSONAR
                src={orientation} //NOSONAR
                width="200" //NOSONAR
                alt="" //NOSONAR
                />
              <div className="row row_cool_config row_cool_config_header" //NOSONAR
              >
                <div className="col col-12 col-lg-2 cool_config_header" //NOSONAR
                >
                  {_T('FACE')}
                </div>
                <div className="col col-12 col-lg-2 cool_config_header" //NOSONAR
                >
                  {_T('LENGTH')}
                </div>
                <div className="col col-12 col-lg-2 cool_config_header" //NOSONAR
                >
                  {_T('MITOYEN')}
                </div>
                <div className="col col-12 col-lg-2 cool_config_header" //NOSONAR
                >
                  {_T('SURFACE')}
                </div>
                <div className="col col-12 col-lg-2 cool_config_header" //NOSONAR
                >
                  {_T('OCCULTANT')}
                </div>
              </div>

              {formStore.form.config?.map((config, index) => (
                <div key={index} //NOSONAR
                  className="row row_cool_config" //NOSONAR
                >
                  <div className="col col-12 cool_config_header mobileOnly" //NOSONAR
                  >
                    {_T('FACE')}
                  </div>
                  <div className="col col-12 col-lg-2" //NOSONAR
                  >
                    {index === 0 && <Field className='custom-select'
                      type="select"
                      name={`config[${index}].orientation`}
                      placeholder={_T('ORIENTATION')}
                      onChange={onOrientationChange}
                      onBlur={onBlur}
                      value={formStore.form.config[index].orientation}
                      errors={errors}
                      touched={touched}
                      defaultValue={true}
                      translate={true}
                      items={data.orientation} />}
                    {index > 0 && <Field className='form-control'
                      type="text"
                      name={`config[${index}].orientation_i18n`}
                      placeholder=""
                      onChange={onChange}
                      onBlur={onBlur}
                      value={formStore.form.config[index].orientation_i18n}
                      errors={errors}
                      touched={touched}
                      disabled={true} />}
                    {index === 4 && <>
                      <button 
                        className='help cold' //NOSONAR
                        id='helpRoof' //NOSONAR
                       ></button>
                      <HelpPophover targetId='helpRoof'>
                        <pre dangerouslySetInnerHTML={{ __html: _T('HELP_ROOF') }} //NOSONAR
                        ></pre>
                        <div></div>
                      </HelpPophover>
                    </>}
                  </div>
                  <div className="col col-12 cool_config_header mobileOnly" //NOSONAR
                  >
                    {_T('LENGTH')}
                  </div>
                  <div className="col col-12 col-lg-2" //NOSONAR
                  >
                    {index < 4 && <Field className='form-control'
                      type="number"
                      name={`config[${index}].length`}
                      placeholder=""
                      onChange={onChange}
                      onBlur={onBlur}
                      value={formStore.form.config[index].length}
                      errors={errors}
                      touched={touched}
                      defaultValue={true}
                      min={0}
                      step={0.1} />}
                  </div>
                  <div className="col col-12 cool_config_header mobileOnly" //NOSONAR
                  >
                    {_T('MITOYEN')}
                  </div>
                  <div className="col col-12 col-lg-2" //NOSONAR
                  >
                    {index < 4 && <Field className='custom-select'
                      type="select"
                      name={`config[${index}].mitoyen`}
                      placeholder=""
                      onChange={(e) => onmitoyenChange(e, index)}
                      onBlur={onBlur}
                      value={formStore.form.config[index].mitoyen}
                      errors={errors}
                      touched={touched}
                      defaultValue={true}
                      translate={true}
                      items={data.mitoyen} />}
                  </div>
                  <div className="col col-12 cool_config_header mobileOnly" //NOSONAR
                  >
                    {_T('SURFACE')}
                  </div>
                  <div className="col col-12 col-lg-2" //NOSONAR
                  >
                    <Field className='form-control'
                      type="number"
                      name={`config[${index}].surface`}
                      placeholder=""
                      onChange={(e) => onsurfaceChange(e, index)}
                      onBlur={onBlur}
                      value={formStore.form.config[index].surface}
                      errors={errors}
                      touched={touched}
                      defaultValue={true}
                      disabled={formStore.form.config[index].mitoyen === "1"}
                      min={0}
                      step={0.1} />

                  </div>
                  <div className="col col-12 cool_config_header mobileOnly" //NOSONAR
                  >
                    {_T('OCCULTANT')}
                  </div>
                  <div className="col col-12 col-lg-2" //NOSONAR
                  >
                    <Field className='custom-select'
                      type="select"
                      name={`config[${index}].occultant`}
                      placeholder=""
                      onChange={onChange}
                      onBlur={onBlur}
                      value={formStore.form.config[index].occultant}
                      errors={errors}
                      touched={touched}
                      defaultValue={true}
                      disabled={formStore.form.config[index].surface === '0'}
                      translate={true}
                      items={data.occultant} />

                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

      </form>

      <NavigationButtons handleSubmit={submit} hidePrev={true} />
    </>
  );
}

export default manageForm(observer(CoolMode), formStore, 'form');