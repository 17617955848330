import { makeObservable,  observable, action } from 'mobx';
import * as Yup from 'yup';

class HeatModeStore {

  formValidationScheme = Yup.object(
    {
      departement: Yup.string().required(),
      stationMeteo: Yup.string().required(),
      altitude: Yup.number().integer().required(),
      placesaid: Yup.string(),
      seaside: Yup.boolean().required(),
      tbase: Yup.number(),
      tbaseModified: Yup.boolean(),
      mitoyennete: Yup.number().integer().required(),
      level: Yup.number().integer().required(),
      zones: Yup.array()
        .of(Yup.object({
          surface: Yup.number().required(),
          hauteur: Yup.number().required(),
          emetteur: Yup.number().required(),
          temperatureNeeded: Yup.boolean(),
          temperature: Yup.mixed()
            .when('temperatureNeeded', {
              is: true,
              then: () => Yup.number().required(),
              otherwise: () => Yup.mixed(),
            }),
        })),
      deperditions: Yup.lazy(value => {
        if (value !== undefined) {
          switch (value.deperditionMethod) {
            case '0': // HeatModeDeperditionsConnues
              return Yup.object(
                {
                  deperditionMethod: Yup.string().required(),
                  temperature: Yup.number().required(),
                  deperditions: Yup.number().integer().required(),
                }
              );
            case '1': // HeatModeDeperditionsDPE
              return Yup.object(
                {
                  deperditionMethod: Yup.string().required(),
                  temperature: Yup.number().required(),
                  dpe: Yup.number().integer().required(),
                }
              );
            case '2': // HeatModeDeperditionsInvoice
              return  Yup.object(
                {
                  deperditionMethod: Yup.string().required(),
                  temperature: Yup.number().required(),
                  annualBilling: Yup.number().required(),
                  actualSystem: Yup.string().required(),
                  nrgPrice: Yup.number().required(),
                  withECS: Yup.string().required(),
                  nbPeoples: Yup.mixed()
                    .when('withECS', {
                      is: '1',
                      then: () => Yup.string().required(),
                      otherwise: () => Yup.mixed(),
                    }),
                  systemAge: Yup.string().required(),
                  dayTemp: Yup.string().required(),
                  nightTemp: Yup.string().required(),
                }
              );
            case '3': // HeatModeDeperditionsCoef
              return  Yup.object(
                {
                  deperditionMethod: Yup.string().required(),
                  temperature: Yup.number().required(),
                  year: Yup.number().min(100).max(2035).required(),
                  insulationWork: Yup.string().required(),
                  walls: Yup.mixed()
                    .when('insulationWork', {
                      is: '1',
                      then: () => Yup.string().required(),
                      otherwise: () => Yup.mixed(),
                    }),
                  roofing: Yup.mixed()
                    .when('insulationWork', {
                      is: '1',
                      then: () => Yup.string().required(),
                      otherwise: () => Yup.mixed(),
                    }),
                  windows: Yup.mixed()
                    .when('insulationWork', {
                      is: '1',
                      then: () => Yup.string().required(),
                      otherwise: () => Yup.mixed(),
                    }),
                }
              );
            default:
              return Yup.object(
                {
                  deperditionMethod: Yup.string().required(),
                }
              );
          }
        }
      })
    }
  );

  defaultZone = { surface: '', hauteur: 2.5, emetteur: undefined, emetteur_i18nCode: '', temperatureNeeded: true, temperature: '' };
  defaultForm = {
    departement: undefined, departement_value: '',
    stationMeteo: undefined, stationMeteo_value: '',
    altitude: '',
    placesaid: '',
    seaside: false,
    tbase: '',
    tbaseModified: false,
    mitoyennete: 0, mitoyennete_i18nCode: '0_WALL',
    level: 0, level_i18nCode: '0_LEVEL',
    zones: [],
    deperditions: {
      deperditionMethod: undefined, deperditionMethod_i18nCode: '',
      temperature: '19',
      deperditions: '',
      // --------------
      year: '',
      insulationWork: '0',
      walls: undefined, walls_i18nCode: '',
      roofing: undefined, roofing_i18nCode: '',
      windows: undefined, windows_i18nCode: '',
      // --------------
      dpe: '',
      // --------------
      annualBilling: '',
      actualSystem: undefined, actualSystem_i18nCode: '',
      nrgPrice: '',
      nrgUnit: '',
      withECS: undefined, withECS_i18nCode: '',
      nbPeoples: undefined,
      systemAge: undefined, systemAge_i18nCode: '',
      dayTemp: '',
      nightTemp: '',
      // --------------
      loss: {},
    }
  };

  form = {...this.defaultForm};

  initNewProject() {
    this.form = {...this.defaultForm};
    this.addZone();
  }

  updateMitoyennete(code, label) {
    this.form.mitoyennete = code;
    this.form.mitoyennete_i18nCode = label;
  }

 updateLevel(code, label) {
    this.form.level = code;
    this.form.level_i18nCode = label;
  }

  addZone() {
    this.form.zones.push({...this.defaultZone});
  }

 removeZone(index) {
    this.form.zones.splice(index, 1);
  }

  constructor() {
    makeObservable(this, {
      form: observable,
      initNewProject: action,
      updateMitoyennete: action,
      updateLevel: action,
      addZone: action,
      removeZone: action    

    })
    this.addZone();
  }

}

export default new HeatModeStore();
