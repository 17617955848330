import ax from 'axios';
import UIStore from '../store/UIStore';
import * as userService from '../User/auth.service';

/**
 * Inject Jwt token in all http request
 */
export const httpRequestInterceptor = () => {
    ax.interceptors.request.use(request => {
        const token = UIStore.token;
        if (token) {
            request.headers.Authorization = `Bearer ${token}`;
        }
        return request;
    }, error => {
        return Promise.reject(error);
    });
};

export const httpResponseInterceptor = () => {
  ax.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response?.status && error.response.data) {
      try {
        console.log(error.response.data.err.toString());
      } catch (e) { console.log(e); }

      console.log(error.response.status);

      switch (error.response.status) {
        case 403:
          break;
        case 404:
          break;
        case 401:
        case 498:
          userService.logout();
          break;
        default:
          console.log('error default');
          break;
      }
    }
    return Promise.reject(error);
  });
};