import {makeObservable, observable, action, computed } from 'mobx'
import validEnvList from '../validEnvironnementList.json'
import { API_URL } from '../config'
import ax from 'axios'
import { toast } from 'react-toastify'
import { _T } from '../_i18n/i18n.helper'

class UIStore {
  appReady = false
  user = null
  environnement_id = null
  environnement_sso = false
  environment_parameters = {}
  environment_energy = {}
  environment_langs = []
  token = null
  isAuthenticated = false
  lang = null
  fallbackLang = null
  env = 'fr'
  lastShowProduct = null
  mobileMenuOpen = false
  loadingBar = false
  disableLoadingBar = false
  satisfactionGoBack = false
  maintenanceMode = null
  disableMaintenanceMode = false
  loadingaction = null
  defaultEnv = 'fr'

  get logo() {
    let brand = 'atlantic'
    if (this.environment_parameters?.brand) {
      brand = this.environment_parameters.brand.toLowerCase()
    }

    return API_URL + '/assets/data/misc/' + brand + '-logo.png'
  }

  get isEnvFr() {
    return this.env === 'fr' || this.env === 'fr-t' || this.env === 'pac-air-eau' || this.env === 'pacific' 
  }

  get isNeuf() {
    return this.env === 'neuf'
  }

  get isAtlantic() {
    return this.env === 'fr'
  }

  get isThermor() {
    return this.env === 'fr-t' || this.env === 'pac-air-eau'
  }

  get isPacific() {
    return this.env === 'pacific'
  }

  constructor() {
    makeObservable(this, {
      appReady: observable,
      user: observable,
      environnement_id:  observable,
      environnement_sso:  observable,
      environment_parameters:  observable,
      environment_energy:  observable,
      environment_langs:  observable,
      token:  observable,
      isAuthenticated:  observable,
      lang:  observable,
      fallbackLang:  observable,
      env:  observable,
      lastShowProduct:  observable,
      mobileMenuOpen:  observable,
      loadingBar:  observable,
      disableLoadingBar:  observable,
      satisfactionGoBack:  observable,
      maintenanceMode:  observable,
      disableMaintenanceMode:  observable,
      loadingaction:  observable,
      appIsReady: action,
      logout: action,
      logged: action,
      setToken: action,
      setLanguage: action,
      setFallbackLanguage: action,
      setLastShowProduct: action,
      toggleMobileMenu: action,
      setLoadingBar: action,
      loadingComplete: action,
      loadingContinuousStart: action,
      hideLoadingBar: action,
      setSatisfactionGoBack: action,
      setDisableMaintenanceMode: action,
      logo: computed,
      isEnvFr: computed,
      isNeuf: computed,
      isAtlantic: computed,
      isThermor: computed,
      isPacific: computed,
    })


    if(window.location.href.indexOf('pac-air-eau') > -1)  {
      this.defaultEnv = 'pac-air-eau';
      this.checkCurrentEnvironnement(this.defaultEnv)
    } else if(window.location.href.indexOf('thermor') > -1) { //NOSONAR
      this.defaultEnv = 'pac-air-eau'; //NOSONAR
      this.checkCurrentEnvironnement(this.defaultEnv) //NOSONAR
    } else if(window.location.href.indexOf('pacific') > -1) { //NOSONAR
      this.defaultEnv = 'pacific';
      this.checkCurrentEnvironnement(this.defaultEnv)
    } else {
      this.defaultEnv = 'fr';
      this.checkCurrentEnvironnement()
    }

    let lang = localStorage.getItem('_lang')
    if (lang) lang = lang.split('-')[0]

    this.lang = lang

    this.setFallbackLanguage()

    const token = localStorage.getItem('_token')
    this.isAuthenticated = token !== null
    if (this.isAuthenticated) {
      this.token = token
      const user = localStorage.getItem('_user')
      if (user) {
        this.user = JSON.parse(user)
      }
    }
  }

  /**
   * Get environnement from url or from localstorage
   * check for validity or logout
   */
  async checkCurrentEnvironnement(forcedEnv) {
    let env = window.location.pathname.split('/')[1]
    env = env.toUpperCase()
    const found = validEnvList.find((code) => code === env)
    if (!found) env = localStorage.getItem('_env') || this.defaultEnv
    else if (found.toLowerCase() !== localStorage.getItem('_env')) {
      this.logout(false)
    }

    this.env = forcedEnv || env.toLowerCase()
    localStorage.setItem('_env', this.env)

    let maintData;

    try {
      maintData = await ax.get(`${API_URL}/maintenance/get`);
    } catch(error) {
      console.log(error);
    }

    try {
      const response = await ax.get(`${API_URL}/infos/${this.env}`);

      if (response.data?.id && response.data?.code === this.env) {
        this.environnement_id = response.data.id
        this.environment_parameters = response.data._parameters
        this.environment_energy = response.data._energy
        this.environment_langs = response.data.langs
        this.environnement_sso = response.data.sso
        this.maintenanceMode = maintData?.data?.isInMaintenance;
        localStorage.setItem('_envCode', response.data.id)
      }
    } catch (error) {
      console.error(error)
      this.logout(false)
      toast.error(_T('ERROR_GET_ENVIRONNEMENT_INFOS'))
    }
  }

  setToken(token) {
    localStorage.setItem('_token', token)
    this.token = token
  }

  logged(user) {
    localStorage.setItem('_user', JSON.stringify(user))
    this.user = user
    this.isAuthenticated = true

    const value = btoa(JSON.stringify({
      username: user.username
    }))

    let domainName = window.location.hostname.split('.').reverse().splice(0,2).reverse().join('.');
    document.cookie = "atlantic_sso_user="+value+"; expires=Session; path=/; secure; SameSite=Lax; domain=." + domainName;
  }

  logout(deleteCookie = true) {
    localStorage.removeItem('_token')
    localStorage.removeItem('_user')
    this.isAuthenticated = false
    this.user = null

    if(deleteCookie) {
      let domainName = window.location.hostname.split('.').reverse().splice(0,2).reverse().join('.');
      document.cookie = "atlantic_sso_user=''; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; secure; SameSite=Lax; domain=." + domainName;
    }
  }

  appIsReady() {
    this.appReady = true
  }

  setLanguage(lang) {
    lang = lang.split('-')[0]
    localStorage.setItem('_lang', lang)

    this.lang = lang
    this.setFallbackLanguage()
  }

  setFallbackLanguage() {
    let lang = this.environment_langs.find((l) => l === this.lang)
    this.fallbackLang = lang ? lang : this.environment_langs[0] //NOSONAR
  }

  setLastShowProduct(product) {
    this.lastShowProduct = product
  }

  toggleMobileMenu() {
    this.mobileMenuOpen = !this.mobileMenuOpen
  }

  setLoadingBar(ref) {
    this.loadingBar = ref.current
  }
  loadingComplete() {
    this.loadingaction = "complete"
  }
  loadingContinuousStart() {
    this.loadingaction = "continuousStart"
  }
  

  hideLoadingBar(state) {
    this.disableLoadingBar = state
  }

  setSatisfactionGoBack(value) {
    this.satisfactionGoBack = value
  }

  setDisableMaintenanceMode() {
    this.disableMaintenanceMode = true
  }
}

// const d = new UIStore()

export default new UIStore();
