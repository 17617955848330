import React, { useState } from 'react'
import { observer } from 'mobx-react'
import ISO6391 from 'iso-639-1'
import * as i18nService from './i18n.service'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import UIStore from '../store/UIStore'

const LanguageSwitch = (props) => {
  const [dropdownOpen, setdropdownOpen] = useState(false); //NOSONAR

  const availableLangs = {
    fr: ISO6391.getNativeName('fr'),
    en: ISO6391.getNativeName('en'),
    de: ISO6391.getNativeName('de'),
    nl: ISO6391.getNativeName('nl'),
    vls: 'Flamand',
    pl: ISO6391.getNativeName('pl'),
    es: ISO6391.getNativeName('es'),
    pt: ISO6391.getNativeName('pt'),
    it: ISO6391.getNativeName('it'),
    bg: ISO6391.getNativeName('bg'),
    el: ISO6391.getNativeName('el'),
    sr: ISO6391.getNativeName('sr'),
    sv: ISO6391.getNativeName('sv'),
    fi: ISO6391.getNativeName('fi'),
    da: ISO6391.getNativeName('da'),
    et: ISO6391.getNativeName('et'),
    hu: ISO6391.getNativeName('hu'),
    lt: ISO6391.getNativeName('lt'),
    hr: ISO6391.getNativeName('hr'),
    sl: ISO6391.getNativeName('sl'),
    ro: ISO6391.getNativeName('ro'),
  }

  const selectedLangFlag = `langue${UIStore.lang.toUpperCase()}`;

  const toggle = () => {
    setdropdownOpen(!dropdownOpen);
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="choixLangue">
      <DropdownToggle className="langue langueChoix" caret>
        <span className={selectedLangFlag}></span>
        {availableLangs[UIStore.lang]}
      </DropdownToggle>
      <DropdownMenu right>
        {availableLangs &&
          Object.entries(availableLangs).map(([key, value]) => {
            const className = `langue${key.toUpperCase()}`
            return (
              <DropdownItem key={key} className="btn dropdown-item langue" type="button" onClick={() => i18nService.translate(key)}>
                <span className={className}></span>
                {value}
              </DropdownItem>
            )
          })}
      </DropdownMenu>
    </Dropdown>
  )
}
export default observer(LanguageSwitch);