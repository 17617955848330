import UIStore from '../store/UIStore';
import ax from 'axios';
import { API_URL } from '../config';
import * as apiService from "../_services/api.service";

export function setToken (token) {
  UIStore.setToken(token);
}

export async function logout () {
  try {
    // const response = await ax.get(`${API_URL}/logout?env=${UIStore.env}`)
    UIStore.logout();
    // return true
  } catch (error) {
    UIStore.logout();
    // return true
  }
  return true;
}

/**
 * Get userinfos
 * @returns {Promise<boolean>}
 */
export async function getUserInfos () {
  try {
    const response = await ax.get(`${API_URL}/auth/userinfos`);
    UIStore.logged(response.data);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function forgotPassword (email) {
  return await apiService.post('forgot-password', {email}, null, true);
}