import { makeObservable, observable } from 'mobx'
import * as Yup from 'yup'

class AccountStore {
  formValidationScheme = Yup.object().shape({
    lastname: Yup.string().required(),
    firstname: Yup.string().required(),
    address1: Yup.string().required(),
    address2: Yup.string(),
    city: Yup.string().required(),
    postalcode: Yup.string().required(),
    siret: Yup.string(),
    phone: Yup.string().required(),
    fax: Yup.string(),
    email: Yup.string().required(),
    www: Yup.string(),
    comment: Yup.string(),
    energy: Yup.object()
  })

  form = {
    lastname: '',
    firstname: '',
    address1: '',
    address2: '',
    city: '',
    postalcode: '',
    siret: '',
    phone: '',
    fax: '',
    email: '',
    www: '',
    comment: '',
    image: '',
    energy: {}
  }

  constructor() {
    makeObservable(this, {
      form: observable
    })
  }
}

const accStore = new AccountStore();

export default accStore;
