import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link, withRouter } from "react-router-dom";

import { _T } from '../../_i18n/i18n.helper';

import { getBreadcrumbItems } from '../../configApp';
import ProjectStore from '../../store/ProjectStore'
import UIStore from '../../store/UIStore';

import logo_projipac from "../../assets/img/logo-projipac.svg";
import logo_thermor from "../../assets/img/logo-thermor.png";
import logo_pacific_project from "../../assets/img/logo-pacific-project.png";
import logo_pacific from "../../assets/img/logo-pacific-color.png";
import BreadcrumbMobile from "./BreadcrumbMobile";

const Breadcrumb = (props) =>  {
  const { handleSubmit, title } = props; //NOSONAR
  let currentStep = null;
  let brand = UIStore.environment_parameters.brand;

  useEffect(()=> {
    // Update breadcrumb on history change
    //unlisten = props.history.listen(checkStep);

    // Update breadcrumb first time
    checkStep(props.history.location); //NOSONAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   // return a function to execute at unmount
  //   return () => {
  //     unlisten()
  //   }
  // }, [])

  /**
   * Update breadcrumb (use above)
   * @param location
   * @param action
   */
  const checkStep = (location, action) => {
    const pathname = location.pathname.split('/'); //NOSONAR
    // Search for the breadcrumb step in location path
    // Then update breadcrumb step if match with a BREADCRUMB_ITEM
    for(let i=pathname.length-1; i>0; i--) {
      const endPath = pathname[i];
      const found = getBreadcrumbItems(UIStore.environment_parameters.disabledSteps).findIndex(item => item.id === endPath);
      if(found >= 0) {
        ProjectStore.changeStep(found);
        continue; //NOSONAR
      }
    }
  }

  return (
    <>
      <div className="menuBarreContainer">
        <div className="row menuBarre">
          <div className="logoProjipac">
            <Link to={`/${UIStore.env}/home`}>
              {brand === "thermorfr" && <img className="logoThermorImg" src={logo_thermor} alt="Thermor" width="84" height="60" />}
              {brand === "pacific" && <img className="logoPacificImg" src={logo_pacific_project} alt="Pacific" width="60" height="60" />}
              {brand !== "thermorfr" && brand !== 'pacific' &&
                <img className="logoProjipacImg" src={logo_projipac} alt="Projipac" width="62" height="62" />
              }
              <p>{title}</p>
            </Link>
          </div>
          { !title && <div className="col menuProjipac">
            <nav className="mainMenu">
              <ul className={
                brand === 'thermorfr' ? //NOSONAR
                  "chevronsBarre chevronsBarreThermor"
                  :
                  brand === 'pacific' ? //NOSONAR
                    "chevronsBarre chevronsBarrePacific"
                    :
                    "chevronsBarre"
              }>
                {
                  getBreadcrumbItems(UIStore.environment_parameters.disabledSteps).map(item => {

                    let className = '';
                    if(ProjectStore.project.currentStep === item.index) {
                      className = 'active';
                      currentStep = item;
                    } else if(ProjectStore.project.currentStep > item.index) {
                      className = 'passed';
                    }

                    return (
                      <li key={item.id}>
                        {ProjectStore.project.maxReachedStep >= item.index
                          ? <Link className={className}
                                  to={location => (`/${UIStore.env}/project/${item.id}` + (item.params ? `/${item.params}` : ''))}>{_T(item.label)}</Link>
                          : <a href="#" //NOSONAR
                            >{_T(item.label)}</a>
                        }
                      </li>
                    )

                  })
                }
              </ul>
            </nav>
          </div>}

          <div className="logoAtlantic">
            {brand === "pacific" && <img className="logoPacific2Img" src={logo_pacific} alt="Pacific" width="270" height="30" />}
            {brand !== "thermorfr" && brand !== 'pacific' && <img src={UIStore.logo} alt="" height="40" />}
          </div>

        </div>
      </div>

      { !title && currentStep && <BreadcrumbMobile currentStep={currentStep} handleSubmit={handleSubmit} /> }
    </>
  );
}

export default  withRouter(observer(Breadcrumb));
