import React from 'react';
import { API_URL } from '../config';
import UIStore from "../store/UIStore";

/**
 * Home Page
 */
export default function CGUSection(props) {
  //const lang = UIStore.lang === 'fr' || UIStore.lang === 'en' ? UIStore.lang : 'en';
  const fileName = UIStore.environment_parameters.termsAndConditions;
  const fileSrc = fileName && `${API_URL}/assets/data/cgu/${fileName}.pdf`;

  return (
    <div className="col col-12 col-md-9 contentAide">
      <embed src={fileSrc} type="application/pdf" width="100%" height="100%"/>
    </div>
  )
}