import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { isIOS } from 'react-device-detect'

import NavigationButtons from '../_components/Navigation/NavigationButtons'
import { _T } from '../_i18n/i18n.helper'

import ProjectStore from '../store/ProjectStore'
import UIStore from '../store/UIStore'
import SatisfactionModal from '../Satisfaction/_modal/index'
import * as luaService from './lua.service'
import MainMenu from '../_components/Navigation/MainMenu'
import Breadcrumb from '../_components/Navigation/Breadcrumb'
import * as projectService from './project.service'
import { getSynthesisPages } from '../configApp'

import saveAs from 'file-saver'

/**
 * Project page
 */
function ProjectStepSynthesis(props) {
  const { history } = props; // NOSONAR

  const [documents, setDocuments] = useState(null);
  const [checkedPages, setCheckedPages] = useState({});
  const [satisfactionModalOpened, setSatisfactionModalOpened] = useState(false);
  const [toogleSaveModal, setToogleSaveModal] = useState(false);
  const [generateInProgress, setGenerateInProgress] = useState(false);

  useEffect(() => {
    async function fetchData() {
      //satisfactionModal
      let lastDemand = localStorage.getItem('satisfactionLastDemand')
      if (lastDemand) {
        if (lastDemand !== 'filled') {
          let nextTry = new Date(lastDemand)
          let now = new Date()
          nextTry.setMonth(nextTry.getMonth() + 1)
          if (now >= nextTry) setSatisfactionModalOpened(true);
        }
      } else {
        setSatisfactionModalOpened(true);
      }

      let checkedPages = {}
      const synthesisPages = getSynthesisPages(UIStore.environment_parameters.disabledSteps)
      if (synthesisPages) {
        synthesisPages.forEach((page) => {
          checkedPages['page_' + page.id] = true
        })
      }

      let _documents = await luaService.getDocuments(ProjectStore.project.selectedProduct.product.id);
      setDocuments(_documents);
      setCheckedPages(checkedPages);
    }

    fetchData();
  }, [])

  const submit = () => {
    ProjectStore.saveStore()
    return Promise.resolve()
  }

  const handleSaveModalResponse = (header) => {
    if (generateInProgress) {
      setGenerateInProgress(false);
      if (header) {
        setTimeout(() => toast.info(_T('SYNTHESIS_GEN_IN_PROGRESS'), { hideProgressBar: true }), 500)
        
        projectService
          .generateSynthesis(checkedPages, header)
          .then((res) => {

            let name = ""
            if(UIStore.isThermor) name = 'simulhome-synthesis.pdf'
            else if(UIStore.isPacific) name = 'project-synthesis.pdf'
            else name = 'projipac-synthesis.pdf'

            if (isIOS) {
              // Open in new tab -> pb with adblock
              let blob = new Blob([res.data], { type: 'application/pdf' })
              // window.URL.createObjectURL(blob)
              saveAs(blob, name)
            } else {
              saveAs(res.data, name)
            }
          })
          .catch((err) => {
            // An error message is already catch globally
            // toast.error(_T('FRONT_GENERATE_SYNTHESIS_ERROR'))
          })
      }
    }
  }

  const generateSynthesis = () => {
    setToogleSaveModal(!toogleSaveModal);
    setGenerateInProgress(true);
  }

  const handlePageSelection = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;

    let _checkedPages = {...checkedPages};
    _checkedPages[item] = isChecked;

    setCheckedPages(_checkedPages);
  }

  const satisfactionModalYes = () => {
    UIStore.setSatisfactionGoBack(true);
    history.push(`/${UIStore.env}/satisfaction`); // NOSONAR
  }

  const satisfactionModalNo = () => {
    setSatisfactionModalOpened(false);
    localStorage.setItem('satisfactionLastDemand', new Date())
  }

  const brand = UIStore.environment_parameters.brand;
  const synthesisPages = getSynthesisPages(UIStore.environment_parameters.disabledSteps);

  return (
    <>
      <div className="header container-fluid">
        <MainMenu />
        <Breadcrumb handleSubmit={submit} hideSave={true} hideNext={true} />
      </div>

      <div className="main container-fluid">
        <div className="row synthese">
          <div className="col col-12 col-sm-12 col-md-6 choixDOC">
            <p>
              <i className="fichierPDF"></i>
              {_T('MY_SYNTHESIS')}
            </p>

            {synthesisPages && synthesisPages.length > 0 && (
              <>
                {/*<em>Quels documents souhaitez-vous joindre à votre synthèse ?</em>*/} {/*NOSONAR*/}
                <em>{_T('WHAT_DOC_TO_JOIN')}</em>

                <div 
                  className="form" // NOSONAR
                >
                  {synthesisPages.map((page) => {
                    const pageName = 'page_' + page.id
                    return (
                      <div 
                        key={page.id} // NOSONAR
                        className="pretty p-round p-default p-pulse" // NOSONAR
                        >
                        <input
                          type="checkbox" //NOSONAR
                          id="data_synthese_page" //NOSONAR
                          name={pageName} //NOSONAR
                          checked={checkedPages[pageName]} //NOSONAR
                          onChange={handlePageSelection} // NOSONAR
                        />
                        <div 
                          className="state" //NOSONAR
                        >
                          <label>
                            {ProjectStore.project.simulationCool && page.labelColdMode ? _T(page.labelColdMode) : _T(page.label)}
                          </label>
                        </div>
                      </div>
                    )
                  })}

                  <button 
                    className={brand === "pacific" && "pacific"} //NOSONAR
                    onClick={generateSynthesis} //NOSONAR
                    >
                    <i></i>
                    {_T('GENERATE_SYNTHESIS')}
                  </button>
                </div>
              </>
            )}
          </div>

          <div className="col col-12 col-sm-12 col-md-6 downloadDOC" //NOSONAR
          >
            <p>{_T('DOWNLOADABLE_DOCUMENT')}</p>

            <div>
              {documents?.map((doc, index) => (
                  <a key={'doc' + index} className="btn" href={doc.link} target="_blank" rel="noopener noreferrer" //NOSONAR
                  >
                    <i className="fichierPDF" //NOSONAR
                    ></i>
                    <em>{doc.label}</em>
                    <i className="download" //NOSONAR
                    ></i>
                  </a>
                ))}
            </div>
          </div>
        </div>
        <NavigationButtons
          handleSubmit={submit}
          hideNext={true}
          toogleSaveModal={toogleSaveModal}
          handleSaveModalResponse={handleSaveModalResponse}
        />
      </div>

      <SatisfactionModal isOpen={satisfactionModalOpened} yes={satisfactionModalYes} no={satisfactionModalNo} />
    </>
  )
}

export default observer(ProjectStepSynthesis);
