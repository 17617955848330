import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import numeral from 'numeral'
import saveAs from 'file-saver'
import MainMenu from '../_components/Navigation/MainMenu'
import Breadcrumb from '../_components/Navigation/Breadcrumb'
import NavigationButtons from '../_components/Navigation/NavigationButtons'
import { _T } from '../_i18n/i18n.helper'

import chaudiere from '../assets/img/produits/chaudiere.jpg'
import ProjectStore from '../store/ProjectStore'
import * as luaService from './lua.service'
import QuoteSection from './StepQuote/QuoteSection'
import Field from '../_components/forms/Field'
import * as projectService from './project.service'
import * as userService from '../MyAccount/user.service'
import UIStore from '../store/UIStore'
import { API_URL } from '../config'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

/**
 * Project page
 */
function ProjectStepQuote(props) {
  const [data, setData] = useState({
    vat: [],
    selectedVAT: '0',
    lines: [],
    comment: null,
    commentTemp: null,
    commentModalOpen: false,
  });

  useEffect(() => {
    async function fetchData() {
      const vat = await luaService.getVAT();
      const userInfos = await userService.get();

      const selectedVat = ProjectStore.project.quote?.vat
          ? ProjectStore.project.quote.vat
          : vat?.length > 0 // NOSONAR
          ? vat[0].code
          : '0'

          
          let accessories = ProjectStore.project.accessories?.recommendedAccessories
          .slice()
          .concat(ProjectStore.project.accessories.otherAccessories.slice().filter((acc) => acc._selected))
          .concat(ProjectStore.project.quote.otherlines.slice())
          .map((acc) => {
            acc._type = 'accessories'
            return sanitizeItem(acc);
          })
          
          ProjectStore.project.selectedProduct.product._type = 'generator'
          ProjectStore.project.selectedProduct.product._selected = true
          sanitizeItem(ProjectStore.project.selectedProduct.product)
          
          ProjectStore.setQuoteLines([ProjectStore.project.selectedProduct.product, ...accessories])

          setData({
            ...data,
            vat: vat,
            selectedVAT: selectedVat,
            comment: ProjectStore.project.quote?.comment ? ProjectStore.project.quote.comment : userInfos.comment,
            lines: [ProjectStore.project.selectedProduct.product, ...accessories]
          });
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sanitizeItem = (item) => {
    item.price = parseFloat(item.price)
    if (item.eco != null) {
      item.eco = parseFloat(item.eco)
    }

    if (item._qty == null) {
      item._qty = item._selected ? 1 : 0
    } else item._qty = parseInt(item._qty)

    if (item._discount == null) item._discount = 0
    else item._discount = parseFloat(item._discount)

    return item
  }

  const submit = () => {
    let total =
      projectService.computeTotal(ProjectStore.project.quote.otherlines, data.selectedVAT) +
      projectService.computeTotal(ProjectStore.project.quote.lines, data.selectedVAT)
    let totalHT =
      projectService.computeTotal(ProjectStore.project.quote.otherlines, 0) +
      projectService.computeTotal(ProjectStore.project.quote.lines, 0)

    ProjectStore.setComment(data.comment)
    ProjectStore.setQuoteTotal(total)
    ProjectStore.setQuoteTotalHT(totalHT)
    ProjectStore.setVat(data.selectedVAT)
    ProjectStore.saveStore()

    return Promise.resolve()
  }

  const getTotal = (items, applyVAT) => {
    applyVAT = applyVAT || false

    let itemsCopy = []

    if (!Array.isArray(items)) {
      itemsCopy = [items]
    } else {
      itemsCopy = items
    }

    // console.log(items)

    let total = 0
    itemsCopy.forEach((item) => {
      total += projectService.quoteGetLineTotal(item)
    })

    return projectService.quoteGetTotal(total, applyVAT, data.selectedVAT).round(2)
  }

  const addProduct = (e) => {
    let lines = [...data.lines]
    lines.push({
      _type: 'accessories',
      _custom: 'true',
      _qty: 1,
      _discount: 0,
      _selected: true,
      code_env: '',
      reference: '',
      price: 0,
      eco: 0,
    })
    updateQuote(lines)
  }

  const deleteProduct = (e, index) => {
    let lines = [...data.lines]
    let line = { ...lines[index] }
    line._selected = false
    lines[index] = line

    updateQuote(lines)
  }

  const onChangeVAT = (e, item) => {
    const { value } = e.target
    setData({
      ...data,
      selectedVAT: value,
    })
  }

  const generateXlsx = () => {
    projectService
      .generateXlsx(ProjectStore.alllQuoteLines, data.selectedVAT)
      .then((res) => {
        saveAs(res.data, `${UIStore.environment_parameters.brand}-quote.xlsx`)
      })
      .catch((err) => {
        toast.error(_T('FRONT_EXPORT_ERROR'))
      })
  }

  const generateCsv = () => {
    projectService
      .generateCsv(ProjectStore.project.quote.lines)
      .then((res) => {
        saveAs(res.data, `${UIStore.environment_parameters.brand}-export.csv`)
      })
      .catch((err) => {
        toast.error(_T('FRONT_EXPORT_ERROR'))
      })
  }

  const onChange = (e, index, min, max) => {
    let { name, value } = e.target
    if (min != null && (value < min || !value)) value = min
    if (max != null && value > max) value = max

    let lines = [...data.lines]
    let line = { ...lines[index] }
    line[name] = value
    lines[index] = line

    if (name === '_qty' && value > 0 && line._recommended) line._selected = true

    updateQuote(lines)
  }

  const onCommentChange = (e) => {
    setData({...data, commentTemp: e.target.value })
  }

  const updateQuote = (lines) => {
    setData({...data, lines: lines})

    ProjectStore.setQuoteLines([...lines])
  }

  const toogleCommentModal = () => {
    setData({
      ...data,
      commentTemp: !data.commentModalOpen ? data.comment : null,
      commentModalOpen: !data.commentModalOpen,
    })
  }

  const saveComment = () => {
    setData({
      ...data,
      comment: data.commentTemp,
      commentModalOpen: false,
    })
  }

  const hideNextButton = () => {
    if(!UIStore.environment_parameters.disabledSteps) return false;

    try {
      const disabledStepsArray = UIStore.environment_parameters.disabledSteps.split(',');
      if(disabledStepsArray.find(ds => ds === 'synthesis')) return true;
    } catch(ex) {
      return false
    }
  }

  return (
    <>
      <div className="header container-fluid">
        <MainMenu />
        <Breadcrumb handleSubmit={submit} />
      </div>

      <div className="main container-fluid quote">
        <div className="row devis">
          {!UIStore.environment_parameters.hidePrice &&<div className="row choixTVA">
            <form>
              <Field
                className="custom-select"
                type="select"
                id="data_tva"
                name="vat"
                placeholder={_T('CHOOSE_VAT')}
                onChange={onChangeVAT}
                value={data.selectedVAT}
                defaultValue={false}
                translate={false}
                items={data.vat}
              />
            </form>
          </div>}

          {ProjectStore.project.quote && (
            <div className="row tabTAB devisTVA">
              <div className="row produitTAB">
                <div className="imageTAB">
                  <div>
                    {ProjectStore.project.selectedProduct.product.classe_energie && (
                      <img
                        className="pictoNRJ"
                        src={`${API_URL}/assets/data/pictos_nrj/${ProjectStore.project.selectedProduct.product.classe_energie}.png`}
                        alt=""
                      />
                    )}
                    <img src={ProjectStore.project.selectedProduct.product.photo || chaudiere} alt="" />
                  </div>
                </div>

                <div className="tarifTAB">
                  <QuoteSection
                    name={_T('GENERATOR')}
                    className="prodTAB"
                    items={data.lines}
                    filter="generator"
                    showEco={true}
                    getTotal={getTotal}
                    onChange={onChange}
                  />

                  <QuoteSection
                    name={_T('ACCESSORIES')}
                    className="accessTAB"
                    items={data.lines}
                    filter="accessories"
                    getTotal={getTotal}
                    onChange={onChange}
                    showEco={true}
                    addProduct={addProduct}
                    deleteProduct={deleteProduct}
                  />

                  {!UIStore.environment_parameters.hidePrice &&
                    <div className="tab_total">
                      <p className="total">{_T('TOTAL_TTC', { currency: UIStore.environment_parameters.currency })}</p>
                      <span className="total">
                        {numeral(getTotal(data.lines, true)).format('0,0.00')} {UIStore.environment_parameters.currency}
                      </span>
                    </div>}
                </div>
              </div>
            </div>
          )}

          <div className="row boutonsTAB">
            <button className="btn_exporter" onClick={toogleCommentModal}>
              {data.comment ? _T('UPDATE_QUOTE_NOTE') : _T('ADD_QUOTE_NOTE')}
            </button>
            <button className="btn_exporter" onClick={generateCsv}>
              {_T('SF_EXPORT')}
            </button>
            <button className="btn_generer" onClick={generateXlsx}>
              {_T('XLS_EXPORT')}
            </button>
          </div>
        </div>

        <Modal isOpen={data.commentModalOpen} toogle={toogleCommentModal} size="lg" className="modalSaveProject">
          <ModalBody>
            <button className="btn_close" onClick={toogleCommentModal}>
              X
            </button>
            <div>
              <div className="row">
                <p>{_T('QUOTE_NOTE_MODAL_TITLE')}</p>
              </div>
              <div className="tab_comment">
                <textarea
                  className="form-control comment quote_comment"
                  maxLength="600"
                  onChange={(e) => onCommentChange(e)}
                  value={data.commentTemp}
                ></textarea>
                {data.commentTemp && (
                  <>
                    {600 - data.commentTemp.length} {_T('QUOTE_NOTE_REMAINING_CHARS')}{' '}
                  </>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn enregistrer" onClick={saveComment}>
              {_T('NAV_SAVE')}
            </button>
            <button className="btn cancel" onClick={toogleCommentModal}>
              {_T('CANCEL')}
            </button>
          </ModalFooter>
        </Modal>

        <NavigationButtons handleSubmit={submit} hideNext={hideNextButton()} />
      </div>
    </>
  )
}

export default observer(ProjectStepQuote)
