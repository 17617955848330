import React, { useEffect, useRef } from "react";
import _LoadingBar from "react-top-loading-bar";

const LoadingBar = (props) => {
    const { action, ..._props } = props; //NOSONAR
    const ref = useRef();

    const complete = () => {
        ref.current.complete();
    }

    const continuousStart = () => {
        ref.current.continuousStart();
    }

    useEffect(() => {
        switch (action) {
            case 'complete':
                complete();
                break;
            case 'continuousStart':
                continuousStart();
                break;
        }
    }, [action]);

    return <_LoadingBar {..._props} ref={ref} /> // NOSONAR
}

export default LoadingBar;
