import React from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';
import Field from '../../_components/forms/Field';
import formStore from "./heatMode.store";
import * as dd from './dropDown.data'
import HeatModeCalculatedNeeds from "./HeatModeCalculatedNeeds";
import { toast } from 'react-toastify';

/**
 * Manage the zone part of HeatMode Form
 */

const HeatModeDeperditionsConnues = (props) => {
  const { errors, touched, onBlur, deperditions } = props; // NOSONAR

  const data = {
    insulationWork: dd.insulationWork,
    walls: dd.insulation,
    roofing: dd.insulation,
    windows: dd.insulation,
    temperatures: dd.temperature
  };

  const onChange = (e) => {
    props.onChange(e); // NOSONAR
    props.calculateDeperd(); // NOSONAR
  };

  const onBlurYear = (e) => {
    if(e.target.value && errors?.deperditions?.year) {
      toast.error(_T('ERROR_YEAR'));
    }

    onBlur(e);
  }

  const onYearChange = (e) => {
    props.onChange(e); // NOSONAR

    let { value } = e.target;

    if(value.length >= 4) {
      props.calculateDeperd(); // NOSONAR
    }
  };

  const onInsulationWorkChange = (e) => {
    props.onChange(e); // NOSONAR

    formStore.form.deperditions.walls = '1';
    formStore.form.deperditions.roofing = '1';
    formStore.form.deperditions.windows = '1';

    props.calculateDeperd(); // NOSONAR
  };

    return (
      <div className="row estimation">
        <div className="col col-12 col-lg-4">
          <div className="form">

            <Field id="data_murs"
                   className='custom-select'
                   type="select"
                   name='deperditions.temperature'
                   placeholder={_T('DESIRED_TEMP')}
                   onChange={onChange}
                   onBlur={onBlur}
                   value={deperditions.temperature} // NOSONAR
                   errors={errors}
                   touched={touched}
                   defaultValue={true}
                   unit="°C"
                   translate={false}
                   items={data.temperatures} />

            <Field id="data_annee"
                   className='form-control'
                   type="number"
                   name={'deperditions.year'}
                   placeholder={_T('CONSTRUCTION_YEAR')}
                   onChange={onYearChange}
                   onBlur={onBlurYear}
                   value={deperditions.year} // NOSONAR
                   errors={errors}
                   touched={touched} />

            <div className="input-group blocForm choixTravaux">
              <p>{_T('HAVE_YOU_DONE_ANY_WORKS')}</p>

              <Field type="radio"
                     name={'deperditions.insulationWork'}
                     placeholder={_T('INSULATION_WORK')}
                     onChange={onInsulationWorkChange}
                     onBlur={onBlur}
                     value={deperditions.insulationWork} // NOSONAR
                     errors={errors}
                     touched={touched}
                     items={data.insulationWork} />

                <div className="input-group">
                  <Field id="data_murs"
                         className='custom-select'
                         type="select"
                         name='deperditions.walls'
                         placeholder={_T('WALL_INSULATION')}
                         onChange={onChange}
                         onBlur={onBlur}
                         value={deperditions.walls} // NOSONAR
                         errors={errors}
                         touched={touched}
                         defaultValue={true}
                         disabled={deperditions.insulationWork === '0'} // NOSONAR
                         items={data.walls} />

                  <Field id="data_toiture"
                         className='custom-select'
                         type="select"
                         name='deperditions.roofing'
                         placeholder={_T('ROOFING_INSULATION')}
                         onChange={onChange}
                         onBlur={onBlur}
                         value={deperditions.roofing} // NOSONAR
                         errors={errors}
                         touched={touched}
                         defaultValue={true}
                         disabled={deperditions.insulationWork === '0'} // NOSONAR
                         items={data.roofing} />

                  <Field id="data_fenetres"
                         className='custom-select'
                         type="select"
                         name='deperditions.windows'
                         placeholder={_T('WINDOW_INSULATION')}
                         onChange={onChange}
                         onBlur={onBlur}
                         value={deperditions.windows} // NOSONAR
                         errors={errors}
                         touched={touched}
                         defaultValue={true}
                         disabled={deperditions.insulationWork === '0'} // NOSONAR
                         items={data.windows} />
                </div>
            </div>
          </div>
        </div>

        <HeatModeCalculatedNeeds />
      </div>
    );
  }


export default observer(HeatModeDeperditionsConnues);