import ProjectStore from '../store/ProjectStore'
import UIStore from '../store/UIStore'
import heatModeStore from './StepHome/heatMode.store'
import coolModeStore from './StepHome/coolMode.store'
import hpChoiceStore from './StepHPChoice/hpChoice.store'
import saveProjectStore from './Save/form.store'
import * as clientService from "../Client/client.service";
import {_T} from '../_i18n/i18n.helper'
import * as apiService from '../_services/api.service';

export function initNewProject() {
  ProjectStore.initNewProject();
  heatModeStore.initNewProject();
  coolModeStore.initNewProject();
  hpChoiceStore.initNewProject();
  saveProjectStore.initNewProject();
}

export async function getAll(pageSize, pageNumber, searchInput, stepIndexes) {
  const params = {
    pageSize: pageSize,
    pageNumber: pageNumber,
    searchInput: searchInput,
    stepIndexes: stepIndexes
  }

  return await apiService.get(`projects/${UIStore.env}`, { params: params});
}
export async function save(data) {
  try {
    if(data.registerClient) {
      await clientService.insertOrUpdate(data.client);
    }
    const traceId  = localStorage.getItem("traceId");
    let project = {
      id: ProjectStore.project.id,
      user_id: UIStore.user.id,
      environnement_id: UIStore.environnement_id,
      ...(+traceId && {traceId: +traceId}),
      data: {...ProjectStore.project, ...{ header: data }}
    };

    if(!project.id) delete project.id;

    return await insertOrUpdate(project);

  } catch (error) {
    console.error(error);
    return Promise.reject(error);

  }

}

export async function load(project) {
  try {
    if(!project.data) {
      let response = await apiService.get(`project/${project.id}` + (project.uid ? `/${project.uid}` : ''));
      ProjectStore.load(response);
      return response.data;
    } else {
      ProjectStore.load(project);
      return project.data;
    }

  } catch (error) {
    console.error(error);
    return Promise.reject(error);

  }
}

export async function destroy(project) {
  return await apiService.del(`project/${project.id}`);
}

export async function duplicate(project) {
  return await apiService.get(`duplicateProject/${project.id}`);
}

export async function insertOrUpdate (project) {
  try {
    if(project.id) {
      return await apiService.patch(`project/${project.id}`, project);
    } else {
      return await apiService.post('project', project);
    }

  } catch (error) {
    console.error(error);
    return Promise.reject(error);

  }

}

export function computeSchemaCode() {
  let schemaCode = '';
  schemaCode += ProjectStore.project.forms.hpChoiceStep.form.releveChaudiere === 'chaudiere' ? '-' + 1 : '';
  schemaCode += ProjectStore.project.forms.hpChoiceStep.form.typeECS !== 'sans' ? '-' + 2 : '';
  schemaCode += ProjectStore.project.forms.heatModeStep.form.zones.length === 2 ? '-' + 3 : '';
  schemaCode += ''; // Raf
  schemaCode += ''; // Piscine

  ProjectStore.project.forms.heatModeStep.form.zones.forEach( (zone, index) => {
    schemaCode += '-' + zone.emetteur_schemaCode + (index + 1) ;
  });

  ProjectStore.setSchemaCode(schemaCode);;
}

export function selectProduct(product) {
  ProjectStore.selectProduct(product);
}

export function quoteGetLineTotal(item) {
  const discount = (100-parseFloat(item._discount))/100;
  const t = (parseFloat(item.price) + parseFloat(item.eco)) * (parseFloat(item._qty)) * (isNaN(discount) ? 0 : discount);
  console.log('quoteGetLineTotal: ' + t);
  if(isNaN(t)) {
    return 0;
  }
  return t;
}

export function quoteGetTotal(total, applyVAT, vat) {
  const t = isNaN(total) ? 0 : total * (applyVAT && vat ? 1+vat/100 : 1); //NOSONAR
  // console.log('quoteGetTotal: ' + t)
  return t;
}

export function computeTotal(lines, vat) {
  if(!lines || lines.length <= 0) return 0;

  let total = 0;

  lines.forEach(line => {
    line._i18ntype = _T(line._type.toUpperCase());
    line._total = quoteGetLineTotal(line);

    total += line._total;

    line._total = line._total.round(2);
  });

  return quoteGetTotal(total, true, vat).round(2);
}

export async function generateSynthesis(pages, header) {
  return await apiService.post('generatePdf', {project: {...ProjectStore.project, ...header}, pages: pages, env: UIStore.env, traceId: ProjectStore.traceId}, {responseType: 'blob'});
}

export async function generateXlsx(lines, vat) {
  const data = {
    header: {
      i18n_cat: _T("QUOTE_XLSX_CATEGORY"),
      i18n_code: _T("QUOTE_XLSX_CODE"),
      i18n_desc: _T("QUOTE_XLSX_DESC"),
      i18n_qty: _T("QUOTE_XLSX_QTY"),
      i18n_price: _T("QUOTE_XLSX_PRICE", {currency: UIStore.environment_parameters.currency}),
      i18n_discount: _T("QUOTE_XLSX_DISCOUNT"),
      i18n_eco: _T("QUOTE_XLSX_ECO", {currency: UIStore.environment_parameters.currency}),
      i18n_total: _T("QUOTE_XLSX_TOTAL", {currency: UIStore.environment_parameters.currency})
    },
    lines: lines,
    i18n_vtaHeader: _T("QUOTE_XLSX_VTA"),
    vta: vat,
    i18n_totalTTCHeader: _T("QUOTE_XLSX_TOTALTTC", {currency: UIStore.environment_parameters.currency}),
    total: 0
  };

  data.total = computeTotal(data.lines, vat);

  return await apiService.post('generateXlsx', data, {responseType: 'blob'});
}

export async function generateCsv(lines) {
  let csv = [["Numero","Type","Option","Parent","CodeArticle","Description","Quantite","PrixPublic"]];

  lines.forEach((line, index) => {
    csv.push([
      index+1,"P",
      "","",
      line.code_env,
      "",
      line._qty,
      "",
    ]);
  });

  const csvContent = csv.map(e => e.join(";")).join("\n");
  return {data: new Blob([csvContent], {type: "text/plain;charset=utf-8"})};
}