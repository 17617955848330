import React from 'react';
import { observer } from 'mobx-react';
import { Link } from "react-router-dom";

import { _T } from '../../_i18n/i18n.helper';

import UIStore from '../../store/UIStore';

import logo_projipac from "../../assets/img/logo-projipac.svg";
import logo_simulhome from "../../assets/img/simul_home_blanc.svg";
import logo_pacific from "../../assets/img/logo-pacific-project.png"

const MobileMenu = (props) => {
  let brand = UIStore.environment_parameters.brand;
  return (
    <div className="menuMobile">
      {brand === "thermorfr" ? //NOSONAR
        <img src={logo_simulhome} width="179" height="44" alt="Logo Simul'home" />
        :
        brand === "pacific" ? //NOSONAR
          <img src={logo_pacific} width="60" height="60" alt="Logo Simul'home" />
          :
          <div className="blocProjipac">
            <p>Proji-Pac</p>
            <img src={logo_projipac} width="60" height="60" alt="Logo Projipac" />
          </div>
      }
      <nav>
        <ul>
          <li className="menuAide"><Link to={`/${UIStore.env}/help`}><i></i>{ _T('MMENU_HELP') }</Link></li>
          <li className="menuCompte"><Link to={`/${UIStore.env}/my_account`}><i></i>{ _T('MMENU_MY_ACCOUNT') }</Link></li>
          <li className="menuSimulations"><Link to={`/${UIStore.env}/my_projects`}><i></i>{ _T('MMENU_MY_SIMULATION') }</Link></li>
          <li className="menuDeconnexion"><Link to={`/logout`}><i></i>{ _T('MMENU_LOGOUT') }</Link></li>
        </ul>
      </nav>
    </div>
  );
}

export default  observer(MobileMenu);