import React from 'react'
import { observer } from 'mobx-react'
import * as _ from 'lodash'
import { _T } from '../../_i18n/i18n.helper'
import Rater from './field_components/rate'

/**
 * Manage form fields
 */

function Field(props) { //NOSONAR
  const renderError = (touched, errors, name) => (
    <></>
    // Uncomment to show error message under the fields
    // _.get(touched, name, false) && _.get(errors, name, false) && <small className="form-text text-muted">{ _.get(errors, name) }</small>
  )

  const onNumberchange = (e, min, max) => {
    if (min != null && e.target.value < min) e.target.value = min
    if (max != null && e.target.value > max) e.target.value = max

    props.onChange(e); //NOSONAR
  }

  const { type, name, id, placeholder, onChange, onBlur, value, touched, errors, defaultValue, items, disabled, children } = props; //NOSONAR
  let { min, max, step, unit, required, className, translate, defaultValueData } = props; //NOSONAR

  required = required != null ? required : false
  defaultValueData = defaultValueData != null ? defaultValueData : '';

  let a = _.get(touched, name, false) && _.get(errors, name, false) ? ' error' : '';
  let className_ = className + a;

  className_ += unit != null ? ' withUnit' : '';
  /* eslint eqeqeq: 0 */
  translate = translate != false; //NOSONAR

    switch (type) {
      case 'textarea':
        className_ += value == null || value === '' ? ' empty' : ' not-empty'
        return (
          <div className="input-group blocForm">
            {id && <i className={id}></i>}
            <textarea
              id={id}
              className={className_}
              name={name}
              //placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              required={required}
              disabled={disabled}
            />
            <label className="placeholder">{placeholder}</label>
            {renderError(touched, errors, name)}
          </div>
        )
      case 'text':
      case 'email':
        className_ += value == null || value === '' ? ' empty' : ' not-empty'
        max = max != null ? max : 10000000
        return (
          <div className="input-group blocForm">
            {id && <i className={id}></i>}
            <input
              id={id}
              className={className_}
              type={type}
              name={name}
              //placeholder={placeholder}
              maxLength={max}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              required={required}
              disabled={disabled}
            />
            <label className="placeholder">
              {placeholder}
              {unit && ` (${unit})`}
            </label>{' '}
            {/*unit && <span className="unit">{unit}</span>*/}
            {renderError(touched, errors, name)}
          </div>
        )
      case 'range':
      case 'number':
        className_ += value == null || value === '' ? ' empty' : ' not-empty'
        min = min != null ? min : -1000000
        max = max != null ? max : 10000000
        step = step || 1
        return (
          <div className="input-group blocForm">
            {id && <i className={id}></i>}
            <input
              className={className_}
              type={type}
              name={name}
              //placeholder={placeholder}
              onChange={(e) => onNumberchange(e, min, max)}
              onBlur={onBlur}
              value={value}
              min={min}
              max={max}
              step={step}
              required={required}
              disabled={disabled}
            />
            <label className="placeholder" name={name}>
              {placeholder}
              {unit && ` (${unit})`}
            </label>{' '}
            {/*unit && <span className="unit">{unit}</span>*/}
            {renderError(touched, errors, name)}
          </div>
        )

      case 'select':
        className_ += value == null || value === '' ? ' empty' : ' not-empty'
        return (
          <>
            <div className="input-group blocForm">
              {id && <i className={id}></i>}
              <select
                id={id}
                className={className_}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                required={required}
                disabled={disabled}
              >
                {defaultValue && <option value={defaultValueData}></option>}
                {items?.map((item, index) => { //NOSONAR
                    if (_.isObject(item)) {
                      return (
                        <option key={name + index} //NOSONAR
                         value={item.code}>
                          {translate ? _T(item.label) : item.label}
                        </option>
                      )
                    } else {
                      return (
                        <option key={item} value={item}>
                          {translate ? _T(item) : item}
                        </option>
                      )
                    }
                  })}
              </select>
              <label className="placeholder">
                {placeholder}
                {unit && ` (${unit})`}
              </label>{' '}
              {/*unit && <span className="unit select">{unit}</span>*/}
              {renderError(touched, errors, name)}
            </div>
            {children}
          </>
        )

      case 'radio':
        className_ = (className || '') + ' blocForm choixIsolation'
        return (
          <div className={className_}>
            {items?.map((item) => ( //NOSONAR
                <div className="pretty p-default p-round p-pulse" key={item.code}>
                  <input
                    type="radio"
                    name={name}
                    id={name + '_' + item.code}
                    value={item.code}
                    onChange={onChange}
                    checked={item.code === value}
                    disabled={disabled}
                    required={required}
                  />
                  <div className="state">
                    <label htmlFor={name + '_' + item.code}>{_T(item.label)}</label>
                  </div>
                </div>
              ))}
            {renderError(touched, errors, name)}
          </div>
        )

      case 'checkbox':
        return (
          <div className="pretty p-default">
            <input
              type="checkbox"
              name={name}
              id={id}
              onChange={onChange}
              value={true}
              checked={value}
              disabled={disabled}
              required={required}
            />
            <div className="state">
              <label className={className_}>{_T(placeholder)}</label>
            </div>
          </div>
        )
      case 'switch':
        return (
          <div className="pretty p-switch p-fill">
            <input type="checkbox" name={name} id={id} onChange={onChange} value={true} checked={value} disabled={disabled} />
            <div className="state">
              <label>{_T(placeholder)}</label>
            </div>
          </div>
        )
      case 'rater':
        return <Rater className={className_} value={value} onChange={onChange} disabled={disabled} name={name} />
      default:
        // do something
    }
  }


export default observer(Field);
