import ProjectStepHome from "./ProjectStepHome";
import ProjectStepHPChoice from "./ProjectStepHPChoice";
import ProjectStepResults from "./ProjectStepResults";
import ProjectStepAcc from "./ProjectStepAcc";
import ProjectStepQuote from "./ProjectStepQuote";
import ProjectStepSynthesis from "./ProjectStepSynthesis";

// list of project steps components
export const projectStepMap = {
  ProjectStepHome: ProjectStepHome,
  ProjectStepHpchoice: ProjectStepHPChoice,
  ProjectStepResults: ProjectStepResults,
  ProjectStepAcc: ProjectStepAcc,
  ProjectStepQuote: ProjectStepQuote,
  ProjectStepSynthesis: ProjectStepSynthesis,
};