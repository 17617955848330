import React, { useState, useEffect } from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';
import { _T } from '../_i18n/i18n.helper';
import UIStore from '../store/UIStore';

import * as helpService from "./help.service";
import * as tools from "../_helpers/tools";

export default function FaqSection() {
  const [data, setData] = useState({
    faq: null,
    loading:false,
    isOpen:{}
  })

  const manageLoader = () => {
    setTimeout(() => {
      setData({
        ...data,
        loading: !data.faq
      })
    }, 100);
  };

  useEffect(() => {
    async function fetchFAQ() {
      manageLoader()

      const faq = await helpService.getFAQ();

      setData({
        ...data,
        loading: false,
        faq: faq
      })
    }

    fetchFAQ();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (i) => {
    let isOpen = { ...data.isOpen };
    isOpen[i] = !isOpen[i];

    setData({
      ...data,
      isOpen: isOpen
    })
  };

  const getHelpMsg = () => {
    return _T('NEED_MORE_HELP').replace(/([a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})/ig, '<a href="mailto:$1">$1</a>');
  };


  const lang = UIStore.fallbackLang;

  return (
    <div className="col col-12 col-md-9 contentAide">
      {_T('FAQ')} :

      <div className="faq">

        {data.loading &&
          <div>{_T('LOADING')}</div>
        }

        {data.faq && data.faq[lang] && // NOSONAR
          data.faq[lang].map((faq, i) => (
            <ul key={i} // NOSONAR
            >
              
              <li onClick={() => toggle(i)} // NOSONAR
              >
                {!data.isOpen[i] && <svg className="bi bi-chevron-compact-right" width="1em" height="1em" viewBox="0 0 16 16"
                  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd"
                    d="M6.776 1.553a.5.5 0 01.671.223l3 6a.5.5 0 010 .448l-3 6a.5.5 0 11-.894-.448L9.44 8 6.553 2.224a.5.5 0 01.223-.671z"
                    clipRule="evenodd" />
                </svg>}
                {data.isOpen[i] &&
                  <svg className="bi bi-chevron-compact-down" width="1em" height="1em" viewBox="0 0 16 16"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                      d="M1.553 6.776a.5.5 0 01.67-.223L8 9.44l5.776-2.888a.5.5 0 11.448.894l-6 3a.5.5 0 01-.448 0l-6-3a.5.5 0 01-.223-.67z"
                      clipRule="evenodd" />
                  </svg>}
                <div className="question">{faq.question}</div>
                <Collapse className="answer" isOpen={data.isOpen[i]}>
                  <Card>
                    <CardBody>
                      <div dangerouslySetInnerHTML={{ __html: tools.nl2br(faq.answer) }}></div>
                    </CardBody>
                  </Card>
                </Collapse></li>
            </ul>
          ))}

      </div>

      <div className="helpMsg" dangerouslySetInnerHTML={{ __html: getHelpMsg() }}></div>

    </div>
  )
}
