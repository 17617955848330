import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import UIStore from '../../store/UIStore';
import { _T } from '../../_i18n/i18n.helper';
import styles from './satisfaction.module.scss'
import logo_pacific_project from "../../assets/img/logo-pacific-project.png";

/**
 * @prop {bool} isOpen
 * @prop {func} no
 * @prop {func} yes
 */
const SatisfactionModal = (props) => {
  const brand = UIStore.environment_parameters.brand;

  function renderTitle() {
    if(brand === 'thermorfr') {
      return <span className={styles.row}>
              <p>Simul'home PAC air-eau</p>
            </span>
    } else if(brand === 'pacific') {
      return <span className={styles.row}>
              <p>PROJECT</p>
              <img className="logoPacificImg" src={logo_pacific_project} alt="Pacific" width="60" height="60" />
            </span>
    } else {
      return <span className={styles.row + ' ' + styles.logo}>
              <p>Proji-Pac</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 121.626 119.04"><g transform="translate(0.502 0.136)"><path d="M477.028,403.359l7.841-11.952v11.952Zm-8.751,4.875h16.5v6.9h5.432v-6.9h3.436v-4.875h-3.436V384.124h-6.049Z" transform="translate(-421.051 -309.417)" fill="#fff" stroke="#000" strokeWidth="1" fillRule="evenodd"/><path d="M458.655,335.094l-44.872,25.549v4.64h7.077v23.228s-10.336,2.614-16.533,6.96-2.349,9.28-2.349,9.28H520.03l1.268-9.28s1.081-9.016-3.618-11.629c-4.728-2.585-5.609,2.349-5.609,2.349s-.617-4.376-6.2-4.67c-3.612-.176-4.522,1.792-4.727,3.231v1.439a4.9,4.9,0,0,1,0-1.439v-19.47h9.426v-4.64l-47.221-25.549H461a33.084,33.084,0,0,0,0-11.6c-1.175-5.814-3.26-11.041-14.155-13.919-10.924-2.907-22.436,9.867-28.338,9.28s-9.456-11.6-9.456-11.6-1.468,5.491,7.077,13.92c8.575,8.4,32.772,6.372,35.415,4.639,2.672-1.732,0-5.227,4.728-4.639S458.655,335.094,458.655,335.094ZM446.85,379.2c0-8.956,12.98-29.6,16.5-29.6,1.792,0,16.533,20.645,16.533,29.6a16.52,16.52,0,0,1-33.037,0Z" transform="translate(-400.798 -286.346)" fill='#fff' stroke="#000" strokeWidth="1" fillRule="evenodd"/><path d="M493.371,309.888s-4.14-17.12,11.805-20.909c15.916-3.759,30.394-.294,35.416-2.32s7.077-9.28,7.077-9.28-1.175,31.334-18.589,40.643c-17.209,9.162-25.989,9.28-28.632,5.785-2.672-3.466,1.762-10.455,4.728-13.92,2.937-3.494,7.664-6.666,2.349-6.96s-11.8,9.28-11.8,9.28Z" transform="translate(-428.437 -277.38)" fill='#fff' stroke="#000" strokeWidth="1" fillRule="evenodd"/></g></svg>
            </span>
    }
  }

  return (
    <Modal 
      isOpen={props.isOpen} //NOSONAR
      toggle={props.toggle} //NOSONAR
      size='lg'
      className="modalSatisfaction"
    >
      <ModalBody>
        <button className="btn_close" onClick={props.no} //NOSONAR
        >X</button>

        <div className={styles.satisfactionModal}>
          <div className={styles.row}>
            <h3>{_T('THANKS_USING')}</h3>
            {renderTitle()}
          </div>
          <h4>{_T('SURVEY_REQUEST')}</h4>
          <p className={styles.text}>{_T('SURVEY_DESC')}
          </p>

          <div className={styles.actionBlock}>
            <div className="pretty p-default p-round p-pulse" onClick={props.yes} //NOSONAR
            >
              <input type="radio" name='YES_MODAL' value={'YES'} readOnly />
              <div className="state">
                <label htmlFor='YES_MODAL'>{_T('YES')}</label>
              </div>
            </div>
            <div className="pretty p-default p-round p-pulse" onClick={props.no} readOnly //NOSONAR
            >
              <input type="radio" name='NO_MODAL' value={'NO'} />
              <div className="state">
                <label htmlFor='NO_MODAL'>{_T('LATER')}</label>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
  }

export default SatisfactionModal;