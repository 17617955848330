export const emetteurZ1 = [
  { code: '1', label: 'SELECT_Z1_PLANCHER_CHAUFFANT', schemaCode: 'P', temperatures: ['30', '35'] },
  { code: '2', label: 'SELECT_Z1_PLANCHER_CHAUFFANT_RAFRAICHISSANT', schemaCode: 'P', temperatures: ['30', '35'] },
  { code: '4', label: 'SELECT_Z1_RADIATEURS', schemaCode: 'R', temperatures: ['40', '45', '50', '55', '60', '65', '70', '75'] },
  { code: '3', label: 'SELECT_Z1_RADIATEURS_DYNAMIQUES', schemaCode: 'V', temperatures: ['30', '35', '40', '45', '50'] },
]

export const emetteurZ2 = [
  { code: '1', label: 'SELECT_Z1_PLANCHER_CHAUFFANT', schemaCode: 'P', temperatures: ['30', '35'] },
  { code: '2', label: 'SELECT_Z1_PLANCHER_CHAUFFANT_RAFRAICHISSANT', schemaCode: 'P', temperatures: ['30', '35'] },
  { code: '4', label: 'SELECT_Z1_RADIATEURS', schemaCode: 'R', temperatures: ['40', '45', '50', '55', '60', '65', '70', '75'] },
  { code: '3', label: 'SELECT_Z1_RADIATEURS_DYNAMIQUES', schemaCode: 'V', temperatures: ['30', '35', '40', '45', '50'] },
  { code: '6', label: 'SELECT_Z2_CLIMATISATION', schemaCode: 'V', temperatures: [] },
  { code: '5', label: 'SELECT_Z2_RADIATEURS_ELECTRIQUES', schemaCode: 'R', temperatures: [] },
]

export function getEmetteursByZone(index) {
  return index <= 0 ? emetteurZ1 : emetteurZ2
}

export const insulationWork = [
  { code: '0', label: 'NO' },
  { code: '1', label: 'YES' },
]

export const insulation = [
  { code: '1', label: 'SELECT_WI_NO' },
  { code: '2', label: 'SELECT_WI_AVANT1988' },
  { code: '3', label: 'SELECT_WI_ENTRE_1989ET2000' },
  { code: '4', label: 'SELECT_WI_ENTRE2000ET2010' },
  { code: '5', label: 'SELECT_WI_APRES2010' },
]

export const actualSystem = [
  { code: '1', label: 'CHAUDIERE_FIOUL' },
  { code: '2', label: 'CHAUDIERE_GAZ' },
  { code: '3', label: 'CHAUDIERE_PROPANE' },
]

export const withECS = [
  { code: '1', label: 'YES' },
  { code: '0', label: 'NO' },
]

export const systemAge = [
  { code: '1', label: 'SUP_10_YEARS' },
  { code: '2', label: 'INF_10_YEARS' },
]

export const temperature = [
  { code: '15', label: '15' },
  { code: '16', label: '16' },
  { code: '17', label: '17' },
  { code: '18', label: '18' },
  { code: '19', label: '19' },
  { code: '20', label: '20' },
  { code: '21', label: '21' },
  { code: '22', label: '22' },
  { code: '23', label: '23' },
  { code: '24', label: '24' },
  { code: '25', label: '25' },
]

export const invoiceNbOccupants = [
  { code: '1', label: '1' },
  { code: '2', label: '2' },
  { code: '3', label: '3' },
  { code: '4', label: '4' },
  { code: '5', label: '5' },
  { code: '6', label: '6' },
]

export const cool_temperature = [
  { code: '24', label: '24' },
  { code: '25', label: '25' },
  { code: '26', label: '26' },
  { code: '27', label: '27' },
  { code: '28', label: '28' },
]

export const cool_nbPeoples = [
  { code: '1', label: '1' },
  { code: '2', label: '2' },
  { code: '3', label: '3' },
  { code: '4', label: '4' },
  { code: '5', label: '5' },
  { code: '6', label: '6' },
]

export const cool_inertia = [
  { code: '0', label: 'COOL_INERTIA_LIGHT' },
  { code: '1', label: 'COOL_INERTIA_AVG' },
  { code: '2', label: 'COOL_INERTIA_HEAVY' },
]

export const cool_location = [
  { code: '0', label: 'YES' },
  { code: '1', label: 'NO' },
]

export const cool_mitoyen = [
  { code: '0', label: 'NO' },
  { code: '1', label: 'YES' },
]

export const cool_orientation = [
  { code: '1', label: 'NORTH' },
  { code: '0', label: 'NORTH_WEST' },
]

export const cool_occultant = [
  { code: '0', label: 'NO' },
  { code: '1', label: 'INTERIOR' },
  { code: '2', label: 'EXTERIOR' },
]
