import React, {useState, useEffect} from 'react'
import { observer } from 'mobx-react'
import { toJS, autorun } from 'mobx'
import { _T, _THtml } from '../_i18n/i18n.helper'
import NavigationButtons from '../_components/Navigation/NavigationButtons'
import { toast } from 'react-toastify'

import manageForm from '../_components/forms/manageForm'
import Field from '../_components/forms/Field'

import UIStore from '../store/UIStore'
import ProjectStore from '../store/ProjectStore'
import formStore from './StepHPChoice/hpChoice.store'
import * as dd from './StepHPChoice/dropDown.data'

import MainMenu from '../_components/Navigation/MainMenu'
import Breadcrumb from '../_components/Navigation/Breadcrumb'

import fleche_titre_atlantic from '../assets/img/fleche-titre.svg'
import fleche_titre_thermorfr from '../assets/img/fleche-titre-thermorfr.svg'
import fleche_titre_pacific from '../assets/img/fleche-titre-pacific.svg'

const imgChevrons = {
  atlantic: fleche_titre_atlantic,
  thermorfr: fleche_titre_thermorfr,
  pacific: fleche_titre_pacific
}

const ProjectStepHPChoice = (props) => { //NOSONAR
  const [data, setData] = useState({
    typologies: dd.typologies,
    captages: dd.captages,
    utilisations: dd.utilisations,
    energies: dd.energies,
    alimentations: dd.alimentations,
    abonnements: dd.abonnementsMono,
    releveChaudiere: dd.releveChaudiere,
    anneesChaudiere: dd.anneesChaudiere,
    typesChaudiere: dd.typesChaudiere,
    energiesChaudiere: dd.energiesChaudiere,
    typesECS: dd.typesECSDefault,
    nbOccupants: dd.nbOccupants,
    configOk: false,

    hybridOnly: false
  });

  const fleche_titre = imgChevrons[UIStore.environment_parameters.brand] || fleche_titre_atlantic

  useEffect(() => { //NOSONAR
    async function fetchData() {
      if (ProjectStore.project.forms.hpChoiceStep.form) {
        formStore.form = { ...formStore.form, ...ProjectStore.project.forms.hpChoiceStep.form }
      } else {
        // Auto choose of typology based on emitter max temperature
        if (usageOfHightTempEmitter()) {
          onHeatPumpTypeChange('3', 'HYBRID');
        } else {
          onHeatPumpTypeChange('1', 'AEROTHERMAL');
        }

        if (hybridOnly()) {
          onHeatPumpTypeChange('3', 'HYBRID');
          setData(prevData => ({...prevData, hybridOnly: true}))}
      }

      checkEnergyAndUtilisation(false);

      autorun(() => {
        if (UIStore.environment_parameters.enableMonobloc) {
          setData(prevData => ({
            ...prevData,
            typologies: dd.typologiesWithMonobloc,
          }))
        }

        if (UIStore.environment_parameters.enableMultiElecSubscription) {
          setData(prevData => ({
            ...prevData,
            abonnements: dd.abonnementsMulti,
          }))
        }
      })
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usageOfHightTempEmitter = () => {
    return ProjectStore.project.deperds.Study.StudyMaximumTempTransmitter > 60
  }

  const hybridOnly = () => {
    return UIStore.isAtlantic && ProjectStore.project.deperds.Study.StudyDeperdition > 21000
  }

  /**
   * Lock "NON" choice and print warning if usageOfHightTempEmitter() with AEROTHERMAL and GEOTHERMAL
   * @param e
   */

  const onReleveChaudiereChange = (e) => {
    let { value } = e.target;
    if (value === 'sans' && formStore.form.heatPumpType !== "3" && usageOfHightTempEmitter()) {
      toast.warning(_T('BOILER_RELIEVING_EXCEPTION'))
    } else if (value === 'chaudiere' && formStore.form.heatPumpType === "3") { // NOSONAR
      toast.warning(_T('BOILER_RELIEVING_WITH_HYBRID_FORBID'))
    } else {
      props.onChange(e); // NOSONAR
    }
  }

  const onHeatPumpTypeChange = (code, label) => {
    formStore.updateHeatPumpType(code, label)
    checkEnergyAndUtilisation();
    if (code !== "3" && usageOfHightTempEmitter()) {
      formStore.form.releveChaudiere = 'chaudiere'
    } else if (code === "3") {
      formStore.form.releveChaudiere = 'sans'
    }

    props.validate('touch_no_field'); // NOSONAR
  }

  const checkEnergyAndUtilisation = (forceDefaultValue = true) => {
    let typesECS, alimentations
  
    if (formStore.form.heatPumpType === "3" && (formStore.form.energie === 'gaz' || formStore.form.energie === 'fioul')) {
      typesECS = dd.typesECSIntegree
      alimentations = dd.alimentations

      if (formStore.form.energie === 'gaz') {
        typesECS = typesECS.concat(dd.typesECSInst)
      } else if (formStore.form.energie === 'fioul' && formStore.form.utilisation === "1") {
        typesECS = typesECS.concat(dd.typesECSInst)
      }
    } else {
      typesECS = dd.typesECSDefault
      alimentations = dd.alimentations

      if(formStore.form.heatPumpType === "3" && formStore.form.energie === 'propane' && formStore.form.utilisation === "2") {
        typesECS = typesECS.concat(dd.typesECSInst)
      }
    }

    if (forceDefaultValue) {
      if (typesECS.length === 1) {
        formStore.form.typeECS = typesECS[0].code
        formStore.form.typeECS_i18nCode = typesECS[0].label
      } else {
        formStore.form.typeECS = ''
        formStore.form.typeECS_i18nCode = ''
      }
    }

    setData({
      ...data,
      typesECS,
      alimentations,
      energies: formStore.form.utilisation === '1' ? dd.energies_rt2012 : dd.energies,
    })
  }

  const onHybridVarChange = (e) => {
    props.onChange(e); // NOSONAR
    checkEnergyAndUtilisation()
  }

  const ontypeECSChange = (e) => {
    props.onChange(e); // NOSONAR

    if (formStore.form.typeECS === 'sans') {
      formStore.form.nbOccupants = ''
    } else if (ProjectStore.project.simulationCool) {
      formStore.form.nbOccupants = ProjectStore.project.forms.coolModeStep.form.nbPeoples
    }
  }

  const onnbOccupantsChange = (e) => {
    props.onChange(e); // NOSONAR

    if (ProjectStore.project.simulationCool) {
      ProjectStore.project.forms.coolModeStep.form.nbPeoples = formStore.form.nbOccupants
    }
  }

  const submit = () => {
    return props
      .validate() // NOSONAR
      .then(() => {
        ProjectStore.resetResults()
        ProjectStore.updateForm('hpChoiceStep', { form: toJS(formStore.form) })
        return Promise.resolve()
      })
      .catch((err) => {
        //toast.error(_T(err.errors.join(', '))) // NOSONAR
        toast.error(_T('SOME_FIELDS_NEED_YOUR_ATTENTION'))
        return Promise.reject(err)
      })
  }

  const enabledHeatPumpByType = (type) => {
    if (!UIStore.environment_parameters.heatPumpTypes) return true

    try {
      const heatPumpTypesArray = UIStore.environment_parameters.heatPumpTypes.split(',')
      return heatPumpTypesArray.find(hpt => hpt == type)
    } catch (ex) {
      return true
    }
  }

  const { errors, touched, onChange, onBlur } = props; // NOSONAR

  let brand = UIStore.environment_parameters.brand;

  return (
    <>
      <div className="header container-fluid">
        <MainMenu />
        <Breadcrumb handleSubmit={submit} />
      </div>

      <div className="main container-fluid hpchoice-step">
        <br />

        <form id="form_stepHome" onSubmit={(e) => e.preventDefault()}>
          {brand !== "pacific" && (
            <div className="row donneesGEO">
              <div className="col col-12 col-lg-2 titreRubrique">
                <p className={brand === "pacific" ? "pacific pacificPAC" : undefined}>{_THtml('HEATPUMP_TYPE')}</p>
                {brand !== "pacific" && <img className="chevron" src={fleche_titre} alt="" />}
              </div>

              <div className="col col-12 col-lg-10 contentRubrique">
                <div className="form">
                  <div className="row">
                    {enabledHeatPumpByType(1) && <div className="col col-12 col-lg-4 typePompe">
                      <Field
                        type="radio"
                        name={'typePompe'}
                        value={formStore.form.heatPumpType}
                        items={[{ code: '1', label: null }]}
                        onChange={() => onHeatPumpTypeChange('1', 'AEROTHERMAL')}
                        disabled={data.hybridOnly}
                      />

                      {/* Preload bg img to avoid the flash at mouse hover */}
                      <button className="preload aerothermie active">
                        <span></span>
                      </button>
                      <button className="preload aerothermie">
                        <span></span>
                      </button>
                      <button
                        className={formStore.form.heatPumpType === "1" ? 'aerothermie active' : 'aerothermie'}
                        onClick={() => onHeatPumpTypeChange('1', 'AEROTHERMAL')}
                      >
                        <span></span>
                        {_T('AEROTHERMAL')}
                      </button>
                    </div>
                    }

                    {enabledHeatPumpByType(2) && <div className="col col-12 col-lg-4 typePompe">
                      <Field
                        type="radio"
                        name={'typePompe'}
                        value={formStore.form.heatPumpType}
                        items={[{ code: '2', label: null }]}
                        onChange={() => onHeatPumpTypeChange('2', 'GEOTHERMAL')}
                        disabled={data.hybridOnly}
                      />

                      {/* Preload bg img to avoid the flash at mouse hover */}
                      <button className="preload geothermie active">
                        <span></span>
                      </button>
                      <button className="preload geothermie">
                        <span></span>
                      </button>
                      <button
                        className={formStore.form.heatPumpType === "2" ? 'geothermie active' : 'geothermie'}
                        onClick={() => onHeatPumpTypeChange('2', 'GEOTHERMAL')}
                      >
                        <span></span>
                        {_T('GEOTHERMAL')}
                      </button>
                    </div>
                    }

                    {enabledHeatPumpByType(3) && <div className="col col-12 col-lg-4 typePompe">
                      <Field
                        type="radio"
                        name={'typePompe'}
                        value={formStore.form.heatPumpType}
                        items={[{ code: '3', label: null }]}
                        onChange={() => onHeatPumpTypeChange('3', 'HYBRID')}
                      />

                      {/* Preload bg img to avoid the flash at mouse hover */}
                      <button className={
                        brand === "thermorfr" ?
                          "preload hybride active thermor"
                          :
                          brand === "pacific" ? // NOSONAR
                            "preload hybride active pacific"
                            :
                            "preload hybride active"
                      }>
                        <span></span>
                      </button>
                      <button className={
                        brand === "thermorfr" ?
                          "preload hybride active thermor"
                          :
                          brand === "pacific" ? // NOSONAR
                            "preload hybride active pacific"
                            :
                            "preload hybride active"
                      }>
                        <span></span>
                      </button>
                      <button
                        className={
                          formStore.form.heatPumpType === "3" ?
                            (brand === 'thermorfr' ? // NOSONAR
                              'hybride active thermor'
                              :
                              brand === 'pacific' ? // NOSONAR
                                'hybride active pacific'
                                :
                                'hybride active')
                            :
                            (brand === 'thermorfr' ? // NOSONAR
                              'hybride thermor'
                              :
                              brand === 'pacific' ? // NOSONAR
                                'hybride pacific'
                                :
                                'hybride')
                        }
                        onClick={() => onHeatPumpTypeChange('3', 'HYBRID')}
                      >
                        <span></span>
                        {_T('HYBRID')}
                      </button>
                    </div>
                    }
                  </div>
                  <div className="row" style={{ paddingTop: 0 }}>
                    {enabledHeatPumpByType(1) && <div className="col col-12 col-lg-4">
                      <Field
                        className="custom-select"
                        type="select"
                        name="typologie"
                        placeholder={_T('TYPOLOGY')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={formStore.form.typologie}
                        errors={errors}
                        touched={touched}
                        defaultValue={true}
                        disabled={formStore.form.heatPumpType !== "1"}
                        items={data.typologies}
                      />
                    </div>
                    }

                    {enabledHeatPumpByType(2) && <div className="col col-12 col-lg-4">
                      <Field
                        className="custom-select"
                        type="select"
                        name="captage"
                        placeholder={_T('CAPTURE_TYPE')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={formStore.form.captage}
                        errors={errors}
                        touched={touched}
                        defaultValue={true}
                        disabled={formStore.form.heatPumpType !== "2"}
                        items={data.captages}
                      />
                    </div>
                    }

                    {enabledHeatPumpByType(3) && <div className="col col-12 col-lg-4">
                      <Field
                        className="custom-select"
                        type="select"
                        name="utilisation"
                        placeholder={_T('USAGE')}
                        onChange={onChange}
                        onBlur={(e) => onHybridVarChange(e)}
                        value={formStore.form.utilisation}
                        errors={errors}
                        touched={touched}
                        defaultValue={true}
                        disabled={formStore.form.heatPumpType !== "3"}
                        items={data.utilisations}
                      />
                      <Field
                        className="custom-select"
                        type="select"
                        name="energie"
                        placeholder={_T('ENERGY')}
                        onChange={(e) => onHybridVarChange(e)}
                        onBlur={onBlur}
                        value={formStore.form.energie}
                        errors={errors}
                        touched={touched}
                        defaultValue={true}
                        disabled={formStore.form.heatPumpType !== "3"}
                        items={data.energies}
                      />
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row donneesGEO">
            <div className="col col-12 col-lg-2 titreRubrique">
              <p className={brand === "pacific" ? "pacific pacificPAC" : undefined}>{_THtml('ELECTRIC_INFOS')}</p>
              {brand !== "pacific" && <img className="chevron" src={fleche_titre} alt="" />}
            </div>

            <div className="col col-12 col-lg-10 contentRubrique">
              <div className="form">
                <div className="row">
                  <div className="col col-12 col-lg-4">
                    <Field
                      className="custom-select"
                      type="select"
                      name="alimentation"
                      placeholder={_T('POWER_SUPPLY')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={formStore.form.alimentation}
                      errors={errors}
                      touched={touched}
                      defaultValue={true}
                      items={data.alimentations}
                    />
                  </div>

                  <div className="col col-12 col-lg-4">
                    <Field
                      className="custom-select"
                      type="select"
                      name="abonnement"
                      placeholder={_T('ELECTRIC_SUBSCRIPTION')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={formStore.form.abonnement}
                      errors={errors}
                      touched={touched}
                      defaultValue={true}
                      items={data.abonnements}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row donneesGEO">
            <div className="col col-12 col-lg-2 titreRubrique">
              <p className={brand === "pacific" ? "pacific pacificPAC" : undefined}>{_THtml('HEATPUMP_SUBSTITUTION')}</p>
              {brand !== "pacific" && <img className="chevron" src={fleche_titre} alt="" />}
            </div>

            <div className="col col-12 col-lg-10 contentRubrique">
              <div className="form">
                <div className="row">
                  <div className="col col-12 col-lg-4 releveChaudiere">
                    <Field
                      className="releveChaudiereRadio"
                      type="radio"
                      name={'releveChaudiere'}
                      onChange={onReleveChaudiereChange}
                      onBlur={onBlur}
                      value={formStore.form.releveChaudiere}
                      errors={errors}
                      touched={touched}
                      disabled={formStore.form.heatPumpType === "3"}
                      items={data.releveChaudiere}
                    />
                  </div>

                  <div className="col col-12 col-lg-4 releveChaudiere">
                    <Field
                      className="custom-select"
                      type="select"
                      name="anneeChaudiere"
                      placeholder={_T('BOILER_AGE')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={formStore.form.anneeChaudiere}
                      errors={errors}
                      touched={touched}
                      defaultValue={true}
                      disabled={formStore.form.releveChaudiere !== 'chaudiere'}
                      items={data.anneesChaudiere}
                    />
                    <Field
                      className="custom-select"
                      type="select"
                      name="typeChaudiere"
                      placeholder={_T('BOILER_TYPE')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={formStore.form.typeChaudiere}
                      errors={errors}
                      touched={touched}
                      defaultValue={true}
                      disabled={formStore.form.releveChaudiere !== 'chaudiere'}
                      items={data.typesChaudiere}
                    />
                  </div>

                  <div className="col col-12 col-lg-4 releveChaudiere">
                    <Field
                      className="custom-select"
                      type="select"
                      name="energieChaudiere"
                      placeholder={_T('BOILER_ENERGY')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={formStore.form.energieChaudiere}
                      errors={errors}
                      touched={touched}
                      defaultValue={true}
                      disabled={formStore.form.releveChaudiere !== 'chaudiere'}
                      items={data.energiesChaudiere}
                    />
                    <Field
                      className="form-control"
                      type="number"
                      name="puissanceChaudiere"
                      min={1}
                      placeholder={_T('BOILER_POWER')}
                      unit="kW"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={formStore.form.puissanceChaudiere || ''}
                      disabled={formStore.form.releveChaudiere !== 'chaudiere'}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row donneesGEO">
            <div className="col col-12 col-lg-2 titreRubrique">
              <p className={brand === "pacific" ? "pacific pacificPAC" : undefined}>{_THtml('DOMESTIC_HOT_WATER')}</p>
              {brand !== "pacific" && <img className="chevron" src={fleche_titre} alt="" />}
            </div>

            <div className="col col-12 col-lg-10 contentRubrique">
              <div className="form">
                <div className="row">
                  <div className="col col-12 col-lg-4">
                    <Field
                      className="custom-select"
                      type="select"
                      name="typeECS"
                      placeholder={_T('HOT_WATER_TYPE')}
                      onChange={ontypeECSChange}
                      onBlur={onBlur}
                      value={formStore.form.typeECS}
                      errors={errors}
                      touched={touched}
                      defaultValue={true}
                      items={data.typesECS}
                    />
                  </div>

                  <div className="col col-12 col-lg-4">
                    <Field
                      className="custom-select"
                      type="select"
                      name="nbOccupants"
                      placeholder={_T('OCCUPANTS_NUMBER')}
                      onChange={onnbOccupantsChange}
                      onBlur={onBlur}
                      value={formStore.form.nbOccupants}
                      errors={errors}
                      touched={touched}
                      defaultValue={true}
                      translate={false}
                      disabled={formStore.form.typeECS === '' || formStore.form.typeECS === 'sans'}
                      items={data.nbOccupants}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <NavigationButtons handleSubmit={submit} />
      </div>
    </>
  )
}


export default manageForm(observer(ProjectStepHPChoice), formStore, 'form')
