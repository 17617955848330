import React from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';
import ProjectStore from "../../store/ProjectStore";
import numeral from 'numeral';
/**
 * Manage the zone part of HeatMode Form
 */

const HeatModeCalculatedNeeds = (props) => {
    let { showLossCoef } = props; //NOSONAR
    showLossCoef = showLossCoef == null ? true : showLossCoef;

    const className = showLossCoef ? 'col col-6 infosBesoin' : 'col col-12 infosBesoin';

    return (
      ProjectStore.project.deperds &&
      <div className="col col-12 col-lg-8  besoin">
        <p className="titreBesoin">{_T('ESTIMATED_NEED')}</p>

        <div className="row" style={{width: '100%'}}>
          { showLossCoef &&
            <div className="col col-6 infosBesoin1">
              <p>{ _T('COEFFICIENT_OF_LOSS') }</p>
              <em>{ numeral(ProjectStore.project.deperds.G).format('0,0.00') } W/m3/K</em>
            </div>
          }

          <div className={className}>
            <p>{ _T('LOSS') }</p>
            <em>{ numeral(ProjectStore.project.deperds.Deperd && ProjectStore.project.deperds.Study.StudyDeperdition.round(0)).format() } W</em>
          </div>
        </div>

        <div className="row" style={{width: '100%'}}>
          { ProjectStore.project.newMaximumTempTransmitter != null  &&
            <div className="col col-12 infosBesoin">
              <p>{ _T('NEW_START_TEMP') }</p>
              <em>{ ProjectStore.project.newMaximumTempTransmitter  } °C</em>
            </div>
          }
        </div>
      </div>
    );
  }

export default observer(HeatModeCalculatedNeeds);