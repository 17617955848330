import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import numeral from 'numeral';

import chaudiere from "../../assets/img/produits/chaudiere.jpg";
import {_T} from "../../_i18n/i18n.helper";
import ProductModalContent1 from "./ProductModalContent1";
import ProductModalContent2 from "./ProductModalContent2";
import ProductModalContent3 from "./ProductModalContent3";
import ProductModalContent4 from "./ProductModalContent4";
import ProductModalContent5 from "./ProductModalContent5";
import ProductModalContent7 from "./ProductModalContent7";
import MainMenu from "../../_components/Navigation/MainMenu";
import Breadcrumb from "../../_components/Navigation/Breadcrumb";
import ProductNavigationButtons from "../../_components/Navigation/ProductNavigationButtons";
import UIStore from "../../store/UIStore";
import * as projectService from "../project.service";
import {API_URL} from "../../config";
import projectStore from "../../store/ProjectStore";
import TagManager from 'react-gtm-module'

function ProductInfos (props) {
  const [state, setState] = useState({
    showPrevious:false,
    showNext: true,
    selectedMenu: 0,
    ContentComponent: null
  });

  const menuItems = [
    {label: _T('PRODCTMODAL_MENU_1'), component: ProductModalContent1},
    {label: _T('PRODCTMODAL_MENU_2'), component: ProductModalContent4},
    {label: _T('PRODCTMODAL_MENU_4'), component: ProductModalContent5},
    {label: _T('PRODCTMODAL_MENU_5'), component: ProductModalContent3},
    {label: _T('PRODCTMODAL_MENU_6'), component: ProductModalContent2},
    ...(projectStore.project.simulationCool ? [{label: _T('PRODCTMODAL_MENU_7'), component: ProductModalContent7}] : [])
  ];

  useEffect(() => {
    // if(projectStore.project.simulationCool) {
    //   menuItems.push({label: _T('PRODCTMODAL_MENU_7'), component: ProductModalContent7});
    // }

    window.scrollTo(0,0);
    if(!UIStore.lastShowProduct) {
      const { history } = props; // NOSONAR
      history.push(`/${UIStore.env}/project/results`); // NOSONAR
    }
    loadContent(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Load a component by his index in menuItems array
   * @param index
   */
  const loadContent = (index) => {
    if(index < 0) index = menuItems.length - 1;
    if(index > menuItems.length - 1) index = 0;

    setState({
      showPrevious:true,
      showNext: true,
      selectedMenu: index,
      ContentComponent: menuItems[index].component
    })
  };

  const previous = () => {
    loadContent(state.selectedMenu - 1);
  };

  const next = () => {
    loadContent(state.selectedMenu + 1);
  };

  const select = () => {
    projectService.selectProduct(UIStore.lastShowProduct);
    props.history.goBack(); // NOSONAR
  };

    let data = UIStore.lastShowProduct;

    if(!data) return null;

    const brand = UIStore.environment_parameters.brand;

    const tagManagerArgs = {
      dataLayer: {
        'event': 'datalayer-ready',
        'page':{
          'arbo1': 'produit',
          'arbo2':null,
          'arbo3':null,
          'arbo4':null,
          'taxonomy1':'simulateur',
          'product_taxonomy1':'PAC AIR/EAU',
          'product_taxonomy2':'PAC INDIVIDUELLE',
          'product_taxonomy3':null,
          'product_taxonomy4':null,
          'product_taxonomy5':null,
          'product_taxonomy6':null
          },
        'user':{
          'id': UIStore.user.id,
          'type':null,
          'company': null,
          'siret': UIStore.user.siret
          }
      },
      dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

  return (
    <>
      <div className="header container-fluid">
        <MainMenu />
        <Breadcrumb />
      </div>

      <div className="main container-fluid">
        <div className="row barreProduit">
          <div className="col col-12 col-md-12 col-lg-5  produitLibelle">
            <p>{ data.product.reference }</p>
          </div>

          <div className={brand === "pacific" ? "col col-12 col-md-6 col-lg-3 produitCode produitCodePacific" : "col col-12 col-md-6 col-lg-3 produitCode"}>
            <p>{_T('ARTICLE_CODE')} : <span>{ data.product.code_env }</span></p>
          </div>

          <div className="col col-12 col-md-6 col-lg-4 produitPrix">
          {!UIStore.environment_parameters.hidePrice && <p>{_T('PRICE')} : <span>{ numeral(data.product.price).format() }</span>{UIStore.environment_parameters.currency} {_T('HT')}</p>}
          <button className="btn_close" onClick={props.history.goBack}>X</button> {/*NOSONAR*/}
          
          </div>
        </div>

        <div className="row contentProduit">
          <div className="col col-12 col-lg-3 sidebar">
            <nav className="produitMenu">
              <ul>
                { menuItems.map((item, index) => (
                  <li key={index} onClick={() => loadContent(index)} className={index === state.selectedMenu ? 'active' : ''}><span>{item.label}</span></li> // NOSONAR
                ))}
              </ul>
            </nav>

            <div className="imgProduit">
              { data.product.classe_energie && <img className="pictoNRJ" src={`${API_URL}/assets/data/pictos_nrj/${data.product.classe_energie}.png`} alt="" /> }
              <img src={data.product.photo || chaudiere} alt="" />
            </div>
          </div>

          <div className="col col-12 col-lg-9 leContenu">
            { state.ContentComponent && <state.ContentComponent product={data} /> }
          </div>
        </div>
      </div>

      <ProductNavigationButtons 
        // handleSubmit={submit}
        hidePrev={!state.showPrevious} 
        hideNext={!state.showNext}
        previous={previous} 
        next={next} 
        select={select} 
      />
    </>
  )
}

export default  withRouter(observer(ProductInfos));