import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';
import {CHART_COLOR_2, CHART_BARCHART_OPTIONS} from '../../configApp';
import UIStore from "../../store/UIStore";
import projectStore from "../../store/ProjectStore";
import numeral from 'numeral';

const ProductModalContent3 = (props) => {
  const { product } = props; // NOSONAR
  const [, setWindowSize] = useState(null); // NOSONAR
  // const [modalIsOpen, setModalIsOpen] = useState(false);

  /*
      ElecDHWCost: 0
      ElecDHWKWH: 0
      ElecHeatingCost: 1079
      ElecHeatingKWH: 7050
      ElectDHWCO2: 0
      ElectHeatingCO2: 1269
      GasDHWCO2: 0
      GasDHWCost: 0
      GasDHWKWH: 0
      GasHeatingCO2: 1953
      GasHeatingCost: 611
      GasHeatingKWH: 9481
      HPDHWCO2: 0
      HPDHWCost: 0
      HPDHWKWH: 0
      HPHeatingCO2: 322
      HPHeatingCost: 274
      HPHeatingKWH: 1790
      OilDHWCO2: 0
      OilDHWCost: 0
      OilDHWKHW: 0
      OilHeatingCO2: 2615
      OilHeatingCost: 926
      OilHeatingKHW: 9685
      PropaneDHWCO2: 0
      PropaneDHWCost: 0
      PropaneDHWKWH: 0
      PropaneHeatingCO2: 2180
      PropaneHeatingCost: 1425
      PropaneHeatingKWH: 9478
      RAFHPCO2: 0
      RAFHPCost: 0
      RAFHPKWH: 0
     */

  const barChartOptions = {...CHART_BARCHART_OPTIONS, ...{colors: CHART_COLOR_2}};

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Fix a bug on smartphone to adapt first chart to screen size
    setTimeout(() => {
      setWindowSize(window.innerWidth);
    }, 100)
  }, []);

  const handleResize = e => {
    delete barChartOptions.animation;
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [handleResize]);

  const drawChartConso = (conso) => {

    let chartData = [
      projectStore.project.simulationCool
        ? [_T('ENERGY'), _T('HEATING'), _T('HOT_WATER'), _T('RAF'), { role: 'annotation', type: "number" } ]
        : [_T('ENERGY'), _T('HEATING'), _T('HOT_WATER'), { role: 'annotation', type: "number" } ]
    ];

    chartData.push(projectStore.project.simulationCool
      ? [_T('HEATPUMP'), conso.HPHeatingKWH, conso.HPDHWKWH, conso.RAFHPKWH, conso.HPHeatingKWH + conso.HPDHWKWH + conso.RAFHPKWH]
      : [_T('HEATPUMP'), conso.HPHeatingKWH, conso.HPDHWKWH, conso.HPHeatingKWH + conso.HPDHWKWH]);
    chartData.push(projectStore.project.simulationCool
      ? [_T('GAS'), conso.GasHeatingKWH, conso.GasDHWKWH, 0, conso.GasHeatingKWH + conso.GasDHWKWH]
      : [_T('GAS'), conso.GasHeatingKWH, conso.GasDHWKWH, conso.GasHeatingKWH + conso.GasDHWKWH]);
    chartData.push(projectStore.project.simulationCool
      ? [_T('OIL'), conso.OilHeatingKHW, conso.OilDHWKHW, 0, conso.OilHeatingKHW + conso.OilDHWKHW]
      : [_T('OIL'), conso.OilHeatingKHW, conso.OilDHWKHW, conso.OilHeatingKHW + conso.OilDHWKHW]);
    chartData.push(projectStore.project.simulationCool
      ? [_T('PROPANE'), conso.PropaneHeatingKWH, conso.PropaneDHWKWH, 0, conso.PropaneHeatingKWH + conso.PropaneDHWKWH]
      : [_T('PROPANE'), conso.PropaneHeatingKWH, conso.PropaneDHWKWH, conso.PropaneHeatingKWH + conso.PropaneDHWKWH]);

    const data = new window.google.visualization.arrayToDataTable(chartData);

    const chart = new window.google.visualization.ColumnChart(document.getElementById('chartConso'));

    // find v-axis max value
    let vAxisMax = null;
    for (let i = 1; i < data.getNumberOfColumns(); i++) {
      let range = data.getColumnRange(i);
      vAxisMax = Math.max(vAxisMax || range.max, range.max);
    }
    // add buffer for annotation
    vAxisMax *=1.2;

    chart.draw(data, {...barChartOptions, ...{
      title: _T('BARCHART_CONSO_AN_TITLE'),
      vAxis: {
        title: _T('CONSUMED_ENERGY'),
        titleTextStyle: { italic: false },
        viewWindow: {
          max: vAxisMax
        }
      }
    }});
  };

  const drawChartCost = (conso) => {

    let chartData = [
      projectStore.project.simulationCool
        ? [_T('ENERGY'), _T('HEATING'), _T('HOT_WATER'), _T('RAF'), { role: 'annotation' } ]
        : [_T('ENERGY'), _T('HEATING'), _T('HOT_WATER'), { role: 'annotation' } ]
      //['PAC', 10, 24, 20, ''],
    ];

    chartData.push(projectStore.project.simulationCool
      ? [_T('HEATPUMP'), conso.HPHeatingCost, conso.HPDHWCost, conso.RAFHPCost, conso.HPHeatingCost + conso.HPDHWCost + conso.RAFHPCost]
      : [_T('HEATPUMP'), conso.HPHeatingCost, conso.HPDHWCost, conso.HPHeatingCost + conso.HPDHWCost]);
    chartData.push(projectStore.project.simulationCool
      ? [_T('GAS'), conso.GasHeatingCost, conso.GasDHWCost, 0, conso.GasHeatingCost + conso.GasDHWCost]
      : [_T('GAS'), conso.GasHeatingCost, conso.GasDHWCost, conso.GasHeatingCost + conso.GasDHWCost]);
    chartData.push(projectStore.project.simulationCool
      ? [_T('OIL'), conso.OilHeatingCost, conso.OilDHWCost, 0, conso.OilHeatingCost + conso.OilDHWCost]
      : [_T('OIL'), conso.OilHeatingCost, conso.OilDHWCost, conso.OilHeatingCost + conso.OilDHWCost]);
    chartData.push(projectStore.project.simulationCool
      ? [_T('PROPANE'), conso.PropaneHeatingCost, conso.PropaneDHWCost, 0, conso.PropaneHeatingCost + conso.PropaneDHWCost]
      : [_T('PROPANE'), conso.PropaneHeatingCost, conso.PropaneDHWCost, conso.PropaneHeatingCost + conso.PropaneDHWCost]);

    const data = new window.google.visualization.arrayToDataTable(chartData);

    const chart = new window.google.visualization.ColumnChart(document.getElementById('chartCost'));

    // find v-axis max value
    let vAxisMax = null;
    for (let i = 1; i < data.getNumberOfColumns(); i++) {
      let range = data.getColumnRange(i);
      vAxisMax = Math.max(vAxisMax || range.max, range.max);
    }
    // add buffer for annotation
    vAxisMax *=1.2;

    chart.draw(data, {...barChartOptions, ...{
        title: _T('BARCHART_COST_AN_TITLE', {currency: UIStore.environment_parameters.currency}),
        vAxis: {
          title: _T('ANNUAL_COST', {currency: UIStore.environment_parameters.currency}),
          titleTextStyle: { italic: false },
          viewWindow: {
            max: vAxisMax
          }
        }
      }
    });
  };

  const drawChartCo2 = (conso) => {
    let chartData = [
      projectStore.project.simulationCool
        ? [_T('ENERGY'), _T('HEATING'), _T('HOT_WATER'), _T('RAF'), { role: 'annotation' } ]
        : [_T('ENERGY'), _T('HEATING'), _T('HOT_WATER'), { role: 'annotation' } ]
      //['PAC', 10, 24, 20, ''],
    ];

    chartData.push(projectStore.project.simulationCool
      ? [_T('HEATPUMP'), conso.HPHeatingCO2, conso.HPDHWCO2, conso.RAFHPCO2,  conso.HPHeatingCO2 + conso.HPDHWCO2 + conso.RAFHPCO2]
      : [_T('HEATPUMP'), conso.HPHeatingCO2, conso.HPDHWCO2, conso.HPHeatingCO2 + conso.HPDHWCO2]);
    chartData.push(projectStore.project.simulationCool
      ? [_T('GAS'), conso.GasHeatingCO2, conso.GasDHWCO2, 0, conso.GasHeatingCO2 + conso.GasDHWCO2]
      : [_T('GAS'), conso.GasHeatingCO2, conso.GasDHWCO2, conso.GasHeatingCO2 + conso.GasDHWCO2]);
    chartData.push(projectStore.project.simulationCool
      ? [_T('OIL'), conso.OilHeatingCO2, conso.OilDHWCO2, 0,conso.OilHeatingCO2 + conso.OilDHWCO2]
      : [_T('OIL'), conso.OilHeatingCO2, conso.OilDHWCO2, conso.OilHeatingCO2 + conso.OilDHWCO2]);
    chartData.push(projectStore.project.simulationCool
      ? [_T('PROPANE'), conso.PropaneHeatingCO2, conso.PropaneDHWCO2, 0, conso.PropaneHeatingCO2 + conso.PropaneDHWCO2]
      : [_T('PROPANE'), conso.PropaneHeatingCO2, conso.PropaneDHWCO2, conso.PropaneHeatingCO2 + conso.PropaneDHWCO2]);

    const data = new window.google.visualization.arrayToDataTable(chartData);

    const chart = new window.google.visualization.ColumnChart(document.getElementById('chartCo2'));

    // find v-axis max value
    let vAxisMax = null;
    for (let i = 1; i < data.getNumberOfColumns(); i++) {
      let range = data.getColumnRange(i);
      vAxisMax = Math.max(vAxisMax || range.max, range.max);
    }
    // add buffer for annotation
    vAxisMax *=1.2;

    chart.draw(data, {...barChartOptions, ...{
        title: _T('BARCHART_CO2_AN_TITLE'),
        vAxis: {
          title: _T('CO2_EMISSIONS', {an: _T('YEAR_UNIT')}),
          titleTextStyle: { italic: false },
          viewWindow: {
            max: vAxisMax
          }
        }
      }
    });
  };

  // const toggle = () => { // NOSONAR
  //   setModalIsOpen(!modalIsOpen) // NOSONAR
  // } // NOSONAR

  // google.charts.load('current', {packages: ['corechart'], language: UIStore.lang}) // NOSONAR
  // google.charts.setOnLoadCallback(() => drawChartConso(product.E4)) // NOSONAR
  // google.charts.setOnLoadCallback(() => drawChartCost(product.E4)) // NOSONAR
  // google.charts.setOnLoadCallback(() => drawChartCo2(product.E4)) // NOSONAR

  useEffect(() => {
    drawChartConso(product.E4); // NOSONAR
    drawChartCost(product.E4); // NOSONAR
    drawChartCo2(product.E4); // NOSONAR
  }, []);

  return (
    <>
      <div className="row chartRow">
        <div className="col col-12 col-md-12 col-lg-12">
          <div className="chart" id="chartCost" />
        </div>
      </div>
      <div className="row chartRow">
        <div className="col col-12 col-md-12 col-lg-12">
          <div className="chart" id="chartConso" />
        </div>
      </div>
      <div className="row chartRow">
        <div className="col col-12 col-md-12 col-lg-12">
          <div className="chart" id="chartCo2" />
        </div>
      </div>

      {projectStore.isHybride && 
      <div className="note">
        <div>
          {_T('CO2_EMISSIONS')} = {numeral(projectStore.value('results.E1[0].CO2_kwh')).format('0,0')}&nbsp; {_T('CO2_UNIT')}
        </div>
        <div>
          {_T('COMMENT_EMISSIONS_CO2')}
        </div>
        </div>
      }
    </>
  );
}

export default  observer(ProductModalContent3);