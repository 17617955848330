import {makeObservable, observable, action } from 'mobx';
import UIStore from '../store/UIStore'
import {post} from '../_services/api.service';
import * as Yup from 'yup';

class satisfactionStore { //NOSONAR

  formValidationScheme = Yup.object().shape(
    {
      accessFacility: Yup.string().required(),
      testRadio: Yup.string().required(),
      testTextarea: Yup.string().required(),
    }
  );
  
  form = {
    ACCESS_FACILITY: 0,
    ERGONOMY: 0,
    SIMPLICITY: 0,
    INTUITIVENESS: 0,
    FIABILITY: 0,
    RAPIDITY: 0,
    LISIBILITY: 0,
    INFORMATIONS: 0,
    DESIGN: 0,
    TOOL_GIVE_HELP_SATISFACTION: '1',
    GIVE_IT_HELP_SATISFACTION: '1',
    GIVE_IT_HELP_PLUS_SATISFACTION: '',
    APP_IS_HELPFULL: '2',
    SUGGESTION: '',
  };
  
  construcctor() {
    makeObservable(this, {
      form: observable,
      send: action
    });
  }
  async send() {
    return (await post('satisfaction/' + UIStore.environnement_id, this.form))
  }
}

//const sat = new satisfactionStore()

export default new satisfactionStore(); //NOSONAR