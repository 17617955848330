/**
 * Application configuration
 */

export const CHART_COLOR = ['#52A5AD', '#F98358', '#E43201', '#60aff4', '#0033cc'];
export const CHART_COLOR_2 = ['#52A5AD', '#F98358', '#60aff4'];
export const CHART_COLOR_3 = ['#70ad48', '#e2f0d9', '#ed7d31', '#ffc82b', '#000000'];
export const CHART_COLOR_3b = ['#70ad48', '#e2f0d9', '#000000'];
export const DARKEN_CHART_COLOR = ['#448c8e', '#F98358', '#E43201', '#0a599f'];
export const CHART_FONTSIZE = 11;

export const CHART_BARCHART_OPTIONS = {
  legend: {position: 'top', maxLines: 3},
  bar: { groupWidth: '75%' },
  isStacked: true,
  backgroundColor: { fill:'transparent' },
  colors: CHART_COLOR,
  fontSize: CHART_FONTSIZE,
  chartArea: {'width': '70%', 'height': '75%'},
  animation: {
    duration: 1000,
    easing: 'out',
    startup: true
  },
  annotations: {
    alwaysOutside: true,
    textStyle: {
      bold: true,
      italic: false,
      // The color of the text.
      color: '#000000',
      // The color of the text outline.
      auraColor: '#ffffff',
    }
  }
};

export const CHART_PIECHART_OPTIONS  = {
  backgroundColor: { fill:'transparent' },
  pieHole: 0.4,
  colors: CHART_COLOR,
  fontSize: CHART_FONTSIZE,
  legend: { position: 'none' },
  chartArea: {'width': '85%', 'height': '85%'},
  animation: {
    duration: 4000,
    easing: 'out',
    startup: true
  },
  pieSliceText: 'value',
  pieSliceTextStyle: {
    color: 'black',
  },
};

export const CHART_LINECHART_OPTIONS = {
  legend: {position: 'top', maxLines: 3},
  backgroundColor: { fill:'transparent' },
  colors: CHART_COLOR,
  fontSize: CHART_FONTSIZE,
  chartArea: {'width': '75%', 'height': '75%'},
  animation: {
    duration: 1000,
    easing: 'out',
    startup: true
  },
  annotations: {
    textStyle: {
      color: '#000000',
    }
  },
};

export const DEFAULT_ROUTE = "/";

// Breadcrumb item configuration
const BREADCRUMB_ITEMS = [
  {
    id: "home",
    params: "heat",
    index: 0,
    label: 'BREADCRUMB_HOME',
  },
  {
    id: "hpchoice",
    index: 1,
    label: 'BREADCRUMB_HP_CHOICE'
  },
  {
    id: "results",
    index: 2,
    label: 'BREADCRUMB_RESULTS'
  },
  {
    id: "acc",
    index: 3,
    label: 'BREADCRUMB_ACCESSORIES'
  },
  {
    id: "quote",
    index: 4,
    label: 'BREADCRUMB_QUOTE'
  },
  {
    id: "synthesis",
    index: 5,
    label: 'BREADCRUMB_SYNTHESIS'
  },
];
export const getBreadcrumbItems = (disabledSteps) => {
  if(!disabledSteps) return BREADCRUMB_ITEMS

  try {
    const disabledStepsArray = disabledSteps.split(',')
    return BREADCRUMB_ITEMS.filter(i => !disabledStepsArray.find(ds => ds === i.id))
  } catch(ex) {
    return BREADCRUMB_ITEMS
  }
}

const SYNTHESIS_PAGES = [
  {
    id: "200_300",
    label: "PROJECT",//"Étude",
  },
  {
    id: "1000",
    label: "QUOTATION",//"Devis",
    pageId: 'quote'
  },
  {
    id: "750",
    label: "HYDRAULIC_SCHEME",//"Schéma hydraulique",
  },
  {
    id: "600_650",
    label: "CONSUMPTION_ESTIMATE",//"Estimatif des consommations",
    labelColdMode: "CONSUMPTION_ESTIMATE_COLDMODE",//"Estimatif des consommations Froid",
  },
  {
    id: "700",
    label: "ACCESSORY_INFORMATION", //"Informations accessoires",
  },
  {
    id: "500_550",
    label: "ENERGY_COMPARISON",//"Comparatif des énergies",
  },
  {
    id: "800",
    label: "TAX_ADVANTAGE",//"Avantages fiscaux et aides",
  }
];
export const getSynthesisPages = (disabledSteps) => {
  if(!disabledSteps) return SYNTHESIS_PAGES

  try {
    const disabledStepsArray = disabledSteps.split(',')
    return SYNTHESIS_PAGES.filter(p => !p.pageId || !disabledStepsArray.find(ds => ds === p.pageId))
  } catch(ex) {
    return SYNTHESIS_PAGES
  }
}