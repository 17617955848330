import React from 'react';
import styles from './rate.module.scss'

// const EmptyStar = (props) => (
//   <span style={{cursor: 'pointer'}} {...props}>
//       <svg width='35' height='35' xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
//         <g color='#A3A3A3'>
//           <path fill="currentColor" d="M 22.847656 12.578125 L 35 14.269531 L 26.15625 22.835938 L 28.3125 35 L 17.5 29.171875 L 6.683594 35 L 8.84375 22.835938 L 0 14.269531 L 12.148438 12.578125 L 17.5 1.457031 Z M 13.121094 13.929688 L 3.179688 15.3125 L 10.417969 22.320312 L 8.648438 32.273438 L 17.5 27.503906 L 26.347656 32.273438 L 24.582031 22.320312 L 31.820312 15.3125 L 21.875 13.929688 L 17.5 4.828125 Z M 13.121094 13.929688" />
//         </g>
//       </svg>
//     </span>
// )

// const FilledStar = (props) => (
// <span style={{cursor: 'pointer'}} {...props}>
//     <svg {...props} width='35' height='35' xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
//       <g color='#F47E60'>
//         <path fill="currentColor" d="M 17.5 0.855469 L 22.847656 11.894531 L 35 13.570312 L 26.15625 22.070312 L 28.316406 34.144531 L 17.5 28.359375 L 6.683594 34.144531 L 8.84375 22.070312 L 0 13.570312 L 12.152344 11.894531 Z M 17.5 0.855469 " />
//       </g>
//     </svg>
//   </span>
// )

const DisabledStar = (props) => (
  <span>
    <svg width='35' height='35' xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
      <g color='#B0B0B0'>
        <path fill="currentColor" d="M 17.5 0.855469 L 22.847656 11.894531 L 35 13.570312 L 26.15625 22.070312 L 28.316406 34.144531 L 17.5 28.359375 L 6.683594 34.144531 L 8.84375 22.070312 L 0 13.570312 L 12.152344 11.894531 Z M 17.5 0.855469 " />
      </g>
    </svg>
  </span>
)

/**
 * 
 */
function Rater(props) {
  const { value, disabled, name } = props; //NOSONAR

  const onChange = (id) => {
    props.onChange({target:{ name: name, value: id, type: 'rater'}}); //NOSONAR
  }

  return (
    <div className={styles.rate}>
      {disabled ?
        <>
          <DisabledStar />
          <DisabledStar />
          <DisabledStar />
          <DisabledStar />
          <DisabledStar />
        </>
      :
        <>
          <span className={`${styles.star} ${value >= 1 && styles.starActive}`} onClick={() => onChange(1)} //NOSONAR
          />
          <span className={`${styles.star} ${value >= 2 && styles.starActive}`} onClick={() => onChange(2)} //NOSONAR
          />
          <span className={`${styles.star} ${value >= 3 && styles.starActive}`} onClick={() => onChange(3)} //NOSONAR
          />
          <span className={`${styles.star} ${value >= 4 && styles.starActive}`} onClick={() => onChange(4)} //NOSONAR
          />
          <span className={`${styles.star} ${value >= 5 && styles.starActive}`} onClick={() => onChange(5)} //NOSONAR
          />
        </>
      }
    </div>
  )
  
}

export default Rater;