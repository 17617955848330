import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import Field from '../_components/forms/Field'

import { _T } from '../_i18n/i18n.helper'
import { toast } from 'react-toastify'
import * as userService from '../User/auth.service'

const ForgotPwdModal = ({ modalIsOpen, close }) => { // NOSONAR
  const [email, setEmail] = useState('');

  const handleOk = async () => {
    if (email && email !== '') {
      try {
        await userService.forgotPassword(email)
        toast.success(_T('FORGOT_PWD_MODAL_SUCCESS'))
        handleCancel()
      } catch (ex) {
        toast.error(_T('FORGOT_PWD_MODAL_ERROR'))
      }
    } else {
      toast.warning(_T('FORGOT_PWD_MODAL_WARNING'))
    }
  }

  const handleCancel = () => {
    setEmail('')
    close()
  }

  return (
    <Modal isOpen={modalIsOpen} size="lg" className="modalSaveProject">
      <ModalBody>
        <button className="btn_close" onClick={close}>
          X
        </button>
        <div>
          <div className="row">
            <p>{_T('FORGOT_PWD_MODAL_TITLE')}</p>
          </div>
          <div className="row">{_T('FORGOT_PWD_MODAL_TEXT')}</div>
          <div className="row">
            <Field
              className="form-control"
              type="email"
              name="projectName"
              placeholder={_T('EMAIL')}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn enregistrer" onClick={handleOk}>
          {_T('SUBMIT')}
        </button>
        <button className="btn cancel" onClick={close}>
          {_T('CANCEL')}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default ForgotPwdModal
