import React from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';
import ProjectStore from '../../store/ProjectStore';
import numeral from 'numeral';

const ColdResults = () => {
  return (
    <div className="col col-12 col-lg-4 bilan">
      <p className="titrage">{_T('ESTIMATED_COLD_BALANCE')}</p>
      <p>{_T('TOTAL_RAF_SURFACE')} :<strong>{numeral(ProjectStore.value('results.E1[0].SurfaceRAF')).format('0,0')}&nbsp;m2</strong></p>
      <p>{_T('MAX_EMITTER_POWER')} :<strong>{numeral(ProjectStore.value('results.E1[0].ColdMaxPowerTransmitters')).format('0,0')}&nbsp;W</strong></p>
    </div>
  );
}

export default  observer(ColdResults);