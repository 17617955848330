import React from 'react';
import { observer } from 'mobx-react';

import { _T } from '../_i18n/i18n.helper';
import MainMenu from "../_components/Navigation/MainMenu";
import { toast } from 'react-toastify'
import Breadcrumb from "../_components/Navigation/Breadcrumb";
import manageForm from "../_components/forms/manageForm";
import formStore from "./form.store";
import Field from "../_components/forms/Field";
import NavigationButtons from "../_components/Navigation/NavigationButtons";

import RatesSatisfaction from './_components/rates';
import FormElement from './_components/formElement';
import satisfaction from '../assets/img/satisfaction.png';
import UIStore from "../store/UIStore";

/**
 * Satisfaction Page
 */
const SatisfactionPage = (props) => {
  const { touched, onChange, onBlur, history } = props; //NOSONAR
  const goBAcktoSynthesisStep = UIStore.satisfactionGoBack;

  const save = async () => {
    formStore.send()
      .then(() => {
        localStorage.setItem('satisfactionLastDemand', 'filled');
        toast.success(_T('SATISFACTION_SEND_SUCCESS'));

        if(goBAcktoSynthesisStep) {
          UIStore.setSatisfactionGoBack(false);
          history.push(`/${UIStore.env}/project/synthesis`)
        } else {
          history.push(`/${UIStore.env}/home`)
        }
      })
      .catch(() => {
        toast.error(_T('SATISFACTION_SEND_ERROR'));
      })
  };

  const brand = UIStore.environment_parameters.brand;

  return (
    <div className="pageSatisfaction">
      <div className="header container-fluid withoutBreadcrumb">
        <MainMenu />
        <Breadcrumb title={_T('SATISFACTION_TITLE')} />
      </div>

      <div className="content main container-fluid">
        <div className="row">
          <div className={`col col-12 ${brand === 'pacific' ? 'col-lg-12' : 'col-lg-7'}`}>
            <h2>{_T('THANKS_USING')}</h2>
            <p className="description">{_T('DESCRIPTION_SATISFACTION')}</p>

            <RatesSatisfaction {...props} />
            <FormElement
              name='TOOL_GIVE_HELP_SATISFACTION'
              type='radio'
              items={[
                { code: '1', label: 'YES' },
                { code: '0', label: 'NO' },
              ]}
              {...props}
            />
            <FormElement
              name='GIVE_IT_HELP_SATISFACTION'
              type='radio'
              items={[
                { code: '1', label: 'YES' },
                { code: '0', label: 'NO' },
              ]}
              {...props}
            />
            <Field
              name='GIVE_IT_HELP_PLUS_SATISFACTION'
              placeholder={_T('GIVE_IT_HELP_PLACEHOLDER')}
              type='textarea'
              value={formStore.form.GIVE_IT_HELP_PLUS_SATISFACTION}
              onChange={onChange} onBlur={onBlur} touched={touched}
              {...props}
            />
            <FormElement
              name='APP_IS_HELPFULL'
              type='radio'
              value={formStore.form.APP_IS_HELPFULL}
              onChange={onChange}
              items={[
                { code: '0', label: 'VERY_NOT_SATISFYING' },
                { code: '1', label: 'NOT_SATISFYING' },
                { code: '2', label: 'SATISFYING' },
                { code: '3', label: 'VERY_SATISFYING' },
              ]}
              {...props}
            />
            <FormElement
              name='SUGGESTION'
              type='textarea'
              placeholder={_T('SUGGESTION_PLACEHOLDER')}
              value={formStore.form.SUGGESTION}
              onChange={onChange} onBlur={onBlur} touched={touched}
              {...props}
            />
          </div>
          {brand !== 'pacific' && <div className="col col-12 col-lg-5">
            <img className="visuel" src={satisfaction} alt='satisfaction' />
          </div>}
        </div>
      </div>

      <NavigationButtons handleSave={save} saveText={_T('SEND')} hidePrev={true} hideNext={true} />
    </div>
  )
}


export default manageForm(observer(SatisfactionPage), formStore, 'form');