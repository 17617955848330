import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import UIStore  from '../store/UIStore';
import MaintenancePage from '../Maintenance/MaintenancePage';

/**
 * If not authenticated redirect to Home else Show Component
 * @param Component
 * @param auth
 * @param rest
 * @returns {*}
 * @constructor
 */
export const PrivateRoute = ({ component: Component, auth, maintenanceMode, ...rest }) => ( //NOSONAR
    <Route {...rest} render={props => (
        maintenanceMode
        ? <MaintenancePage /> //NOSONAR
        : (
            auth //NOSONAR
            ? <Component {...props} /> //NOSONAR
            : <Redirect to={{pathname: `/${UIStore.env}/login`, state: {from: props.location}}} //NOSONAR
            />
        )
    )} />
)
