import React, { useEffect, useState } from 'react'
import { _T } from '../_i18n/i18n.helper'

import * as helpService from './help.service'
import UIStore from '../store/UIStore'
import uuid from 'uuid';

export default function ChangelogSection(props) {
  const [versions, setVersions] = useState(null);
  const [loading, setLoading] = useState(false);

  const manageLoader = () => {
    setTimeout(() => {
      setLoading(!versions)
    }, 100)
  }

  useEffect(() => {
    async function fetchVersion() {
      manageLoader()

      const version = await helpService.getVersions();

      setVersions(version);
      setLoading(false);
    }
    fetchVersion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lang = UIStore.fallbackLang;

  return (
    <div className="col col-12 col-md-9 contentAide">
      <div>{UIStore.isThermor ? "SIMUL'HOME" : 'PROJIPAC4'}</div>
      <div>{_T('IMPROVEMENT')}</div>

      <div className="changelog">
        {loading && <div>{_T('LOADING')}</div>}

        { versions && // NOSONAR
          versions[lang] &&
          versions[lang].map((version, i) => (
            <div key={uuid.v4()}>
              <div className="version">{version.version}</div>
              <ul>{version.text?.map((text, j) => <li key={uuid.v4()}>{text}</li>)}</ul>
            </div>
          ))}
      </div>
    </div>
  )
}

