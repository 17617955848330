import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';

import { _T } from '../_i18n/i18n.helper';
import * as projectService from "./project.service";
import ProjectStore from "../store/ProjectStore";
import UISTore from "../store/UIStore";

/**
 * Project page
 */

const LoadProjectPage = (props) => {
  useEffect(() => {
    async function fetchLoad() {
      const { history } = props; // NOSONAR
      const { id, uid } = props.match.params; // NOSONAR

      try {
        await projectService.load({ id, uid });
        ProjectStore.goToStep(ProjectStore.project.currentStep, history);
        toast.info(_T('PROJECT_LOADED'), {
          position: "top-right",
          autoClose: false,
          draggable: true
        });
      } catch (e) {
        history.push(`/${UISTore.env}/home/`); // NOSONAR
      }
    }
    
    fetchLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {_T('LOADING')}
    </>
  );
}

export default observer(LoadProjectPage);