import React from 'react';
import {UncontrolledPopover, PopoverHeader, PopoverBody} from 'reactstrap';

import styles from './helpPopover.module.scss';
/**
 * @param {string} title (optional)
 * @param {Element} targetId element who displays popover when clicked
 * @param {Elements} children
 */
function HelpPophover(props) {
  const { targetId, title, children } = props; //NOSONAR

  return(
    <UncontrolledPopover trigger='legacy' placement='top-start' target={targetId}>
      {
        title && <PopoverHeader>{title}</PopoverHeader> //NOSONAR
      }
      <PopoverBody>
        {children}
      </PopoverBody>
    </UncontrolledPopover>
  )
}


export default HelpPophover;
