import * as apiService from "../_services/api.service";

/**
 * Get changelog
 * @returns {Promise<boolean>}
 */
export async function getVersions () {
  const response = await apiService.get('versions');

  let changelog = {
    fr: [],
    en: []
  };

  if(response && response.length > 0) {
    response.forEach(item => {
      item.fr = item.fr?.split('\n');
      item.en = item.en?.split('\n');

      changelog.fr.push({version: item.version, text: item.fr});
      changelog.en.push({version: item.version, text: item.en});
    });
  }

  return changelog;
}


/**
 * Get FAQ
 * @returns {Promise<boolean>}
 */
export async function getFAQ () {
  const response = await apiService.get('faq');
  let faq = {
    fr: [],
    en: []
  };

  if(response && response.length > 0){
    response.forEach(r => {
      faq.fr.push({question: r.question_fr, answer: r.answer_fr});
      faq.en.push({question: r.question_en, answer: r.answer_en});
    })
  }

  return faq;
}