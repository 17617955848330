import React, { useState } from 'react';
import * as _ from 'lodash';
import {toJS} from 'mobx'

/**
 * HOC for Form events handling
 * @param WrappedComponent : Form to manage
 * @param store : MobxStore with form state and validation scheme
 * @returns {{new(*=): {onChange, updateProperty, validate, state, onBlur, touchField, render(): *}, prototype: {onChange, updateProperty, validate, state, onBlur, touchField, render(): *}}}
 */
function manageForm(WrappedComponent, store, form) {

  // Return the managed Form wrap on a new component
  return function(props) { //NOSONAR
    // const [errors, setErrors] = useState({}) // errors handling
    // const [touched, setTouched] = useState({}) // touched field handling

    const [state, setState] = useState({errors: {}, touched: {}});

    /**
     * Touch the field and validate the form
     * Fired when exit field
     * @param e
     */
    const onBlur = (e) => {
      const key = e.target.name;
      touchField(key);
      validate(key); // -> Validate the field
    };

    /**
     * Touch the field and Set it's value on the form's store
     * @param e
     */
    const onChange = (e) => {
      let { name, value, tagName, type, checked } = e.target;

      if(type === 'checkbox') {
        value = checked;
      }

      // For a select tag, we store the i18nCode alongside the selected value
      if(tagName === 'SELECT') {
        const {options, selectedIndex} = e.target;
        if (_.has(toJS(store[form]), name + '_i18nCode')) {
          _.set(store[form], name + '_i18nCode', options[selectedIndex].text); // deep set
        } else if (_.has(toJS(store[form]), name + '_value')) {
          _.set(store[form], name + '_value', options[selectedIndex].text); // deep set
        }
      }

      // this.touchField(key)
      _.set(store[form], name, value); // deep set

      validate(name);

    };

    /**
     * Touch a field
     * @param key
     */
    const touchField = (key) => {
      // setTouched((prevState) => {
      //   let touched = { ...prevState }
      //   _.set(touched, key, true) // deep set
      //   return { touched }
      // })
      setState(prevState => {
        let touched = { ...prevState.touched };
        _.set(touched, key, true); // deep set
        return { ...prevState, touched: touched };
      });
    };

    /**
     * Validate form and get fields errors
     * @param key -> the field to touch
     * @returns {*}
     */
    const validate = (key) => {
      // Validate form and get fields errors
      if(!store.formValidationScheme) return Promise.resolve();
    const dataa = toJS(store[form]);
      return store.formValidationScheme.isValid(dataa)
        .then(valid => {
          if(valid) {
            //setErrors({})  // form is valid so reset errors
            setState(prevState => { return {...prevState, errors: {}}});
            return Promise.resolve();
          } else {
            const _validation = store.formValidationScheme.validate(toJS(store[form]), { abortEarly: false })
            .then()
              .catch(err => {
                // get and set fields errors
                //setErrors({}); // reset errors
            setState(prevState => { return {...prevState, errors: {}}});

                // err.inner.forEach(err => {
                //   let a = err.path
                //   setErrors((prevState) => {
                //     _.set(prevState, err.path, err.message)
                //     return prevState
                //   })
                //   setTouched((prevState) => {
                //     if(!key) {
                //       _.set(prevState, err.path, true)
                //     }
                //     debugger
                //     return prevState
                //   })

                // });

                err.inner.forEach(err => {
                  setState(prevState => {
                    let errors = { ...prevState.errors };
                    _.set(errors, err.path, err.message); // deep set

                    let touched = {...prevState.touched};
                    if(!key) {
                      _.set(touched, err.path, true);
                    }

                    return { errors, touched };
                  });

                });

                console.log("111111111")

                return Promise.reject(err);
              })
              return _validation;
          }
        })

    };

    // Render the form to manage with all necessary props
    return <WrappedComponent 
      errors={state.errors} 
      touched={state.touched}
      validate={validate} 
      onChange={onChange} 
      onBlur={onBlur}
      // props 
      {...props} 
    />;
  };
}

export default manageForm;



