import React from 'react';
import { observer } from 'mobx-react';

import formStore from '../form.store.js';
import Field from '../../_components/forms/Field';
import { _T } from '../../_i18n/i18n.helper';

import styles from './formElement.module.scss';

const FormElement = (props) => {
  const { name } = props; //NOSONAR

  return (
    <div className={styles.formElement}>
      <p>{_T(name)}</p>
      <Field {...props} value={formStore.form[name]} />
    </div>
  )
}


export default observer(FormElement);