import React from "react";
import ReactPaginate from 'react-paginate';
import UIStore from '../../store/UIStore';
// import { _T } from '../../../src/_i18n/i18n.helper'

export default function Pagination({ total, pageData, setPageData}) {
    const numberOfPages = Math.ceil(total / pageData.pageSize);
    let brand = UIStore.environment_parameters.brand;

    return (
        <div className="paginationContr">
            <select className="custom-select" style={{width: '100px', border: '0.5px solid #f47e60'}} onChange={e => setPageData({pageNumber: 1, pageSize: e.target.value})} value={pageData.pageSize}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
            </select>
            <ReactPaginate
                nextLabel="Next"
                previousLabel="Prev"
                breakLabel="..."
                onPageChange={(e) => setPageData({...pageData, pageNumber: e.selected+1})}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={numberOfPages}
                containerClassName="pagination"
                pageLinkClassName={`pagination btn inactive ${brand}`}
                activeLinkClassName={`pagination btn active1 ${brand}`}
                previousLinkClassName={pageData.pageNumber !== 1 ? "pagination btn inactive" : "pagination btn inactive disabled"}
                nextLinkClassName={pageData.pageNumber !== numberOfPages ? "pagination btn inactive" : "pagination btn inactive disabled"}
                breakLinkClassName="pagination btn inactive"
                renderOnZeroPageCount={null}
                forcePage={pageData.pageNumber-1}
            />
        </div>
    );
}