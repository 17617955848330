import React from 'react';
import { observer } from 'mobx-react';
import { Redirect } from "react-router-dom";
import { _T } from '../_i18n/i18n.helper';
import HeatMode from "./StepHome/HeatMode";
import CoolMode from "./StepHome/CoolMode";
import projectStore from '../store/ProjectStore';
import UIStore from "../store/UIStore";
import { toast } from 'react-toastify';

import picto_chaud from "../assets/img/picto-chaud.svg";
import picto_froid from "../assets/img/picto-froid.svg";
import MainMenu from "../_components/Navigation/MainMenu";
import Breadcrumb from "../_components/Navigation/Breadcrumb";
import manageForm from "../_components/forms/manageForm";
import Field from "../_components/forms/Field";

const projectModeMap = {
  heatMode: HeatMode,
  coolMode: CoolMode
};

let childSubmit = null;

const ProjectStepHome = (props) => {
  const { history } = props; // NOSONAR

  /**
   * Run the submit form function of the child "ModeComponent" component
   * @returns {*}
   */
  const submit = () => {
    if(childSubmit) {
      return childSubmit();
    } else {
      return Promise.reject(); // NOSONAR
    }
  };

  /**
   * Registrer the submit form function of the child "ModeComponent" component to be call by BreadCrumbMobile
   * @param func
   */
  const registerChildSubmit = (func) => {
    childSubmit = func;
  };

  const onSwitchChange = (e) => {
    let { name, checked } = e.target;
    switch(name) {
      case 'simulationHot':
        if(!checked) {
          toast.warning(_T('DISABLE_HOT_SMULATION_WARNING'));
          return;
        }
        break;
      case 'simulationCool':
        if(!checked && !projectStore.project.simulationHot) return;
        else {
          props.onChange(e); // NOSONAR
          if(!checked) history.push(`/${UIStore.env}/project/home/heat`); // NOSONAR
        }
        break;
      default:
        return;
    }

    /*
    Mode: Au moins un des deux
    switch(name) {
      case 'simulationHot':
        if(!checked && !projectStore.project.simulationCool) return;
        else {
          props.onChange(e);
          if(checked) history.push(`/${UIStore.env}/project/home/heat`);
        }
        return;
      case 'simulationCool':
        if(!checked && !projectStore.project.simulationHot) return;
        else {
          props.onChange(e);
          if(checked) history.push(`/${UIStore.env}/project/home/cool`);
        }
        return;
    }*/
  };

  const enableMode = (e, mode) => {
    if(mode === 'cool') {
      submit().then(() => {
        projectStore.nextStep(history);
      });
    } else {
      history.push(`/${UIStore.env}/project/home/heat`); // NOSONAR
    }
  };

    const { mode } = props.match.params; // NOSONAR
    const ModeComponent = projectModeMap[mode + 'Mode'];

      if(mode === 'cool' && !projectStore.project.simulationCool) {
        const path = `/${UIStore.env}/project/home/heat`;
        return <Redirect to={path} />
      }

      let brand = UIStore.environment_parameters.brand;
      const enableCool = UIStore.environment_parameters.enableCool;

      return (
        <>
          <div className="header container-fluid">
            <MainMenu />
            <Breadcrumb handleSubmit={submit} />
          </div>

          <div className="main container-fluid">
              <div className="row choixSimulation">
                <p className={brand === 'pacific' ? 'pacific' : undefined}>{_T('CHOOSE_SIMULATION')}</p>
                <form>
                  <Field id='data_chaud'
                          type="switch"
                          name={'simulationHot'}
                          placeholder='HOT'
                          onChange={onSwitchChange}
                          value={projectStore.project.simulationHot} />

                  { enableCool && <Field id='data_froid'
                          type="switch"
                          name={'simulationCool'}
                          placeholder='COOL'
                          onChange={onSwitchChange}
                          value={projectStore.project.simulationCool} />
                  }

                </form>

              </div>

              <div className="row choixSticker">
                { projectStore.project.simulationHot &&
                  <button className={brand === 'pacific' ? "btn stickerChaud stickerChaudPacific" : "btn stickerChaud"} onClick={(e) => enableMode(e, 'heat')}>
                    <img src={picto_chaud} alt="" width="43" height="32"/>
                    <em>{_T('HOT')}</em>
                  </button>
                }

                {projectStore.project.simulationCool &&
                  <button className={brand === 'thermorfr' ?
                    "btn stickerFroid stickerFroidThermor"
                    :
                    brand === "pacific" ? // NOSONAR
                      "btn stickerFroid stickerFroidPacific"
                      :
                      "btn stickerFroid"}
                    onClick={(e) => enableMode(e, 'cool')}>
                    <img src={picto_froid} alt="" width="45" height="32"/>
                    <em>{_T('COOL')}</em>
                  </button>
                }
              </div>

            <ModeComponent registerChildSubmit={registerChildSubmit} />
          </div>
        </>
      );
    }


export default manageForm(observer(ProjectStepHome), projectStore, 'project');
