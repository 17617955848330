import {_T} from "../../_i18n/i18n.helper";

import HeatModeDeperditionsConnues from "./HeatModeDeperditionsConnues";
import HeatModeDeperditionsCoef from "./HeatModeDeperditionsCoef";
import HeatModeDeperditionsInvoice from "./HeatModeDeperditionsInvoice";
import HeatModeDeperditionsDPE from "./HeatModeDeperditionsDPE";

const deperditionMethods = [
  { code: '0', label: _T('SELECT_LOSS_KNOWN'), component: HeatModeDeperditionsConnues },
  { code: '1', label: _T('SELECT_LOSS_DPE'), component: HeatModeDeperditionsDPE },
  { code: '2', label: _T('SELECT_LOSS_INVOICE'), component: HeatModeDeperditionsInvoice },
  { code: '3', label: _T('SELECT_LOSS_COEF'), component: HeatModeDeperditionsCoef },
];

export const getLossMethods = (availableLossMethods) => {
  if(!availableLossMethods) return deperditionMethods

  try {
    const availableLossMethodsArray = availableLossMethods.split(',')
    return deperditionMethods.filter(dm => availableLossMethodsArray.find(alm => alm === dm.code))
  } catch(ex) {
    return deperditionMethods
  }
}