import React, {useState, useEffect} from 'react';
import { _T } from '../../_i18n/i18n.helper';
import UIStore from '../../store/UIStore';
import { API_URL } from '../../config';
import MainMenu from "../../_components/Navigation/MainMenu";
import Breadcrumb from "../../_components/Navigation/Breadcrumb";
import * as projectService from "../project.service";
import moment from 'moment'
import ProjectStore from "../../store/ProjectStore";
import { getBreadcrumbItems } from "../../configApp";
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from './Pagination';

let timeoutID;
const MyProjects = (props) => {
  const { history } = props; // NOSONAR
  const [data, setData] = useState({
    columns: null,
    data: null,
    //searchInput: '',
    showAlert: false,
    c: false,
    projectToDelete: null,
    showOpenProjectAlert: false,
    projectToOpen: null,
    loading: true,
    total: 0
  })

  const [pageData, setPageData] = useState({
    pageNumber: 1,
    pageSize: 10,
    prevData: {}
  });

  const [searchInput, setSearchInput] = useState('');

  const refreshProjectList = async () => {
    let stepIndexes = steps.filter(step => step.label.toLowerCase().includes(searchInput)).map(step => step.index);
    const {projects, total, totalProjects} = await projectService.getAll(pageData.pageSize, pageData.pageNumber, searchInput, stepIndexes);

    let lastProject = localStorage.getItem('lastProject');

    if (lastProject) {
      try {
        lastProject = JSON.parse(lastProject);

        lastProject = {
          createdAt: null,
          _name: _T('CURRENT_UNSAVED_PROJECT'),
          _reference: "",
          _clientName: "",
          _currentStep: lastProject.currentStep,
          _maxReachedStep: lastProject.maxReachedStep,
          data: { ...lastProject }
        };

        projects.unshift(lastProject);
      } catch (err) {
        //
      }
    }

    setData({
      ...data,
      projects: totalProjects,
      data: projects,
      showAlert: false,
      loading: false,
      total: total,
    })
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    refreshProjectList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData])

  let steps = getBreadcrumbItems(UIStore.environment_parameters.disabledSteps);
  steps = steps.map(step => {return {...step, label: _T(step.label)}});

  const handleSubmitSearch =(e) => {
    e.preventDefault();
  }

  const handleSearchInputChange = (e) => {
    clearTimeout(timeoutID);

    const searchInput = e.target.value.toLowerCase();

    timeoutID = setTimeout(async () => {
      setData({
        ...data,
        loading: true,
      });

      setPageData((prev) => ({
        ...pageData,
        pageNumber: 1,
        searchInput: searchInput,
        prevData: prev
     }));
    }, 2000);

    setSearchInput(searchInput);
  };

  const onConfirmDelete = () => {
    projectService.destroy(data.projectToDelete).then(response => {
      refreshProjectList();
      onCancel();
    })

    setData({
      ...data,
      showAlert: false,
    })
  };

  const onCancel = () => {
    setData({
      ...data,
      showAlert: false,
      projectToDelete: null
    });
  };

  const deleteProject = (e, project) => {
    setData({
      ...data,
      showAlert: true,
      projectToDelete: project
    });
  };

  const duplicateProject = (e, project) => {
    projectService.duplicate(project).then(response => {
      refreshProjectList();
    })
  };

  const openProject = async (e, project) => {
    if(moment(project.updatedAt).isBefore('2023-12-14')) {
      setData({
              ...data,
              showOpenProjectAlert: true,
              projectToOpen: project
            });
    } else {
     onConfirmOpenProject(project)
    }
  };

  const onConfirmOpenProject = async (project) => {
    setData({
      ...data,
      showOpenProjectAlert: false,
    }); 
    
    await projectService.load(project || data.projectToOpen);

    ProjectStore.goToStep(ProjectStore.project.currentStep, history);
  }

  const onCancelOpenProject = () => {
    setData({
      ...data,
      showOpenProjectAlert: false,
      projectToOpen: null
    });
  }

  const getProjectStatus = (project) => {
    const step = getBreadcrumbItems(UIStore.environment_parameters.disabledSteps).find(item => item.index === project._maxReachedStep);
    if (step) {
      project._stepLabel = _T(step.label);
      return project._stepLabel;
    } else {
      return 'undefined'
    }
  };

  const shareReport = (synthesisPath) => {
    const subject = _T('SHARE_REPORT_SUBJECT');
    // eslint-disable-next-line no-useless-concat
    const body = encodeURIComponent(_T('SHARE_REPORT_BODY') + "\n\n" + `${API_URL}/upload/synthesis/${synthesisPath}`);
    return `mailto:?subject=${subject}&body=${body}`;
  };

  const shareProject = (project) => {
    const subject = _T('SHARE_PROJECT_SUBJECT');
    // eslint-disable-next-line no-useless-concat
    const body = encodeURIComponent(_T('SHARE_PROJECT_BODY') + "\n\n" + `${window.location.origin}/${UIStore.env}/load_project/${project.id}/${project._uid}`);
    return `mailto:?subject=${subject}&body=${body}`;
  };

  const renderRow = (row) => {
    return (
      <>
        <td 
          className="versionning" // NOSONAR
          data-title="" // NOSONAR
        >
          {!row.data && <button className="btn_versionning" onClick={(e) => duplicateProject(e, row)}>+</button>}
        </td>
        <td 
          className="nom" // NOSONAR
          data-title="Nom de l'étude" // NOSONAR
        >{row._name}</td> 
        <td 
          className="reference" // NOSONAR
          data-title="Référence" // NOSONAR
          >{row._reference}</td> 
        <td 
          className="reference" // NOSONAR
          data-title="Collaborateur" // NOSONAR
        >{row._collaborateur}</td>
        <td 
          className="client" // NOSONAR
          data-title="Client" // NOSONAR
        >{row._clientName}</td>
        <td 
          className="date" // NOSONAR
          data-title="Date de création" // NOSONAR
        >{row.createdAt && moment(row.createdAt).format('DD/MM/YYYY')}</td>
        <td 
          className="statut" // NOSONAR
          data-title="Statut" // NOSONAR
        >{getProjectStatus(row)}</td>
        <td 
          className="email_etude" // NOSONAR
          data-title="" // NOSONAR
        >
          {!row.data && <a 
            className="btn_email_etude" // NOSONAR
            href={shareProject(row)} // NOSONAR
          ><span>Etude</span></a>}
          {row.data && <a 
            className="btn_email_etude_2" // NOSONAR
            href="/" // NOSONAR
          ><span>Etude</span></a>}
        </td>
        <td 
          className="edit_etude" // NOSONAR
          data-title="" // NOSONAR
        >
          <button 
            className="btn_edit_etude" // NOSONAR
            onClick={(e) => openProject(e, row)} // NOSONAR
          ></button>
        </td>
        <td 
          className="email_rapport" // NOSONAR
          data-title="" // NOSONAR
        >
          {!row.data && row._synthesisPath && <a 
            className="btn_email_rapport" // NOSONAR
            href={shareReport(row._synthesisPath)} // NOSONAR
          ><span>Rapport</span></a>}
        </td>
        <td 
          className="edit_rapport" // NOSONAR
          data-title="" // NOSONAR
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          {!row.data && row._synthesisPath && <a // NOSONAR
            className="btn_edit_rapport" // NOSONAR
            href={`${API_URL}/upload/synthesis/${row._synthesisPath}`} // NOSONAR
            target="_blank" // NOSONAR
            rel='noreferrer noopener' // NOSONAR
          ></a>}
        </td>
        <td 
          className="delete" // NOSONAR
          data-title="" // NOSONAR
        >
          {!row.data && row.user_id === UIStore.user.id && <button
            className="btn_delete" // NOSONAR
            onClick={(e) => deleteProject(e, row)} // NOSONAR
          ><i></i><span>Supprimer</span></button>}
        </td>
        <td 
          className="version" // NOSONAR
          data-title="" // NOSONAR
        >
          {!row.data && <button 
            className="btn_version" // NOSONAR 
            onClick={(e) => duplicateProject(e, row)} // NOSONAR
          ><span>Versionnner</span></button>}
        </td>
      </>
    )
  };

    return (
      <div>
        <div 
          className="header container-fluid withoutBreadcrumb" // NOSONAR
        >
          <MainMenu />
          <Breadcrumb 
            title={_T('MYPROJECTS_TITLE')} // NOSONAR
          />
        </div>

        <div 
          className="main container-fluid" // NOSONAR
        >
          <div 
            className="row etude" // NOSONAR
          >
            {data?.projects > 0 &&
              <>
                <div 
                  className="row rechETUDE" // NOSONAR
                >
                  <form onSubmit={handleSubmitSearch}>
                    <div 
                      className="input-group blocForm" // NOSONAR
                    >
                      <i className="data_search" // NOSONAR
                      ></i>
                      <input  
                        type="search" // NOSONAR
                        id="data_search" // NOSONAR
                        className="form-control" // NOSONAR
                        placeholder={_T('SEARCH_PROJECT')} // NOSONAR
                        value={searchInput || ''} // NOSONAR
                        onChange={handleSearchInputChange} // NOSONAR
                      />
                    </div>
                  </form>
                </div>
                  {data.data &&
                  <>
                   <div
                   className="row tabTAB etudeTAB" // NOSONAR
               >
                   <table
                       className="etudeDATAS" // NOSONAR
                   >
                       <thead>
                           <tr>
                               <th>{_T('NEW_VERSION')}</th>
                               <th>{_T('PROJECT_NAME')}</th>
                               <th>{_T('PROJECT_REF')}</th>
                               <th>{_T('PROJECT_COLLABORATEUR')}</th>
                               <th>{_T('PROJECT_CLIENT')}</th>
                               <th>{_T('PROJECT_DCREAT')}</th>
                               <th>{_T('PROJECT_STATUS')}</th>
                               <th
                                   className="colorETUDE" // NOSONAR
                               ><p><em>{_T('PROJECT')}</em></p>{_T('SHARE_BY_MAIL')}</th>
                               <th
                                   className="colorETUDE" // NOSONAR
                               ><p></p>{_T('OPEN_PROJECT')}</th>
                               <th
                                   className="colorRAPPORT" // NOSONAR
                               ><p><em>{_T('REPORT')}</em></p>{_T('SHARE_BY_MAIL')}</th>
                               <th
                                   className="colorRAPPORT" // NOSONAR
                               ><p></p>{_T('OPEN_REPORT')}</th>
                               <th>&nbsp;</th>
                               <th>&nbsp;</th>
                           </tr>
                       </thead>
                       <tbody>
                           {data.data?.map((row, index) => (
                               <React.Fragment key={index} //NOSONAR
                               >
                                   <tr key={"TR_" + index}//NOSONAR
                                   >
                                       {renderRow(row)}
                                   </tr>
       
                                   {row?.versions?.map((row, index) => (
                                       <tr key={"underTR_" + index} // NOSONAR
                                           className="underTR" // NOSONAR
                                       >
                                           {renderRow(row)}
                                       </tr>
                                   ))}
                               </React.Fragment>
                           ))
                           }
                       </tbody>
                   </table>
               </div>
                     { <Pagination total={data.total} pageData={!data.loading ? pageData : pageData.prevData} setPageData={setPageData}></Pagination>}
                      </>}
              </>
            }
            {
              (!data.loading && !data.projects) &&
              <>
                {_T('NO_PROJECT')}
              </>
            }
          </div>

          {
            data.showAlert &&
            <SweetAlert
              customClass="sweetAlertCustom"
              warning
              showCancel
              confirmBtnText={_T("YES")}
              confirmBtnBsStyle="danger"
              cancelBtnText={_T("NO")}
              title={_T("FRONT_DELETE_PROJECT")}
              onConfirm={onConfirmDelete}
              onCancel={onCancel}
              focusCancelBtn>
              {_T("DELETE_WARNING")}
            </SweetAlert>
          }

          {
            data.showOpenProjectAlert &&
            <SweetAlert
              customClass="sweetAlertCustom"
              showCancel
              confirmBtnText={_T("YES")}
              confirmBtnBsStyle="danger"
              cancelBtnText={_T("CANCEL")}
              title={_T("OPEN_PROJECT_TITLE")}
              onConfirm={onConfirmOpenProject}
              onCancel={onCancelOpenProject}
              focusCancelBtn>
              {_T("OPEN_PROJECT_WARNING")}
            </SweetAlert>
          }
        </div>

      </div>
      )
  }

export default MyProjects;