import React from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';
import Field from '../../_components/forms/Field';
import formStore from "./heatMode.store";
import * as dd from "./dropDown.data";
import HeatModeCalculatedNeeds from "./HeatModeCalculatedNeeds";
import UIStore from "../../store/UIStore";

/**
 * Manage the zone part of HeatMode Form
 */

const HeatModeDeperditionsInvoice  = (props) => {
  const data = {
    actualSystem: dd.actualSystem,
    withECS: dd.withECS,
    systemAge: dd.systemAge,
    temperatures: dd.temperature,
    nbPeoples: dd.invoiceNbOccupants
  }
  
  const { errors, touched, onBlur, deperditions } = props; //NOSONAR

  const onChange = (e) => {
    props.onChange(e); //NOSONAR
    props.calculateDeperd(); //NOSONAR
  };

  const onWithECSChange = (e) => {
    props.onChange(e); //NOSONAR

    let { value } = e.target;
    if(value === '0') formStore.form.deperditions.nbPeoples = '';

    props.calculateDeperd(); //NOSONAR
  };

  const getEnergyUnit = () => {
    const { deperditions } = props; //NOSONAR

    switch(deperditions.actualSystem) { //NOSONAR
      case '1':
        deperditions.nrgUnit = 3; //NOSONAR
        return UIStore.environment_parameters.currency + '/l';
      case '2':
        deperditions.nrgUnit = 1; //NOSONAR
        return UIStore.environment_parameters.currency + '/kWh';
      case '3':
        deperditions.nrgUnit = 2; //NOSONAR
        return UIStore.environment_parameters.currency + '/kg';
      default:
        // Do nothing
    }
  };


    return (
      <div className="row estimation">
        <div className="col col-12 col-lg-4">
          <div className="form">

            <Field id="data_murs"
                   className='custom-select'
                   type="select"
                   name='deperditions.temperature'
                   placeholder={_T('DESIRED_TEMP')}
                   onChange={onChange}
                   onBlur={onBlur}
                   value={deperditions.temperature} //NOSONAR
                   errors={errors}
                   touched={touched}
                   defaultValue={true}
                   unit="°C"
                   translate={false}
                   items={data.temperatures} />

            <Field className='form-control'
                   type="number"
                   name={'deperditions.annualBilling'}
                   placeholder={_T('ANNUAL_BILLING')}
                   onChange={onChange}
                   onBlur={onBlur}
                   value={deperditions.annualBilling} //NOSONAR
                   errors={errors}
                   touched={touched}
                   min={0}
                   step={0.01}
                   unit={UIStore.environment_parameters.currency} />

            <Field className='form-control'
                   type="select"
                   name='deperditions.actualSystem'
                   placeholder={_T('ACTUAL_SYSTEM')}
                   onChange={onChange}
                   onBlur={onBlur}
                   value={deperditions.actualSystem} //NOSONAR
                   errors={errors}
                   touched={touched}
                   defaultValue={true}
                   items={data.actualSystem} />

            <Field className='form-control'
                   type="number"
                   name={'deperditions.nrgPrice'}
                   placeholder={_T('ENERGY_PRICE')}
                   onChange={onChange}
                   onBlur={onBlur}
                   value={deperditions.nrgPrice} //NOSONAR
                   errors={errors}
                   touched={touched}
                   min={0}
                   step={0.01}
                   unit={getEnergyUnit()} />

            <Field className='form-control'
                   type="select"
                   name={'deperditions.withECS'}
                   placeholder={_T('WITH_ECS')}
                   onChange={onWithECSChange}
                   onBlur={onBlur}
                   value={deperditions.withECS} //NOSONAR
                   errors={errors}
                   touched={touched}
                   defaultValue={true}
                   items={data.withECS} />

            <Field className='custom-select'
                   type="select"
                   name='deperditions.nbPeoples'
                   placeholder={_T('OCCUPANTS_NUMBER')}
                   onChange={onChange}
                   onBlur={onBlur}
                   value={deperditions.nbPeoples} //NOSONAR
                   errors={errors}
                   touched={touched}
                   defaultValue={true}
                   translate={false}
                   disabled={deperditions.withECS === '0'} //NOSONAR
                   items={data.nbPeoples} />

            <Field className='form-control'
                   type="select"
                   name={'deperditions.systemAge'}
                   placeholder={_T('SYSTEM_AGE')}
                   onChange={onChange}
                   onBlur={onBlur}
                   value={deperditions.systemAge} //NOSONAR
                   errors={errors}
                   touched={touched}
                   defaultValue={true}
                   items={data.systemAge} />

            <Field className='form-control'
                   type="number"
                   name={'deperditions.dayTemp'}
                   placeholder={_T('DAY_TEMPERATURE')}
                   onChange={onChange}
                   onBlur={onBlur}
                   value={deperditions.dayTemp} //NOSONAR
                   errors={errors}
                   touched={touched}
                   min={0}
                   step={1}
                   unit="°C" />

            <Field className='form-control'
                   type="number"
                   name={'deperditions.nightTemp'}
                   placeholder={_T('NIGHT_TEMPERATURE')}
                   onChange={onChange}
                   onBlur={onBlur}
                   value={deperditions.nightTemp} //NOSONAR
                   errors={errors}
                   touched={touched}
                   min={0}
                   step={1}
                   unit="°C" />

          </div>
        </div>

        <HeatModeCalculatedNeeds />
      </div>
    );
  }

export default observer(HeatModeDeperditionsInvoice);