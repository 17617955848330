import UIStore from "../store/UIStore";
import * as apiService from "../_services/api.service";

export async function getAll () {
  return await apiService.get('client');
}

export async function destroy(client) {
  return await apiService.del(`client/${client.id}`);
}

export async function insertOrUpdate (client) {
  client.user_id = UIStore.user.id;
  if(client.id) {
    return await apiService.patch(`client/${client.id}`, client);
  } else {
    return await apiService.post('client', client);
  }
}