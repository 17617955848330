import React from 'react';
import { toast } from 'react-toastify';

import { projectStepMap } from "./ProjectSteps";
import * as tools from "../_helpers/tools";
import { _T } from '../_i18n/i18n.helper';
import UIStore from '../store/UIStore';
import TagManager from 'react-gtm-module'

/**
 * Project page
 */
const ProjectPage = (props) => {
  let { step } = props.match.params; // NOSONAR

  // useEffect(() => {
  //   async function fetchUserIsfos() {
  //     //const results = await userService.getUserInfos();
  //   }

  //   fetchUserIsfos();
  // }, []);

  const tagManagerArgs = {
    dataLayer: {
      'event': 'datalayer-ready',
      'page': {
        'arbo1': step,
        'arbo2': null,
        'arbo3': null,
        'arbo4': null,
        'taxonomy1': 'simulateur',
        'product_taxonomy1': 'PAC AIR/EAU',
        'product_taxonomy2': 'PAC INDIVIDUELLE',
        'product_taxonomy3': null,
        'product_taxonomy4': null,
        'product_taxonomy5': null,
        'product_taxonomy6': null
      },
      'user': {
        'id': UIStore.user.id,
        'type': null,
        'company': null,
        'siret': UIStore.user.siret
      }
    },
    dataLayerName: 'PageDataLayer'
  }
  TagManager.dataLayer(tagManagerArgs)

  step = tools.capitalizeFirstLetter(tools.camelize(step));
  const StepComponent = projectStepMap["ProjectStep" + step];

  if (!StepComponent) toast.error(_T('ERROR_OCCURED'));

  return (
    <>
      {StepComponent && <StepComponent {...props} />}
    </>
  );
}


export default ProjectPage;